import React, {FC} from 'react';
import {Link} from "react-router-dom";

type Props = {
    text: string
    link?: any
    className?: string
}

const MainButton: FC<Props> = ({text, link, className}) => {
    return (
        <>
            <Link to={link} className={`btn ${className}`}>{text}</Link>
        </>
    );
};

export default MainButton;