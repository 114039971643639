import React, {FC, useState} from "react";
import clsx from "clsx";
import {useFormik} from "formik";
import * as Yup from "yup";
import Select from 'react-select';


const contactSchema = Yup.object().shape({
    email: Yup.string()
        .email("Wrong email format")
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Email is required"),

    name: Yup.string()
        .required("Name is required"),


    phone_number: Yup.string()
        .required("Phone Number is required"),

    subject: Yup.string()
        .required("Tester Session is required"),

    message: Yup.string()
        .required("Message is required"),
});

interface OptionType {
    value: string;
    label: string;
}

const ContactForm: FC = () => {
    const [loading, setLoading] = useState(false);
    // const [isActive, setIsActive] = useState(false);

    const options: OptionType[] = [
        {value: '1', label: 'Networking'},
        {value: '2', label: 'Grow my business'},
        {value: '3', label: 'Get more clients'},
        {value: '4', label: 'Business operations in Dubai'},
        {value: '5', label: 'Seeking advice'},
        {value: '6', label: 'Exploring Dubai & its opportunities'},
        {value: '7', label: 'Meet new people'},
        {value: '8', label: 'Sponsor events'},
        {value: '9', label: 'Start SBC in my City'}
    ];

    const initialValues = {
        email: "",
        name: "",
        phone_number: "",
        subject: "",
        message: ""

    };

    const formik = useFormik({
        initialValues,
        validationSchema: contactSchema,
        onSubmit: async (values, {setSubmitting}) => {

            setSubmitting(true);
            try {
                setLoading(true);
                // const {data} = await bookEvent(event.id , values);

                // if (data.success === 1) {
                setLoading(false);
                // setIsActive(true);

                formik.resetForm();
                // setTimeout(function () {
                //     setIsActive(false);
                //     // resetForm()
                // }, 4500);
                // }
            } catch (ex) {
                console.error(ex);
            } finally {
                setSubmitting(true);
                setLoading(false);
            }
        },
    });
    return (
        <>
            <div className={'card border-0'}>
                <div className={''}>
                    <form className="form justify-content-center w-100 " onSubmit={formik.handleSubmit} noValidate>
                        <div className={'form-group row'}>
                            <div className={'col-lg-3 col-md-12 pe-2 mt-3'}>
                                <input type={'text'} {...formik.getFieldProps("name")} className={clsx(
                                    "form-control input-main",
                                    {"is-invalid": formik.touched.name && formik.errors.name,},
                                    {"is-valid": formik.touched.name && !formik.errors.name,}
                                )} placeholder="Full Name"/>
                                {formik.touched.name && formik.errors.name && (
                                    <div className="text-message-container-danger">
                                        <span role="alert">{formik.errors.name}</span>
                                    </div>
                                )}
                            </div>
                            <div className={'col-lg-3 col-md-12 ps-2 mt-3'}>
                                <input type={'email'}  {...formik.getFieldProps("email")}
                                       placeholder={"Email"}
                                       className={clsx("form-control input-main", {"is-invalid": formik.touched.email && formik.errors.email,}, {"is-valid": formik.touched.email && !formik.errors.email,})}/>
                                {formik.touched.email && formik.errors.email && (
                                    <div className="text-message-container-danger">
                                        <span role="alert">{formik.errors.email}</span>
                                    </div>
                                )}
                            </div>
                            <div className={'col-lg-3 mt-3'}>
                                <input type={'text'} {...formik.getFieldProps("phone_number")} className={clsx(
                                    "form-control input-main",
                                    {"is-invalid": formik.touched.phone_number && formik.errors.phone_number,},
                                    {"is-valid": formik.touched.phone_number && !formik.errors.phone_number,}
                                )} placeholder="Phone Number"/>
                                {formik.touched.phone_number && formik.errors.phone_number && (
                                    <div className="text-message-container-danger">
                                        <span role="alert">{formik.errors.phone_number}</span>
                                    </div>
                                )}
                            </div>
                            <div className={'col-lg-3 mt-3'}>
                                <Select
                                    className={clsx(
                                        "w-100 main-input",
                                        {"invalid-select": formik.touched.subject && formik.errors.subject,},
                                        {"valid-select": formik.touched.subject && !formik.errors.subject,}
                                    )}
                                    closeMenuOnSelect={false}
                                    placeholder={'Subject'}
                                    options={options.map((e: {
                                        label: any;
                                        value: any
                                    }) => ({
                                        label: e.label,
                                        value: e.value
                                    }))}
                                    isSearchable={true}
                                    onChange={(newValue: any) =>
                                        formik.setFieldValue('subject', newValue.value)
                                    }
                                />
                                {formik.touched.subject && formik.errors.subject && (
                                    <div className="text-message-container-danger">
                                        <span role="alert">{formik.errors.subject}</span>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={'form-group row'}>
                            <div className={'col-md-12  mt-3'}>
                               <textarea className={clsx(
                                   "form-control h-150px",
                                   {"is-invalid": formik.touched.message && formik.errors.message,},
                                   {"is-valid": formik.touched.message && !formik.errors.message,}
                               )} placeholder={'Write a message'}/>
                                {formik.touched.message && formik.errors.message && (
                                    <div className="text-message-container-danger">
                                        <span role="alert">{formik.errors.message}</span>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={'row mt-lg-4 mt-3'}>
                            <div className={'col-lg-12'}>
                                <button className="btn btn-primary bordered pe-5 ps-5"
                                        disabled={formik.isSubmitting || !formik.isValid} type="submit">
                                    {!loading && (
                                        <span className="indicator-label">
                                 Submit
                                </span>
                                    )}
                                    {loading && (
                                        <span className="indicator-progress"
                                              style={{display: "block"}}><span
                                            className="spinner-border spinner-border-sm align-middle ms-2"></span></span>)}
                                </button>
                            </div>
                            <p className={'f-regular text-blue-color sm-text mt-3'}>We won't send spam. You can unsubscribe at any time.</p>
                        </div>
                    </form>

                </div>
            </div>
        </>
    );
};
export default ContactForm;