import React, {FC} from "react";

const Point14: FC = () => {
    return (
        <div className={'container'}>
            <div className={'row '}>
                <div className={'col-lg-11 pb-4'}>
                    <h3 className={'f-bold text-blue-color section-title mb-3'}>
                        <span className={'colored-nb me-3'}> 14 </span>
                        PRIVACY POLICIES OF OTHER WEBSITES</h3>
                    <div className={'f-regular subheading text-blue-color'}>
                        <p className={'mt-4'}>
                            Our Website may contain links to other websites. Our Privacy Policy applies only to our
                            website, so if you click on a link to another website, you should read their Privacy Policy.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Point14;
