import React, {FC, useState} from "react";
import clsx from "clsx";
import {useFormik} from "formik";
import * as Yup from "yup";
import Select from "react-select";
import {OptionType} from "../../../models/OptionType";

const joinSchema = Yup.object().shape({
    email: Yup.string()
        .email("Wrong email format")
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Email is required"),

    name: Yup.string()
        .required("Name is required"),


    phone_number: Yup.string()
        .required("Phone Number is required"),

    date: Yup.string()
        .required("Date is required"),



});

const SignUpForm: FC = () => {
    const [loading, setLoading] = useState(false);
    // const [isActive, setIsActive] = useState(false);

    const options: OptionType[] = [
        { value: '1', label: 'Option 1' },
        { value: '2', label: 'Option 2' },
        { value: '3', label: 'Option 3' }
    ];
    const initialValues = {
        email: "",
        name: "",
        phone_number: "",
        date: ""
    };

    const formik = useFormik({
        initialValues,
        validationSchema: joinSchema,
        onSubmit: async (values, {setSubmitting}) => {

            setSubmitting(true);
            try {
                setLoading(true);
                // const {data} = await bookEvent(event.id , values);

                // if (data.success === 1) {
                setLoading(false);
                // setIsActive(true);

                formik.resetForm();
                // setTimeout(function () {
                //     setIsActive(false);
                //     // resetForm()
                // }, 4500);
                // }
            } catch (ex) {
                console.error(ex);
            } finally {
                setSubmitting(true);
                setLoading(false);
            }
        },
    });
    return (
        <>
            <div className={'mt-lg-5 pt-lg-5 mb-lg-5 pb-lg-5 fixed-bottom z-index-999'}>
                <div className={'card card-bordered-blue'}>
                    <div className={'p-4'}>
                        <div>
                            <h3 className={'section-title f-bold text-blue-color'}>
                                <span className={'text-primary-color'}>SIGN UP,</span> FOR FREE
                            </h3>
                        </div>

                        <form className="form justify-content-center w-100 " onSubmit={formik.handleSubmit} noValidate>
                            <div className={'form-group row'}>
                                <div className={'col-lg-12 col-md-12 mt-3'}>
                                    <input type={'text'} {...formik.getFieldProps("name")} className={clsx(
                                        "form-control input-main",
                                        {"is-invalid": formik.touched.name && formik.errors.name,},
                                        {"is-valid": formik.touched.name && !formik.errors.name,}
                                    )} placeholder="Full Name"/>
                                    {formik.touched.name && formik.errors.name && (
                                        <div className="text-message-container-danger">
                                            <span role="alert">{formik.errors.name}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className={'form-group row'}>
                                <div className={'col-lg-12 col-md-12 mt-3'}>
                                    <input type={'email'}  {...formik.getFieldProps("email")}
                                           placeholder={"Email"}
                                           className={clsx("form-control input-main", {"is-invalid": formik.touched.email && formik.errors.email,}, {"is-valid": formik.touched.email && !formik.errors.email,})}/>
                                    {formik.touched.email && formik.errors.email && (
                                        <div className="text-message-container-danger">
                                            <span role="alert">{formik.errors.email}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className={'form-group row'}>
                                <div className={'col-md-12 mt-3'}>
                                    <label className={'text-main-input xs-text f-medium text-uppercase mb-3'}>Phone
                                                                                                              Number</label>

                                    <input type={'text'} {...formik.getFieldProps("phone_number")} className={clsx(
                                        "form-control input-main",
                                        {"is-invalid": formik.touched.phone_number && formik.errors.phone_number,},
                                        {"is-valid": formik.touched.phone_number && !formik.errors.phone_number,}
                                    )} placeholder=""/>
                                    {formik.touched.phone_number && formik.errors.phone_number && (
                                        <div className="text-message-container-danger">
                                            <span role="alert">{formik.errors.phone_number}</span>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className={'form-group row'}>
                                <div className={'col-md-12 mt-3'}>
                                    <label className={'text-main-input xs-text f-medium text-uppercase mb-3'}>SELECT
                                                                                                              DATE for
                                                                                                              SBC
                                                                                                              International
                                                                                                              Business
                                                                                                              Connect
                                                                                                              (Online)</label>

                                    <Select
                                        className={clsx(
                                            "w-100 main-input",
                                            {"invalid-select": formik.touched.date && formik.errors.date,},
                                            {"valid-select": formik.touched.date && !formik.errors.date,}
                                        )}
                                        closeMenuOnSelect={false}
                                        placeholder={''}
                                        options={options.map((e: {
                                            label: any;
                                            value: any
                                        }) => ({
                                            label: e.label,
                                            value: e.value
                                        }))}
                                        isSearchable={true}
                                        onChange={(newValue: any) =>
                                            formik.setFieldValue('date', newValue.value)
                                        }
                                    />

                                    {formik.touched.date && formik.errors.date && (
                                        <div className="text-message-container-danger">
                                            <span role="alert">{formik.errors.date}</span>
                                        </div>
                                    )}

                                </div>
                            </div>

                            <div className={'form-group row'}>
                                <div className={'col-md-12 mt-3'}>
                                    <input
                                        type="checkbox"
                                        className="custom-checkbox"
                                        {...formik.getFieldProps("subscribe")}
                                    />
                                    <span
                                        className={`text-main-input xs-text f-medium mb-3`}>Subscribe to receive notifications via email for purchase requests, connections, and opportunities for business growth.</span>

                                </div>

                            </div>
                            <div className={'form-group row'}>
                                <div className={'col-md-12 mt-3'}>
                                    <input
                                        type="checkbox"
                                        className="custom-checkbox"
                                        {...formik.getFieldProps("agree")}
                                    />
                                    <span
                                        className={`text-main-input xs-text f-medium mb-3`}>I have read and agree to the terms and conditions outlined on this page.</span>

                                </div>

                            </div>

                            <div className={'row mt-lg-3 mt-3'}>
                                <div className={'col-lg-5'}>
                                    <button className="btn btn-primary bordered w-100 "
                                            disabled={formik.isSubmitting || !formik.isValid} type="submit">
                                        {!loading && (
                                            <span className="indicator-label">
                                 Sign Up
                                </span>
                                        )}
                                        {loading && (
                                            <span className="indicator-progress"
                                                  style={{display: "block"}}><span
                                                className="spinner-border spinner-border-sm align-middle ms-2"></span></span>)}
                                    </button>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </>
    );
};
export default SignUpForm;