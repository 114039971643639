import React, {FC} from 'react';


type Props = {
    title: string
    description ?: string
}

const TopBanner: FC<Props> = ({title, description}) => {

    return (
        <section className={'top-banner bg-main-gradient z-index-999'}>
            <div className={'container'}>
                <div className={'row justify-content-center'}>
                    <div className={'col-md-12 text-center mt-5 pt-4 pb-5'}>
                        <div className={' mt-5 pt-5 mb-5 pb-5 '}>
                            <h1 className={'main-title text-white f-bold'}>{title}</h1>
                            <p className={'main-description text-white f-semi-bold'}>{description}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TopBanner;