import React, {FC} from 'react';
import CardLayoutFour from "../../../components/cards/CardLayoutFour";
import {Link} from "react-router-dom";


const Access: FC = () => {

    const items = [
        {
            title: 'Exclusive access to networking events (online and live)',
            description: 'Free access to online events and heavily discounted offers on live events (proven ROI).',
            imageUrl: '/assets/images/networking/access-1.png'
        },
        {
            title: 'Privileged access to workshops & seminars',
            description: 'Access to local experts tips and workshops. Discover the secrets to success in this region.',
            imageUrl: '/assets/images/networking/access-2.png'
        },
        {
            title: 'Connect and network with the SBC directory',
            description: 'Expand your reach and build valuable relationships with other professionals in the community before arriving in Dubai. Find suppliers, advisors and clients.',
            imageUrl: '/assets/images/networking/access-3.png'
        },

    ]
    return (
        <section className={'access position-relative pb-5'}>
            <div className={'container'}>
                <div className={'row'}>
                    <div className={'col-md-10 mt-5 pt-5 z-index-999'}>
                        <h3 className={'f-bold text-blue-color section-title'}>
                            THE BUILDING BLOCKS FOR SUCCESS
                        </h3>
                    </div>

                    <div className={'col-md-12 mt-4  z-index-999'}>
                        <div className={'row justify-content-center'}>
                            {items.map((item, index) => (
                                <CardLayoutFour  colClass={'col-lg-4'} key={index} image={item.imageUrl}
                                                title={item.title}
                                                description={item.description}
                                                btn={false}/>
                            ))}
                        </div>
                    </div>
                </div>
                <div className={'row justify-content-center mb-5'}>
                    <div className={'col-lg-3 col-md-12'}>
                        <Link to={''} className={'btn btn-primary bordered w-100'}>ACCESS NOW</Link>
                    </div>
                </div>
            </div>

            <div className={'top-right-mask'}>
                <img src={'/assets/images/mask/mask-b-t-y.png'} alt={'Mask'}/>
            </div>
        </section>
    );
};

export default Access;