import React, {FC} from "react";
import {Link} from "react-router-dom";
import YouTubeEmbed from "../../../components/videos/YouTubeEmbed";

const Speaking: FC = () => {
    const videos = [
        {
            imageUrl: '/assets/images/rebel-network/video-1.png',
            videoId: 'iEmYz7B0cJc'
        },
        {
            imageUrl: '/assets/images/rebel-network/video-2.png',
            videoId: '5a8waVTg6nM'
        },
        {
            imageUrl: '/assets/images/rebel-network/video-3.png',
            videoId: 'DDzOyiEKd1w'

        },
    ];

    return (
        <section className={'bg-secondary-blue position-relative pb-5'}>
            <div className={'container'}>
                <div className={'row justify-content-center'}>
                    <div className={'col-md-12 text-center mt-5 mb-5 pt-5 z-index-999'}>
                        <h3 className={'text-white section-title f-bold'}>FOR THOSE WHO NEED AN EXPERT SPEAKER</h3>
                        <p className={'subheading text-white f-light mt-3'}>Need a dynamic speaker to energize your
                            staff or clients? With experience in the UAE and internationally, Phil Bedford knows exactly
                            how to engage your audience and leave a lasting impression.</p>
                    </div>
                </div>
                <div className={'row justify-content-center'}>
                    {videos.map((video: any, index: any) => (
                        <div className={'col-lg-4 col-md-12 z-index-999 mt-2 mb-2'} key={index}>
                            <div className={'position-relative w-100 h-100'}>
                                <YouTubeEmbed videoId={video.videoId} videoPoster={video.imageUrl} hideControls={true}/>
                            </div>
                        </div>
                    ))}
                </div>
                <div className={'row justify-content-center mt-5'}>
                    <div className={'col-lg-2 col-md-12 text-center'}>
                        <Link to="https://therebelnetworker.com/speaking/" className={'btn btn-primary bordered w-100'} target="_blank">view more</Link>
                    </div>
                </div>
            </div>

            <div className={'center-left'}>
                <img src={'/assets/images/mask/ellipse-pink.png'} alt={'Mask'}/>
            </div>
        </section>
    );
};

export default Speaking;