import React, {FC} from "react";

const Errors: FC = () => {
    return (
        <div className={'container'}>
            <div className={'row'}>
                <div className={'col-lg-11 pb-4'}>
                    <h3 className={'f-bold text-blue-color section-title mb-3'}>
                        <span className={'colored-nb me-3'}> 09 </span>
                        ERRORS, INACCURACIES & OMISSIONS</h3>

                    <div className={'f-regular subheading text-blue-color'}>
                        <p className={'mt-4'}><span className={'f-bold'}>9.1 </span> Occasionally there may be
                            information on our Platform or in the Service that contains typographical errors,
                            inaccuracies, or omissions that may relate to descriptions, pricing, promotions, offers. We
                            reserve the right to correct any errors, inaccuracies, or omissions, and to change or update
                            information or cancel orders if any information in the Service or on any related Platform is
                            inaccurate at any time without prior notice (including after you have submitted your order).
                        </p>
                        <p className={'mt-4'}><span className={'f-bold'}>9.2 </span>We undertake no obligation to
                            update, amend or clarify information in the Service or on any related Platform, including
                            without limitation, pricing information, except as required by law. No specified update or
                            refresh date applied in the Service or on any related Platform should be taken to indicate
                            that all information in the Service or on any related Platform has been modified or updated.
                        </p>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Errors;
