import React, {FC} from "react";
import GetInTouch from "../../components/GetInTouch";
import TopBanner from "../../layout/base/TopBanner";
import Reviews from "../home/components/Reviews";

const About: FC = () => {
    return (
        <section className="about-section">
            <TopBanner title="About Us"/>

            <div className="position-relative">
                <div className="container">
                    <div className="row align-items-center justify-content-center text-center pt-5">
                        <div className="col-md-9 pt-5">
                            <h3 className="section-title text-blue-color f-bold">The SBC Story: More than just a
                                networking platform</h3>
                        </div>
                    </div>

                    <div className="row mb-5">
                        <div className="col-md-12 mb-md-5 mb-0">
                            <p className="text-blue-color f-light subheading mt-4 text-center">
                                Strategic Business Connect (SBC) is a community. It is a platform where connections are
                                built and prospects arise. What makes it tick is that it's run by small business owners
                                who have grown their organizations using the same opportunities SBC offers its members.
                            </p>

                            <div className="mt-5">
                                <img src="/assets/images/about/about.jpg" loading="lazy"
                                     className="w-100"
                                     alt="About"/>
                            </div>

                            <div className="row justify-content-center mt-md-5 mb-md-5 mt-0 mb-0">
                                <div className="col-md-11 col-11">
                                    <div className="row">
                                        <div className="col-md-6 col-12">
                                            <p className="text-blue-color f-light subheading mt-4 text-left">
                                                It all started when Phil and Michelle had a vision to build a
                                                networking platform that focuses on quality relationships,
                                                collaboration, compassion, wealth, and freedom. They wanted
                                                a space where members could support each other, grow their businesses,
                                                and achieve their goals. And they managed
                                                to create it.
                                            </p>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <p className="text-blue-color f-light subheading mt-4 text-left">
                                                With over 17 years of advanced knowledge in networking, SBC structures
                                                its sessions to generate real results. From a proactive approach to
                                                helping clients grow their businesses to inviting VIP members for
                                                breakfast cooked by Phil and Michelle themselves,
                                                SBC is all about creating meaningful connections.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row align-items-center justify-content-center text-center">
                        <div className="col-md-12 p-0">
                            <div className="content-container">
                                <div className="bg"></div>

                                <div className="content">
                                    <div className="row align-items-center justify-content-center text-center">
                                        <div className="col-md-6">
                                            <h3 className="section-title text-white f-bold">
                                                From zero to 100 VIPs in one year
                                            </h3>
                                            <p className="text-white f-light subheading mt-4 text-center">
                                                SBC began during COVID with free online Zoom sessions, connecting over
                                                3,000
                                                people
                                                globally. As the network evolved, physical sessions were introduced in
                                                Dubai, and an
                                                online community platform was created for easy access to networking.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row justify-content-center mt-5 mb-5">
                        <div className="col-11 mt-3">
                            <h3 className="section-title text-blue-color f-bold text-center mission-section-title">
                                <span className="text-primary-color">Today, over 25% of SBC's</span> client base is
                                international, leading to the global licensing of its model to <span
                                className="text-primary-color">create a worldwide</span> community of entrepreneurs.
                            </h3>

                            <div className="mission-container mt-5 mb-5">
                                <div className="mask">
                                    <img src="/assets/images/mask/mask-top-right-to-bottom.png" alt="Mask"/>
                                </div>

                                <div className="content">
                                    <div className="icon">
                                        <img src="/assets/images/about/mission-icon.png" alt="Mission Icon"/>
                                    </div>
                                    <div className="text-content">
                                        <h3 className="section-title text-blue-color f-bold">Our
                                            Mission</h3>
                                        <p className="text-blue-color f-light subheading mt-2">
                                            "By providing result-driven networking opportunities, high-quality
                                            resources, and pivotal connectors, SBC empowers communities of compassionate
                                            business owners to achieve freedom through success in business."
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-primary-color">
                    <div className="container">
                        <div className="row align-items-center pt-5 pb-lg-5">
                            <div className="col-lg-6 col-md-12 mt-lg-5 pt-4 z-index-999">
                                <div>
                                    <img src="/assets/images/home/profile-1.webp" alt="Phil bedford"
                                         className="w-100 bg-profile"/>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 mt-lg-5 pt-lg-4 mt-5">
                                <div className="text-white ps-lg-5">
                                    <h3 className="f-bold section-title">Phil bedford</h3>

                                    <div className="md-text f-regular mt-4">
                                        <p>
                                            Phil's journey is as diverse as it is impressive. Starting as a dishwasher
                                            and juggling multiple jobs, he put himself through university, earning a
                                            BEng (Hons) in Civil Engineering and later a Masters in European
                                            Construction Engineering. His career spans the UK and Dubai, initially in
                                            recruitment before branching into the exhibition stand industry, real
                                            estate, and entrepreneurship with ventures in kite surfing and Latin dance.
                                        </p>
                                        <p>
                                            Phil's success across industries is attributed to his exceptional networking
                                            skills. Notably, he never relied on cold calling for leads. Introduced to
                                            BNI in 2007, he excelled as a consultant director, training and launching
                                            new chapters. Handpicked to develop Asentiv, an international franchise
                                            focusing on relational marketing, Phil empowers entrepreneurs to enhance
                                            their business skills and expand their networks dynamically and sustainably.
                                        </p>
                                        <p>
                                            When not training or traveling for speaking engagements, Phil enjoys Dubai's
                                            culinary scene, particularly relishing his wife Michelle's cooking and
                                            hosting dinner parties.
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="top-right-mask">
                        <img src="/assets/images/mask/mask-top-right-to-bottom.png" alt="Mask"/>
                    </div>
                </div>
            </div>

            <div className="bg-blue">
                <div className="container">
                    <div className="row align-items-center justify-content-between pt-lg-5 pb-lg-5">
                        <div className="col-lg-4 col-md-12 mt-5 pt-4 z-index-999 order-lg-2 order-1 pb-5">
                            <div className="position-relative">
                                <img src="/assets/images/about/michelle.png" alt="Michelle"
                                     className="w-100 bg-profile z-index-999 position-relative"/>
                                <div className="center-right-mask ellipse d-lg-block d-none">
                                    <img src="/assets/images/mask/ellipse-pink.png" className="ellipse"
                                         alt="Mask"/>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-7 col-md-12 mt-lg-5 pt-lg-4 mt-2 order-lg-1 order-2 pb-5">
                            <div className="text-white">
                                <h3 className="f-bold section-title">Michelle</h3>

                                <div className="md-text f-light mt-4">
                                    <p>
                                        Michelle is Australian by nationality, African by birth and a resident of Dubai
                                        for over 20 years. Her journey spans continents and her dedication is just as
                                        widespread. With a passion for teaching since childhood, she dedicated 34 years
                                        to education, including leadership roles in prestigious schools. As a survivor
                                        of two open heart surgeries, Michelle emphasizes a balanced lifestyle.
                                    </p>
                                    <p>
                                        In 2021, she merged her love for food and teaching to establish Homecook Me,
                                        empowering home cooks to create healthier meals. Partnering with her husband,
                                        Phil Bedford, she launched SBC, leveraging her leadership for quality
                                        networking.
                                    </p>
                                    <p>
                                        Beyond her culinary pursuits, Michelle enjoys reading, beach walks, yoga, and
                                        salsa dancing, always eager to explore new cultures through travel.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Reviews/>

            <GetInTouch/>
        </section>
    );
};

export default About;
