import React, {FC} from 'react';
import GetInTouchForm from "./forms/GetInTouchForm";

const GetInTouch: FC = () => {
    return (
        <section className="contact bg-blue-light position-relative">
            <div className="container pt-lg-5 pb-lg-5">
                <div className="row justify-content-end pt-5 pb-5">
                    <div className="col-lg-5 col-md-12 z-index-999">
                        <div className="me-lg-5 mt-4">
                            <h3 className="main-title f-bold text-white">Get to know us better</h3>
                            <p className="text-white f-regular subheading pe-lg-5">
                                Join one of our Zoom or live sessions to explore what you could access through SBC and
                                the VIP Community!
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 z-index-999 mb-5">
                        <GetInTouchForm/>
                    </div>
                </div>
            </div>

            <div className="masks">
                <div className="top-left-mask white d-lg-block d-none">
                    <img src="/assets/images/mask/ellipse-white-cropped.png" alt="Mask"/>
                </div>
                <div className="center-left to-bottom">
                    <img src="/assets/images/mask/mask-top-left-to-bottom.png" alt="Mask"/>
                </div>
            </div>
        </section>
    );
};

export default GetInTouch;