import React, {FC} from "react";

const Rights: FC = () => {
    return (
        <div className={'container'}>
            <div className={'row pt-5'}>
                <div className={'col-lg-11 pb-4'}>
                    <h3 className={'f-bold text-blue-color section-title mb-3'}>
                        <span className={'colored-nb me-3'}> 06 </span>
                        INTELLECTUAL PROPERTY RIGHTS</h3>

                    <div className={'f-regular subheading text-blue-color'}>
                        <p className={'mt-4'}><span className={'f-bold'}>6.1 </span> All the text, images, logos,
                            software, trademarks, service marks, intellectual property, or other material (“Intellectual
                            Property”) on the Platform belong to us or our licensors. You will not copy or transmit any
                            of the Intellectual Property except for your personal, non-commercial use. All copyright,
                            trademark and other proprietary rights notices presented on the Platform must appear on all
                            copies you print. All rights not expressly granted herein are reserved.</p>
                        <p className={'mt-4'}><span className={'f-bold'}>6.2 </span> Subject to your compliance with
                            these Terms, we grant you a worldwide, non-exclusive, revocable right to use the materials
                            we provide during any sessions or Services you purchase from us (“Materials”) and any
                            intellectual property rights contained in the Materials (“Materials IP”) for your own
                            personal, non-commercial use. You may not provide any Materials or Material IP to any third
                            party for any reason without our prior content.</p>
                        <p className={'mt-4'}><span className={'f-bold'}>6.3 </span> Any other use of Materials on
                            the Platform for purposes other than those permitted above, including the reproduction,
                            modification, distribution, republishing, transmission, display, or performance of such
                            Materials without the prior written consent of the Company is strictly prohibited.</p>
                        <p className={'mt-4'}><span className={'f-bold'}>6.4 </span> If you post customer reviews,
                            comments, customer questions or answers, or other content generated by you for display on
                            the Platform (including any images, video or audio, all together "content", you grant us a
                            non-exclusive, royalty-free, perpetual, irrevocable, and fully sublicensable right to use,
                            reproduce, modify, adapt, publish, perform, create derivative works from, distribute, make
                            available, translate, and modify such content throughout the world in any media. You grant
                            the Company and its sub-licensees the right to use the name that you submit in connection
                            with such content.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Rights;
