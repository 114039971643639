import React, {FC} from "react";
import CardLayoutFive from "../../../components/cards/CardLayoutFive";
import MainButton from "../../../components/buttons/MainButton";


const Options: FC = () => {
    const options = [

        {
            title: 'Option 1',
            description: 'Why wait until you\'re on-site and facing all associated costs? Delaying could result in missed business opportunities, potentially costing millions of Dirhams. Additionally, launching a business in a foreign country can bring significant stress. Take proactive steps now to mitigate these risks.',
            borderClass: 'blue-dark'
        },
        {
            title: 'Option 2',
            description: 'You have the option to schedule a call and hit the ground running. Begin immediately by establishing a robust network that attracts your ideal clients, even before your arrival to Dubai.',
            borderClass: 'blue-dark'
        },

    ];
    return (
        <section className={'options position-relative'}>
            <div className={'container'}>
                <div className={'row mt-5'}>
                    <div className={'col-md-12 text-center mb-5'}>
                        <h3 className={'section-title text-blue-color f-bold'}>TAKE ACTION ... NOW OR LATER?</h3>
                        {/*<small className={'text-blue-color f-light main-description'}>You have two options.</small>*/}
                    </div>
                </div>
                <div className={'row  pb-3 justify-content-center position-relative'}>
                    {options.map((option, index)=>(
                        <div className={'col-lg-6 col-md-12'} key={index}>
                            <CardLayoutFive title={option.title} description={option.description} borderClass={option.borderClass} />
                        </div>
                    ))}

                    <div className={'position-absolute or-wrapper d-lg-block d-none'}>
                        <div className={''}>
                            <div className={'bg-primary-color bdr-50 text-white text-center f-bold  or'}>
                                OR
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'row justify-content-center text-center  pb-lg-5 mb-lg-5 mb-5 pb-5'}>
                    <div className={'col-lg-5 col-md-12 pb-lg-5 pb-5'}>
                        {/*<p className={'f-regular subheading text-blue-color'}>If you feel this community is what you need …</p>*/}

                        <MainButton text={'I\'M IN! LET\'S CHAT'} className={'btn btn-primary bordered w-75 '} link={''} />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Options;
