import React, {FC} from "react";

const Point10: FC = () => {
    return (
        <div className={'container'}>
            <div className={'row '}>
                <div className={'col-lg-11 pb-4'}>
                    <h3 className={'f-bold text-blue-color section-title mb-3'}>
                        <span className={'colored-nb me-3'}> 10 </span>
                        CHILDREN’S DATA RIGHTS</h3>
                    <div className={'f-regular subheading text-blue-color'}>
                        <p className={'mt-4'}><span className={'f-bold'}>10.1 </span>
                            This Company does not intend the Platform to be used by anyone under legal age as per
                            the jurisdiction you reside in. In such cases, Platform does not knowingly collect personal
                            information from anyone under the legal age. In addition, we may limit how we collect, use,
                            and store some of the information of EU users between 13 and 16. In some cases, this means
                            we will be unable to provide certain functionality to these users. If we need to rely on
                            consent as a legal basis for processing your information and your country requires consent
                            from a parent, we may require your parent’s consent before we collect and use that
                            information. Make sure to read the entire policy to fully understand all the below terms
                            related to your privacy.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Point10;
