import React, {FC, useState} from "react";
import clsx from "clsx";
import {useFormik} from "formik";
import * as Yup from "yup";

const Login: FC = () => {
    const loginSchema = Yup.object().shape({
        email: Yup.string()
            .email("Wrong email format")
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required("Email is required"),

        password: Yup.string()
            .required("Password is required"),
    });
    const [loading, setLoading] = useState(false);

    const initialValues = {
        email: "",
        password: "",
    };

    const formik = useFormik({
        initialValues,
        validationSchema: loginSchema,
        onSubmit: async (values, {setSubmitting}) => {

            setSubmitting(true);
            try {
                setLoading(true);
                // const {data} = await bookEvent(event.id , values);

                // if (data.success === 1) {
                setLoading(false);
                // setIsActive(true);

                formik.resetForm();
                // setTimeout(function () {
                //     setIsActive(false);
                //     // resetForm()
                // }, 4500);
                // }
            } catch (ex) {
                console.error(ex);
            } finally {
                setSubmitting(true);
                setLoading(false);
            }
        },
    });
    return (
        <>
            <section className={'top-banner bg-main-gradient z-index-999'}>
                <div className={'container'}>
                    <div className={'row justify-content-center'}>
                        <div className={'col-md-12 text-center mt-5'}>
                            <div className={'mt-100px '}>
                                <h1 className={'main-title text-white f-bold pt-5'}>Login</h1>
                            </div>
                        </div>
                        <div className={'col-md-6 pb-5 mt-5  mb-100px'}>
                            <div className={'card'}>
                                <div className={'p-lg-5 p-3'}>
                                    <form className="form justify-content-center w-100 " onSubmit={formik.handleSubmit}
                                          noValidate>
                                        <div className={'form-group row'}>
                                            <div className={'col-lg-12 col-md-12 ps-2 mt-3'}>
                                                <input type={'email'}  {...formik.getFieldProps("email")}
                                                       placeholder={"Email"}
                                                       className={clsx("form-control input-main", {"is-invalid": formik.touched.email && formik.errors.email,}, {"is-valid": formik.touched.email && !formik.errors.email,})}/>
                                                {formik.touched.email && formik.errors.email && (
                                                    <div className="text-message-container-danger">
                                                        <span role="alert">{formik.errors.email}</span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className={'form-group row'}>
                                            <div className={'col-md-12 mt-3'}>

                                                <input type={'password'} {...formik.getFieldProps("password")}
                                                       className={clsx(
                                                           "form-control input-main",
                                                           {"is-invalid": formik.touched.password && formik.errors.password,},
                                                           {"is-valid": formik.touched.password && !formik.errors.password,}
                                                       )} placeholder='Password'/>
                                                {formik.touched.password && formik.errors.password && (
                                                    <div className="text-message-container-danger">
                                                        <span role="alert">{formik.errors.password}</span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className={'form-group d-flex justify-content-between mt-3'}>
                                            <div className={''}>
                                                <input
                                                    type="checkbox"
                                                    className="custom-checkbox"
                                                    {...formik.getFieldProps("remember")}
                                                />
                                                <span className={`f-regular text-blue-color xs-text ms-2`}>Remember Me</span>
                                            </div>
                                            <div className={`f-regular text-blue-color sm-text`}>
                                                Forget Password?
                                            </div>
                                        </div>

                                            <div className={'row mt-lg-5 mt-4 justify-content-center'}>
                                                <div className={'col-lg-5'}>
                                                    <button className="btn btn-primary bordered w-100 "
                                                            disabled={formik.isSubmitting || !formik.isValid}
                                                            type="submit">
                                                        {!loading && (
                                                            <span className="indicator-label">
                                 login
                                </span>
                                                        )}
                                                        {loading && (
                                                            <span className="indicator-progress"
                                                                  style={{display: "block"}}><span
                                                                className="spinner-border spinner-border-sm align-middle ms-2"></span></span>)}
                                                    </button>
                                                </div>
                                            </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
);
};

export default Login;
