import React, {FC} from "react";

const TermsPrice: FC = () => {
    return (
        <div className={'container'}>
            <div className={'row pt-5'}>
                <div className={'col-lg-11 pb-4'}>
                    <h3 className={'f-bold text-blue-color section-title mb-3'}>
                        <span className={'colored-nb me-3'}> 03 </span>
                        PRICE AND PAYMENT</h3>

                    <div className={'f-regular subheading text-blue-color'}>
                        <p className={''}><span className={'f-bold'}>3.1 </span>The prices for accessing the Platform
                            (“Access Fee”) will be displayed on the Platform or communicated to you via email at the
                            time of your access.</p>
                        <p className={''}><span className={'f-bold'}>3.2 </span> Please check your orders carefully
                            before confirming it. You are responsible for ensuring that your order is complete and
                            accurate. The order is deemed to be accepted when an acceptance of the order is sent to you
                            by email.</p>
                        <p className={''}><span className={'f-bold'}>3.3 </span> 3.3 We reserve the right to change the
                            Access Fee at any time. Such change will not affect existing orders unless there has been a
                            pricing error on the Platform. In an event or any pricing error, we will contact you using
                            the contact details you provided. We will give you the option to re-confirm the order at the
                            correct price or to cancel your order.</p>
                        <p className={''}><span className={'f-bold'}>3.4 </span>3.4 All payments made on our Platform
                            are non-refundable. Once you are provided with Access to the Platform you waive any of your
                            cancellation rights. In an event that you are unhappy with any of the Services of the
                            Platform, you should contact us as soon as reasonably possible.</p>
                        <p className={''}><span className={'f-bold'}>3.5 </span>3.5 If any issue arises with your
                            purchase such that you feel you are entitled to a refund, then we will endeavour to assist
                            you, but refunds are not guaranteed nor are we obligated to consider requests.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TermsPrice;
