import React, {FC} from "react";
import PartnerForm from "../../../components/forms/PartnerForm";
import {Link} from "react-router-dom";

const Book: FC = () => {

    return (
        <>
            <section className={'contact bg-blue-light position-relative'}>
                <div className={'container pt-lg-5 pb-lg-5'}>
                    <div className={'row justify-content-end align-items-center pt-5 pb-5'}>
                        <div className={'col-lg-5 col-md-12 z-index-999'}>
                            <div className={'me-lg-5 mt-4'}>
                                <h3 className={'section-title f-bold text-white'}>Ready to revolutionize business networking in your city?</h3>
                                <p className={'text-white f-regular subheading  pe-lg-5'}>
                                    Let's discuss bringing SBC to your community.
                                </p>
                                <div>
                                    <Link to={''} className={'btn btn-secondary ps-4 ps-5 pe-5'}>Book call</Link>
                                </div>
                            </div>
                        </div>
                        <div className={'col-lg-6 col-md-12 z-index-999 mb-5'}>
                            <PartnerForm/>
                        </div>
                    </div>
                </div>

                <div className={''}>
                    <div className={'top-left-mask white d-lg-block d-none'}>
                        <img src={'/assets/images/mask/ellipse-white-cropped.png'} className={''} alt={'Mask'}/>
                    </div>
                    <div className={'center-left to-bottom'}>
                        <img src={'/assets/images/mask/mask-top-left-to-bottom.png'} className={''} alt={'Mask'}/>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Book