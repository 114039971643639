import React, {FC} from "react";

const Introduction: FC = () => {
    return (
        <div className={'container'}>
            <div className={'row pt-5 mb-5'}>
                <div className={'col-lg-11 pt-4'}>
                    <h3 className={'f-bold text-blue-color section-title mb-3'}>Introduction</h3>

                    <div className={'f-regular subheading text-blue-color'}>
                        <p>
                            www.strategicbusinessconnect.com (the “Platform”) provides services through Asentiv
                            Marketing and Management Company LLC ("Company" or "we").</p>
                        <p> We respect your privacy and are committed to
                            protecting it by complying with this policy.</p>
                        <p> This policy covers how we process your personal data
                            if you use our Platform or order any Services. In these instances, we are the controller of
                            your
                            personal data (which means we decide what information we collect and how it is used).</p>
                        <p> Please read this policy carefully to understand our policies and practices regarding your
                            information and how we will treat it. If you do not agree with our policies and practices,
                            your choice is not to use the Platform. By accessing or using the Platform you agree to this
                            Privacy Policy.</p>
                        <p> This policy may change from time to time. Your continued use of the platform after we make
                            changes is deemed to be acceptance of those changes, so please check the policy periodically
                            for updates.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Introduction;
