import React, {FC} from "react";
import Slider from "react-slick";


type Props = {
    images ?: any
}
const CarouselLogos: FC<Props> = ({images}) => {

    const settings = {
        dots:  false,
        infinite: true,
        speed: 500,
        arrows: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 992,
                settings: {

                    slidesToShow: 2,
                    slidesToScroll: 1,
                    center: true

                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],


    };


    return (
        <div className={'ps-5 pe-5 py-4'}>
            <Slider {...settings} className={'dark-carousel arrows-normal text-center'} >
                {images.map((item: any, index: number) => {
                    return(
                        <div className="" key={index}>
                            <img className="d-block w-100" src={(item)} alt="Slide"/>
                        </div>
                        )}
                    )}
            </Slider>


        </div>
    )
}
export default CarouselLogos;