import React, {FC} from "react";

const PeopleTalks: FC = () => {

    return (
        <section className={'vip-directory bg-blue position-relative'}>
            <div className={'container  pb-5'}>
                <div className={'row justify-content-center mt-5 pt-5'}>
                    <div className={'col-md-6 text-center mt-lg-3'}>
                        <h3 className={'section-title text-white f-bold'}>WHAT DO PEOPLE SAY ABOUT SBC?</h3>
                    </div>
                    <div className={'col-lg-10 text-center'}>
                        <div className={'d-flex justify-content-center align-items-end'}>
                            <div className={'pb-4'}>
                                <img src={'/assets/images/icons/start.png'} alt={''} className={'quote-icon'}/>
                            </div>
                            <div className={''}>
                                <p className={'block-title text-white f-semi-bold'}>
                                    That it’s totally, absolutely, 110% worth it.
                                </p>
                            </div>
                            <div  className={''}>
                                <img src={'/assets/images/icons/end.png'} alt={''} className={'quote-icon'}/>
                            </div>
                        </div>


                    </div>
                </div>
                <div className={'row justify-content-center mt-5 pb-5'}>
                    <div className={'col-lg-5 col-md-12 me-lg-5'}>
                        <p className={'block-title text-white f-semi-bold'}>
                            Every day, we receive feedback from customers who express their delight at the incredible ripple effect SBC has had on their business and overall attitude.
                        </p>
                    </div>
                    <div className={'col-lg-5 col-md-12 z-index-999 mt-lg-0 mt-4'}>
                        <div className={'card'}>
                            <div className={'p-4'}>
                                <div>
                                    <div className={'subheading f-semi-bold text-blue-color mt-3 mb-3'}>
                                        <ul className={'checklist-pink mt-3'}>
                                            <li>Expand network and scale businesses globally</li>
                                            <li>Work with dream clients, not just for bills</li>
                                            <li>Enjoy more time with loved ones</li>
                                            <li>Ensure a full pipeline of high-quality referrals</li>
                                            <li>Access expert help instantly</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className={'d-lg-block d-none'}>
                <div className={'top-left-mask'}>
                    <img src={'/assets/images/mask/dark-left-mask.png'} className={''} alt={'Mask'}/>
                </div>
                <div className={'center-right ellipse'}>
                    <img src={'/assets/images/mask/ellipse-blue-right.png'} className={''} alt={'Mask'}/>
                </div>
            </div>
        </section>
    );
};

export default PeopleTalks;
