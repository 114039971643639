import React, {FC} from "react";

const Point9: FC = () => {
    return (
        <div className={'container'}>
            <div className={'row'}>
                <div className={'col-lg-11 pb-4'}>
                    <h3 className={'f-bold text-blue-color section-title mb-3'}>
                        <span className={'colored-nb me-3'}> 09 </span>
                        YOUR DATA RIGHTS</h3>

                    <div className={'f-regular subheading text-blue-color'}>
                        <p className={'mt-4'}><span className={'f-bold'}>9.1 </span>
                            We want you to be in control of your information, so we provide you with the following
                            tools:
                        </p>

                        <ul className={'alpha-list f-regular subheading text-blue-color ms-0 ps-0'}>
                            <li>
                                The right to access: You have the right to request copies of your personal data. We may
                                charge you a small fee for this service.
                            </li>
                            <li>
                                The right to rectification: You have the right to request that we correct any
                                information you believe is inaccurate. You also have the right to request that We
                                complete the information you believe is incomplete.
                            </li>
                            <li>
                                The right to erasure: You have the right to request that we erase your personal data,
                                under certain conditions.
                            </li>
                            <li>
                                The right to restrict processing: You have the right to request that we restrict the
                                processing of your personal data, under certain conditions.
                            </li>
                            <li>
                                Correction/Rectification. The right to request correction of inaccurate personal
                                information maintained by the business.
                            </li>
                            <li>
                                The right to object to processing: You have the right to object to our processing of
                                your personal data, under certain conditions. You may withdraw your consent to the
                                processing of your information at any time by stopping to use the Platform.
                            </li>
                            <li>
                                Object to Processing: Object, on grounds relating to your situation, to our processing
                                of personal data concerning you which is (i) necessary for the performance of a task
                                carried out in the public interest, (ii) carried out in the exercise of official
                                authority vested in us, or (iii) processed by us on the basis of our legitimate
                                interest. In this case, where applicable, we will no longer process the personal data
                                unless we can demonstrate compelling legitimate grounds for the processing which
                                override your interests, rights, and freedoms, or the processing serves to assert,
                                exercise or defend legal claims.
                            </li>
                            <li>
                                Non-Discrimination. The right not to be discriminated against for exercising any of the
                                rights conferred at law.
                            </li>
                            <li>
                                Complaints. The right to lodge a complaint with the appropriate governmental authority.
                            </li>
                        </ul>
                        <p className={'f-regular subheading text-blue-color'}>
                            If you make a request, we have one month to respond to you. If you would like to exercise
                            any of these rights, please contact us.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Point9;
