import React, {FC, useEffect, useState} from "react";
import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {Link, NavLink, useLocation, useNavigate} from "react-router-dom";


const Header: FC = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [isNavbarExpanded, setIsNavbarExpanded] = useState(false);

    const toggleNavbar = () => {
        setIsNavbarExpanded(!isNavbarExpanded);
        setIsScrolled(!isNavbarExpanded);
    };
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        // Add event listener for scroll
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <header>
            <Navbar expand="xl" className={`pt-3 ${isScrolled ?
                "bg-main-gradient" : ""}`} fixed={'top'}>
                <Container>
                    <Navbar.Brand>
                        <Link className={''} to="/">
                            <img src={"/assets/images/logos/logo.png"} alt="SBC" className="logo"/>
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle onClick={toggleNavbar} aria-controls="navbarScroll ">
                        {isNavbarExpanded ? (<i className="fa-solid fa-x text-white"/>) : (
                            <i className="text-white fa-solid fa-bars"/>)}
                    </Navbar.Toggle>

                    <Navbar.Collapse className={'container justify-content-end align-items-center '} id="basic-navbar-nav">
                        <Nav className={'navbar-nav d-flex justify-content-between align-items-center'}>
                            <NavLink className={`nav-link ${location.pathname === '/' && 'active'}`}
                                     to="/">Home</NavLink>

                            <NavLink
                                className={`nav-link ${location.pathname.includes('/networking-and-workshop') && 'active'}`}
                                to="/networking-and-workshops">Networking & Workshops</NavLink>

                            <NavLink
                                className={`nav-link ${location.pathname.includes('/plug-into-dubai') && 'active'}`}
                                to="/plug-into-dubai">Plug Into Dubai</NavLink>

                            <NavLink
                                className={`nav-link ${location.pathname.includes('/partner') && 'active'}`}
                                to="/partner">Partner</NavLink>

                            <NavLink className={`nav-link ${location.pathname.includes('/about') && 'active'}`}
                                     to="/about">About Us</NavLink>

                            <NavLink
                                className={`nav-link ${location.pathname.includes('/sponsorship') && 'active'}`}
                                to="/sponsorship">Sponsor</NavLink>

                            <NavDropdown title={<>Rebel Network <i className="fa-solid fa-chevron-down"/></>}
                                         className={`nav-dropdown ${location.pathname.includes('/rebel-network') && 'active'}`}
                                         onClick={() => navigate('/rebel-network')}>
                                <NavDropdown title={<>Work With Phill <i className="fa-solid fa-chevron-right"/></>}
                                             className={`nav-dropdown text-capitalize f-regular text-blue-color xs-text`} autoClose={"outside"}>
                                    <NavDropdown.Item as={Link} to={"https://therebelnetworker.com/speaking"}
                                                      className={'text-capitalize f-regular text-blue-color xs-text'}
                                                      target="_blank">Speaking</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to={"https://therebelnetworker.com/business-coaching"}
                                                      className={'text-capitalize f-regular text-blue-color xs-text'}
                                                      target="_blank">Coaching</NavDropdown.Item>
                                </NavDropdown>
                                <NavDropdown title={<>Resources <i className="fa-solid fa-chevron-right"/></>} className={`nav-dropdown text-capitalize f-regular text-blue-color xs-text`} autoClose={"outside"}>
                                    <NavDropdown.Item as={Link} to={"https://therebelnetworker.com/blog"}
                                                      className={'text-capitalize f-regular text-blue-color xs-text'}
                                                      target="_blank">Blog</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to={"https://therebelnetworker.com/episodes"}
                                                      className={'text-capitalize f-regular text-blue-color xs-text'}
                                                      target="_blank">TV Features</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to={"https://therebelnetworker.com/grow-and-engage"}
                                                      className={'text-capitalize f-regular text-blue-color xs-text'}
                                                      target="_blank">Extras</NavDropdown.Item>
                                </NavDropdown>

                                <NavDropdown.Item as={Link} to={"https://therebelnetworker.com/books"}
                                                  className={'text-capitalize f-regular text-blue-color xs-text'}
                                                  target="_blank">Books</NavDropdown.Item>
                            </NavDropdown>

                            <NavLink className={`nav-link ${location.pathname.includes('/contact') && 'active'}`}
                                     to="/contact">Contact</NavLink>

                            <NavLink className={'btn btn-primary me-xl-2 ms-xl-2 ms-md-0 me-md-0 mb-xl-0 mb-2 p-sm'}
                                     to="/login">Login</NavLink>

                            {/*<NavLink className={'btn btn-transparent p-sm'} to="/">Sign Up</NavLink>*/}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    );
};


export default Header;
