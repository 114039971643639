import React, {FC} from "react";
import GetInTouch from "../../components/GetInTouch";
import TopBanner from "../../layout/base/TopBanner";
import Books from "./components/Books";
import Speaking from "./components/Speaking";
import Coaching from "./components/Coaching";
import Blog from "./components/Blog";
import Episodes from "./components/Episodes";


const RebelNetwork: FC = () => {
    return (
        <section className={'rebel-network'}>
            <TopBanner title={'Rebel Network'} description={'Join us in rebellion. Whether you prefer solitary reading, attending events to hear Phil and other experts speak, or seeking a mentor, The Rebel Networker keeps us all connected.'}/>
            <Books />
            <Speaking />
            <Coaching />
            <Blog />
            <Episodes />

            <GetInTouch/>
        </section>
    );
};

export default RebelNetwork;
