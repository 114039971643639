import React, {FC} from "react";

const WhyToBecomePartner: FC = () => {

    return (
        <div className={'position-relative bg-white'}>
            <div className={'container'}>
                <div className={'row justify-content-center align-items-center pt-5 pb-5'}>
                    <div className={'col-lg-6 col-md-12 mt-lg-5 mt-3 z-index-999'}>
                        <div className={'mt-lg-3 mb-lg-3'}>
                            <img src={'/assets/images/partner/p-1.png'} alt={'Partner'} className={'w-100'}/>
                        </div>
                    </div>
                    <div className={'col-lg-6 col-md-12  mt-lg-5 mt-5 ps-lg-5 z-index-999'}>
                        <div className={'mt-lg-3 mb-lg-3'}>
                            <h3 className={'text-blue-color f-bold section-title'}>Why Choose Us?</h3>
                            <p className={'text-blue-color f-regular subheading'}>
                                Strategic Business Connect (SBC) is more than just a networking platform. We're a
                                vibrant community uniting professionals across diverse sectors to exchange knowledge,
                                spur growth, and realize business goals. Founded by Phil and Michelle, whose diverse
                                backgrounds have shaped a supportive ecosystem, we now offer you the opportunity to
                                replicate our model in any city of your choice.
                            </p>

                            <div className={'subheading  f-semi-bold text-blue-color'}>
                                <ul className={'checklist mt-3'}>
                                    <li className={'mb-1'}>Proven Success: <span
                                        className={'text-blue-color f-regular'}>Utilize our established framework for networking & professional development.</span>
                                    </li>
                                    <li className={'mb-1'}>Model Replication: <span
                                        className={'text-blue-color f-regular'}>Gain access to experts, engage with the community, access exclusive programs, and utilize detailed how-to guides for event management and client acquisition.</span>
                                    </li>
                                    <li className={'mb-1'}>Global Networking Opportunities: <span
                                        className={'text-blue-color f-regular'}> Connect through Zoom and easily collaborate with global partners.</span>
                                    </li>
                                    <li className={'mb-1'}>Proven Roll-Out Strategy: <span
                                        className={'text-blue-color f-regular'}> A step-by-step plan to launch your own SBC. No need to reinvent the wheel.</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={'top-right-mask'}>
                <img src={'/assets/images/mask/mask-top-right-to-bottom.png'} alt={'Mask'}/>
            </div>
        </div>
    );
};

export default WhyToBecomePartner;