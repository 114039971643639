import React, {FC} from "react";
import {Link} from "react-router-dom";



const DigitalPlatform: FC = () => {

    return (
        <section className={'digital-platform pb-3 mt-5'}>
            <div className={'container mb-5 mt-5'}>
                <div className={'row justify-content-between align-items-center mt-2 pt-2'}>
                    <div className={'col-lg-7 col-md-12 mt-3 pt-3 pe-lg-5 order-lg-2 order-1'}>
                        <div>
                            <img src={'/assets/images/networking/digital.png'} className={'w-100 ps-5'} alt={'Digital Platform'} />
                        </div>
                    </div>
                    <div className={'col-lg-5 col-md-12 mt-3 pt-3 order-lg-1 order-2 '}>
                        <div>
                            <h3 className={'section-title text-blue-color f-bold'}>A COMPREHENSIVE DIGITAL PLATFORM</h3>
                            <p className={'subheading text-blue-color f-regular '}>Packed with literally everything you might ever need.</p>

                            <div className={'subheading f-semi-bold text-blue-color '}>
                                <ul className={'checklist mt-3'}>
                                    <li className={'mb-1'}>Comprehensive directory of the SBC VIP community, including industry experts.   </li>
                                    <li className={'mb-1'}>Workshops by top SBC professionals on various topics like marketing, law, finance, and tech.</li>
                                    <li className={'mb-1'}>50+ hours of expert business advice from leaders like Phil and Michelle.</li>
                                    <li className={'mb-1'}> Calendar of upcoming networking events and workshops.</li>
                                    <li className={'mb-1'}>FREE Kajabi access for website and course creation.</li>
                                    <li className={'mb-1'}> A regular video call with Phil and the team to help you with queries and challenges.</li>
                                </ul>
                            </div>

                            <div className={'mt-4'}>
                                <Link to={''} className={'btn btn-primary bordered ps-5 pe-5'}>BOOK CALL</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default DigitalPlatform;
