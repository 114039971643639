import React, {FC} from "react";
import {Link} from "react-router-dom";



const Coaching: FC = () => {

    return (
        <section className={'coaching pb-3 mt-5 position-relative'}>
            <div className={'container mb-5 mt-5'}>
                <div className={'row justify-content-between align-items-center mt-2 pt-2'}>
                    <div className={'col-lg-7 col-md-12 mt-3 pt-3 pe-lg-5'}>
                        <div>
                            <img src={'/assets/images/rebel-network/coach.png'} className={'w-100 ps-5'} alt={'Coaching'} />
                        </div>
                    </div>
                    <div className={'col-lg-5 col-md-12 mt-3 pt-3'}>
                        <div>
                            <h3 className={'section-title text-blue-color f-bold'}>FOR THOSE WHO NEED A MENTOR</h3>
                            <p className={'subheading text-blue-color f-regular mt-3'}>
                                Phil Bedford is dedicated to helping entrepreneurs succeed in business. With a commitment to empowering others to realize their aspirations sooner rather than later, Phil employs personalized strategies to unlock potential and achieve objectives.
                            </p>

                            <div className={'subheading f-semi-bold text-blue-color '}>
                                <ul className={'checklist mt-3'}>
                                    <li className={'mb-1'}>Business networking</li>
                                    <li className={'mb-1'}>Keynote speaking</li>
                                    <li className={'mb-1'}>Building a sustainable startup</li>
                                    <li className={'mb-1'}>Lead generation online & offline</li>
                                    </ul>
                            </div>

                            <div className={'mt-4'}>
                                <Link to="https://therebelnetworker.com/business-coaching/" className={'btn btn-primary bordered ps-5 pe-5'} target="_blank">Learn More</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'btm-right'}>
                <img src={'/assets/images/mask/mask-b-t-r.png'} alt={'Mask'}/>
            </div>

        </section>
    );
};

export default Coaching;
