import React, {FC} from "react";


const Features: FC = () => {
    return (
        <div className={'position-relative'}>
            <div className={'container'}>
                <div className={'row pt-5'}>
                    <div className={'col-lg-11'}>
                        <h3 className={'f-bold text-blue-color section-title mb-3'}>
                            <span className={'colored-nb me-3'}> 02 </span>
                            FEATURES</h3>

                        <div className={'f-regular subheading text-blue-color mt-4'}>

                            <p>
                                <span className={'f-bold'}>2.1</span> Your access to the Platform will give you access
                                to the following features ("Features”):
                            </p>
                            <p><span className={'f-bold'}>(a)</span> Access to the SBC community via Kajabi; </p>
                            <p>
                                <span className={'f-bold'}>(b)</span> Access to networking events;
                            </p>
                            <p><span className={'f-bold'}> (c) </span> Access to the Company’s client directory; and
                            </p>
                            <p><span className={'f-bold'}>(d)</span> Live or pre-recorded masterclasses on strategy,
                                marketing, and networking.

                            </p>
                            <p><span className={'f-bold'}>2.2 </span> We will reasonably endeavor to provide the
                                Features, but no guarantees can be provided in
                                respect of third parties we use to provide, and to
                                help to provide, the Features.
                            </p>
                            <p><span className={'f-bold'}>2.3 </span> We may make changes to all or any aspects of the
                                Features at any time providing the nature or quality of the Features aren’t materially
                                affected. We will try to let you know in advance about making any changes.</p>
                        </div>
                    </div>
                </div>
            </div>


        </div>

    );
};

export default Features;
