import React, {FC} from "react";

const Guide: FC = () => {
    return (
        <div className={'bg-blue position-relative'}>
            <div className={'container'}>
                <div className={'row pt-5'}>
                    <div className={'col-lg-11 pt-4 pb-5'}>
                        <h3 className={'f-bold text-white section-title mb-3 me-lg-5'}>
                            <span className={'colored-nb me-3'}> 07 </span>
                            COMMUNITY GUIDELINES AND PROHIBITED USES</h3>

                        <div className={'f-regular subheading text-white mt-5'}>
                            <p>
                                <span className={'f-bold'}>7.1 </span>We have the right to create or modify
                                Community Guidelines relating to the Client Services Platform (“Community Guidelines”)
                                at any time. We are not obligated to notify you about any changes to the Community
                                Guidelines. However, you are obligated to continue to keep yourself updated with respect
                                to these Community Guidelines because this will govern your registration and continued
                                access to the Client Services Platform. </p>
                            <p><span className={'f-bold'}>7.2 </span>You may be permitted to post comments, reviews
                                or content on the Client Services Platform. We have the right but not the obligation to
                                monitor and edit or remove any activity or content. We take no responsibility and assume
                                no liability for any content posted by you or any third party. We are not liable for any
                                statements, representations, or content provided by users and/or brands in any public
                                forum on the Platforms or any third-party site. We cannot guarantee the accuracy,
                                integrity or quality of such content.</p>
                            <p><span className={'f-bold'}>7.3 </span>In addition to the other prohibitions outlined
                                in the Community Terms of Service, you are prohibited from using the Platform or its
                                conten:</p>
                            <p><span className={'f-bold'}>(a) </span> for any unlawful purpose;
                            </p>
                            <p><span className={'f-bold'}>(b) </span> to solicit others to perform or participate in any
                                unlawful acts;</p>
                            <p><span className={'f-bold'}>(c) </span> to violate any international, federal, provincial,
                                or state regulations, rules, laws, or local ordinances;</p>
                            <p>
                                <span className={'f-bold'}> (d) </span> to infringe upon or violate our intellectual
                                property rights or the intellectual property rights of others;
                            </p>

                            <p>
                                <span className={'f-bold'}>(e)</span> to harass, abuse, insult, harm, defame, slander,
                                disparage, intimidate, or discriminate based on
                                gender, sexual orientation, religion, ethnicity,
                                race, age, national origin, or disability;
                            </p>
                            <p><span className={'f-bold'}> (f) </span> to submit false or misleading information;

                            </p>
                            <p><span className={'f-bold'}>(g)</span>to upload or transmit viruses or any other type of
                                malicious code that will or may be used in any way
                                that will affect the functionality or operation of
                                the Service or of any related Platform, other
                                Platforms, or the Internet;</p>
                            <p><span className={'f-bold'}>(h) </span>to collect or track the personal information of
                                others;</p>
                            <p><span className={'f-bold'}>(i) </span> to download the client directory list and spam
                                others;</p>
                            <p><span className={'f-bold'}>(j) </span> to spam, phish, pharm, pretext, spider, crawl, or
                                scrape;</p>
                            <p><span className={'f-bold'}>(k) </span> for any obscene or immoral purpose; or</p>
                            <p><span className={'f-bold'}>(l) </span>to interfere with or circumvent the security
                                features of the Service or any related Platform, other Platforms, or the Internet.</p>
                            <p><span className={'f-bold'}>7.4 </span>We reserve the right to terminate your use of the
                                Service or to ban or deny access to any individual or business, whether registered with
                                us or not, to the Client Services Platform if, in our sole assessment, we believe they
                                violate or do not comply with the Community Guidelines or these Terms.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className={'position-absolute ellipse-top-right ellipse'}>
                <img src={'/assets/images/mask/ellipse-pink-half.png'} className={''} alt={'Mask'}/>
            </div>
        </div>
    );
};

export default Guide;
