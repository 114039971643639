import React, {FC} from "react";

const OptionalTools: FC = () => {
    return (
        <div className={'container'}>
            <div className={'row '}>
                <div className={'col-lg-11 pb-4'}>
                    <h3 className={'f-bold text-blue-color section-title mb-3'}>
                        <span className={'colored-nb me-3'}> 10 </span>
                        OPTIONAL TOOLS</h3>
                    <div className={'f-regular subheading text-blue-color'}>
                        <p className={'mt-4'}><span className={'f-bold'}>10.1 </span>
                            We may provide you with access to third-party tools over which we neither monitor nor
                            have any control nor input.
                        </p>
                        <p className={'mt-4'}><span className={'f-bold'}>10.2 </span>You acknowledge and agree that
                            we provide access to such tools” as is” and “as available” without any warranties,
                            representations, or conditions of any kind and without any endorsement. We shall have no
                            liability whatsoever arising from or relating to your use of optional third-party tools.
                        </p>
                        <p className={'mt-4'}><span className={'f-bold'}>10.3 </span>Any use by you of optional
                            tools offered through the Platform is entirely at your own risk and discretion and you
                            should ensure that you are familiar with and approve of the Terms on which tools are
                            provided by the relevant third-party provider(s).
                        </p>
                        <p className={'mt-4'}><span className={'f-bold'}>10.4 </span>We may also, in the future,
                            offer new services and/or features through the Platform (including, the release of new tools
                            and resources). Such new features and/or services shall also be subject to these Terms of
                            Service.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OptionalTools;
