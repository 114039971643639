import React, {FC} from "react";
import CardBook from "../../../components/cards/CardBook";



const Books: FC = () => {

    const books = [
        {
            title: 'Branding shmanding',
            imageUrl: '/assets/images/rebel-network/book-1.png'
        },
        {
            title: 'You’re on Mute',
            imageUrl: '/assets/images/rebel-network/book-2.png'
        },
        {
            title: 'Seven Deadly Start-up Sins',
            imageUrl: '/assets/images/rebel-network/book-3.png'
        },
        {
            title: 'Kiss The Rebel',
            imageUrl: '/assets/images/rebel-network/book-4.png'
        },
        {
            title: 'I Just Need A Hug',
            imageUrl: '/assets/images/rebel-network/book-5.png'
        }
    ]
    return (
        <div className={'position-relative bg-white'}>
            <div className={'container'}>
                <div className={'row align-items-center pt-5 pb-lg-5'}>
                    <div className={'col-md-12'}>
                        <div className={'text-center'}>
                            <h3 className={'section-title f-bold text-blue-color text-capitalize'}>FOR THE BOOK WORMS</h3>
                            <p className={'main-description text-blue-color f-semi-bold'}>Some Phil Bedford books to add to your reading list</p>
                        </div>
                    </div>
                </div>
                <div className={'row mb-5'}>
                    {books.map((book: any , index: number) => (
                        <CardBook image={book.imageUrl} title={book.title}  colClass={'col-lg-3'} btnText={'Buy Now'} key={index} btn={true}/>
                    ))}

                </div>
            </div>
            <div className={'top-right-mask'}>
                <img src={'/assets/images/mask/mask-top-right-to-bottom.png'} alt={'Mask'}/>
            </div>
        </div>

    );
};

export default Books;
