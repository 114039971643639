import React, {FC} from "react";
import CardLayoutSix from "../../../components/cards/CardLayoutSix";

const BecomePartner: FC = () => {
    const steps = [
        {
            title: 'SUBMIT FORM',
            subtitle: '01',
            description: 'Complete our partnership inquiry form to express interest.',
            imageUrl: '/assets/images/partner/step-1.png',
            borderClass: 'primary'
        },
        {
            title: 'DISCOVERY CALL',
            subtitle: '02',
            description: 'Schedule a call with Phil to discuss your vision, expectations, and partnership details.',
            imageUrl: '/assets/images/partner/step-2.png',
            borderClass: 'blue-light'
        },
        {
            title: 'TRAINING & SETUP',
            subtitle: '03',
            description: 'Undergo comprehensive training on our model, access our resources, and receive support setting up your platform.',
            imageUrl: '/assets/images/partner/step-3.png',
            borderClass: 'secondary'
        },
        {
            title: 'LAUNCH',
            subtitle: '04',
            description: 'With our assistance, launch your SBC community and start hosting events.',
            imageUrl: '/assets/images/partner/step-4.png',
            borderClass: 'green'
        },
    ];


    return (
        <section className={'position-relative pb-5 bg-white'}>
            <div className={'container'}>
                <div className={'row justify-content-center'}>
                    <div className={'col-md-12 text-center  mt-5 mb-5 pt-5'}>
                        <h3 className={'f-bold section-title text-blue-color'}>Becoming A Partner: The Steps</h3>
                    </div>
                </div>
                <div className={'row justify-content-center'}>
                    {steps.map((step: any , index: number) => (
                        <div className={'col-xl-3 col-lg-4 col-md-12 p-lg-0 '} key={index}>
                            <CardLayoutSix title={step.title} image={step.imageUrl} borderClass={step.borderClass} subtitle={step.subtitle} description={step.description} />
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default BecomePartner;