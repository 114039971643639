import React, {FC} from "react";
import {Link} from "react-router-dom";

const Point3: FC = () => {
    return (
        <div className={'container'}>
            <div className={'row pt-5'}>
                <div className={'col-lg-11 pb-4'}>
                    <h3 className={'f-bold text-blue-color section-title mb-3'}>
                        <span className={'colored-nb me-3'}> 03 </span>
                        HOW WE USE OR DISCLOSE YOUR PERSONAL DATA</h3>

                    <div className={'f-regular subheading text-blue-color'}>
                        <p className={'mt-5'}>
                            We may use, or disclose the personal information collected for one or more of the following
                            purposes:
                        </p>


                        <ul className={'alpha-list ms-0 ps-0 mt-3'}>
                            <li>
                                To fulfill or meet the reason you provided the information. For example, if you
                                share your name and contact information to ask a question about the Platform, we will
                                use that personal information to respond to your inquiry;
                            </li>
                            <li>
                                To provide Services and provide updates about the Services you have bought (eg.
                                confirmation of order);
                            </li>
                            <li>
                                To personnel providing technical and business-related services to the Platform
                                (including but not limited to external consultants and freelancers) including the
                                following:
                                <ol className={'mt-2'}>
                                    <li> Kajabi, our Platform host. Their privacy policy can be found at <Link
                                        className={'link-blue-light'} to={'https://kajabi.com/policies/privacy'}
                                        target={'_blank'}>
                                        https://kajabi.com/policies/privacy
                                    </Link>
                                    </li>
                                    <li> Community Box, a plugin aiding in data storage. Their privacy policy is
                                        accessible at <Link className={'link-blue-light'}
                                                            to={'https://www.communitybox.co/privacy-policy'}
                                                            target={'_blank'}>
                                            https://www.communitybox.co/privacy-policy
                                        </Link>
                                    </li>
                                    <li> Stripe : <Link className={'link-blue-light'}
                                                        to={'https://stripe.com/en-in/privacy'}
                                                        target={'_blank'}>https://stripe.com/en-in/privacy</Link>
                                    </li>
                                    <li> Keap : <Link className={'link-blue-light'}
                                                      to={'https://keap.com/legal/privacy-policy'}
                                                      target={'_blank'}>https://keap.com/legal/privacy-policy</Link>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                To comply with any court order, law, or legal process, including responding to any
                                government or regulatory request.
                            </li>
                            <li>To provide, support, personalize, and develop the Platform;</li>
                            <li>To create, maintain, customize, and secure your account with us;</li>
                            <li>
                                To provide you with support and to respond to your inquiries, including to investigate
                                and to address your concerns and monitor and improve our responses;
                            </li>
                            <li>
                                To help maintain the safety, security, and integrity of the Platform;
                            </li>
                            <li>
                                For testing, research, analysis, to develop and improve the Platform.
                            </li>
                            <li>
                                Carrying out our obligations arising from any contracts entered between you and another
                                entity using our application or between you and us;
                            </li>
                            <li>
                                To protect and defend ourselves against legal claims;
                            </li>
                            <li>
                                Respond to law enforcement requests and as required by applicable law, court order, or
                                governmental regulations.
                            </li>
                        </ul>

                    </div>
                    <div className={'f-semi-bold subheading text-blue-color text-lowercase mt-5'}>
                        We do not sell personal information.
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Point3;
