import React, {FC} from "react";
import TopBanner from "../../layout/base/TopBanner";
import SubscriptionForm from "./components/SubscriptionForm";
import {Tooltip} from 'react-tooltip'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleInfo} from "@fortawesome/free-solid-svg-icons";


const Offer: FC = () => {
    const access = [
        {
            title: 'SBC NETWORKING OPPORTUNITES',
            description: [
                'Complimentary access to online SBC networking sessions',
                'Discounted rates for live SBC Networking sessions',
                'Networking opportunities for both females and mixed groups',
                'Varied networking formats, including Super Connect, Deep-Dive and Free-Flow sessions'
            ],
            bgClass: 'bg-primary-color'
        },
        {
            title: 'SBC COMMUNITY DIRECTORY',
            description: [
                'FREE access to the SBC VIP Community - full of local experts and professionals',
                'Access to the Business Directory - a new business funnel for you and a great resource tool',
                'Opportunities for business collaborations within the community',
                'Contact options with other VIP professionals via search features'
            ],
            bgClass: 'bg-blue'
        },
        {
            title: 'SBC RESOURCES',
            description: [
                'Complimentary access to the \'Se7en Deadly Start Up Sins\' e-course',
                'Priority access to esteemed business leaders, authors, experts, and workshops',
                'Access to business-related networking resources and  a 25% discount on \'The Millionaire Networker\' e-course to boost your networking skills and market entry'
            ],
            bgClass: 'bg-blue-light'
        },
    ]
    return (
        <section className={'offer'}>
            <TopBanner title={'Opportunities Await at SBC'}
                       description={'Explore our exclusive offerings and connect with a community of like-minded professionals. Subscribe today to unlock opportunities and resources tailored to your growth and success.'}/>
            <div className={'position-relative bg-white'}>
                <div className={'container'}>
                    <div className={'row pt-5 justify-content-between'}>
                        <div className={'col-lg-7 col-md-12 pt-5 pb-5 z-index-999'}>
                            <div className={'text-blue-color '}>
                                <h3 className={'section-title f-bold'}>Welcome to the SBC Community!</h3>
                            </div>
                            <div
                                className={'d-flex  text-blue-color f-regular align-items-center  text-uppercase pt-2'}>
                                <div className={'me-3 badge text-blue-color'}>
                                    <div className={'subheading pe-3 ps-3 pt-2 pb-2 cursor-pointer'}
                                         data-tooltip-id="founder-deal-tooltip"
                                         data-tooltip-html="<p class='text-transform-none text-white f-light subheading m-0 p-0'>Annual renewal fixed at this price <br> for the foreseeable future</p>"
                                    >
                                        <FontAwesomeIcon icon={faCircleInfo}/> FOUNDER DEAL <span
                                        className={'text-primary-color f-bold '}>$1342/YR </span>
                                    </div>
                                </div>
                                <Tooltip id="founder-deal-tooltip" place={'bottom-start'} className={'tooltip'}/>
                                <div className={'md-text f-semi-bold'}>
                                    $682+vat /YR
                                </div>
                            </div>
                            {/*<p className={'text-blue-color f-light subheading mt-3'}>*/}
                            {/*    (annual renewal fixed at this price for the <br/> foreseeable future)*/}
                            {/*</p>*/}
                            <p className={'text-blue-color f-light subheading mt-3'}>
                                The SBC Community empowers compassionate business owners to attain freedom through
                                business success by offering result-driven networking opportunities, high-quality
                                resources, and key connections.
                            </p>
                            <div className={'subheading f-medium text-blue-color pt-2 pb-2'}>
                                <ul className={'checklist mt-3'}>
                                    <li className={'mb-2'}>Build your Dubai network from abroad</li>
                                    <li className={'mb-2'}>Access the VIP platform online & easily find suppliers,
                                        clients & advisors
                                    </li>
                                    <li className={'mb-2'}>Join FREE networking events on Zoom 4x/month</li>
                                    <li className={'mb-2'}>Complimentary Zoom call with Phil once/month</li>
                                    <li className={'mb-2'}>Access to a network of UAE-based experts</li>
                                    <li>Join face-to-face networking events when in Dubai</li>
                                </ul>
                            </div>
                            <div className={'text-blue-color f-light subheading mt-3 mb-4'}>
                                The SBC VIP Community has its perks. Here are some:
                            </div>

                            <div className={'row'}>
                                {access.map((block: any, index: number) => (
                                    <div className={'col-lg-4 col-md-12 pe-lg-0'} key={index}>
                                        <div className={`card card-custom card-stretch gutter-b border-0 bg-access`}>
                                            <div className="card-body d-flex flex-column p-0 border-0">
                                                <div className="flex-grow-1">
                                                    <div
                                                        className={`${block.bgClass} text-center pt-3 pb-3 title-container `}>
                                                        <h4 className={'subheading f-semi-bold text-white pb-0 mb-0'}>
                                                            {block.title}
                                                        </h4>
                                                    </div>
                                                    <div className="list-container">
                                                        <ul className={'md-text f-light text-blue-color list-unstyled list-bordered p-3'}>
                                                            {block.description.map((item: string, index: number) => (
                                                                <li key={index}>{item}</li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className={'col-lg-2/5 col-md-12  pt-5 pb-5 z-index-999'}>
                            <SubscriptionForm/>
                        </div>
                    </div>
                </div>
                <div className={'top-right-mask'}>
                    <img src={'/assets/images/mask/mask-top-right-to-bottom.png'} alt={'Mask'}/>
                </div>
            </div>
            <section className={'bg-split-primary position-relative pb-5'}>
                <div className={'container'}>
                    <div className={'row justify-content-center'}>
                        <div className={'col-md-8 text-center mt-5 mb-5 z-index-999 pt-5'}>
                            <h3 className={'section-title text-white f-bold'}>
                                We look forward to welcoming <br/>
                                you to our growing community!
                            </h3>

                        </div>
                    </div>
                    <div className={'row justify-content-center pb-5 mb-5 z-index-999'}>
                        <div className={'col-lg-6 col-md-12 mt-2'}>
                            <div>
                                <img src={'/assets/images/about/michelle-phil.png'} alt={'Phil bedford'}
                                     className={'w-100 bg-profile '}/>
                            </div>
                            <div className={'d-flex justify-content-center mt-3'}>
                                <div>
                                    <img src={'/assets/images/icons/phone-icon.png'} className={'w-45px'}
                                         alt={'Phone Number'}/>
                                </div>
                                <div className={'text-blue-color ms-lg-3 ms-1'}>
                                    <div className={'f-bold subheading'}>
                                        Phil
                                    </div>
                                    <div className={'f-regular sm-text'}>
                                        +(971) 50 848 7188
                                    </div>
                                </div>
                                <div className={'text-blue-color ms-lg-3 ms-1 '}>
                                    <div className={'f-bold subheading'}>
                                        Michelle
                                    </div>
                                    <div className={'f-regular sm-text'}>
                                        +(971) 50 565 6310
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*<div className={'col-lg-4 col-md-12 mt-2'}>*/}
                        {/*    <div>*/}
                        {/*        <img src={'/assets/images/about/michelle-2.png'} alt={'Michelle'}*/}
                        {/*             className={'w-100 bg-profile z-index-999 position-relative'}/>*/}
                        {/*    </div>*/}
                        {/*    <div className={'d-flex justify-content-center mt-3'}>*/}
                        {/*        <div>*/}
                        {/*            <img src={'/assets/images/icons/phone-icon.png'} className={'w-45px'}*/}
                        {/*                 alt={'Phone Number'}/>*/}
                        {/*        </div>*/}
                        {/*        <div className={'text-blue-color ms-lg-3 ms-1 '}>*/}
                        {/*            <div className={'f-bold subheading'}>*/}
                        {/*                Michelle*/}
                        {/*            </div>*/}
                        {/*            <div className={'f-regular sm-text'}>*/}
                        {/*                +(971) 50 565 6310*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>

                <div className={'center-left to-top d-lg-block d-md-none d-none'}>
                    <img src={'/assets/images/mask/ellipse-blue.png'} alt={'Mask'}/>
                </div>

            </section>
        </section>
    );
};

export default Offer;
