import React, {FC} from 'react';

type Props = {
    title: string
    description?: string
    borderClass: string
}

const CardLayoutFive: FC<Props> = ({title, description, borderClass}) => {
    return (
        <>
            <div className={`card card-blue card-custom card-stretch gutter-b border-0 border-bottom ${borderClass}`}>
                <div className="card-body d-flex flex-column">
                    <div className="flex-grow-1">
                        <div className="p-3 text-center">
                            <div className="f-bold block-title text-blue-color mt-3">
                                {title}
                            </div>
                            {description &&
                                <p className="subheading f-secondary-regular text-blue-color mt-3 ps-3 pe-3 pt-4"
                                   dangerouslySetInnerHTML={{__html: description}}/>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CardLayoutFive;