import React, {useState} from 'react';

interface YouTubeEmbedProps {
    videoId: string;
    videoPoster: string;
    width?: string;
    height?: string;
    hideControls?: boolean;
}

const YouTubeEmbed: React.FC<YouTubeEmbedProps> = ({videoId, videoPoster, width = "560", height = "250", hideControls = true}) => {
    const [isPlaying, setIsPlaying] = useState(false);

    const handlePlayClick = () => {
        setIsPlaying(true);
    };

    const url = `https://www.youtube.com/embed/${videoId}?controls=${hideControls ? '0' : '1'}${isPlaying ? '&autoplay=1' : ''}&modestbranding=1`;

    return (
        <div style={{position: 'relative', width: '100%', height: '100%'}}>
            {!isPlaying && (
                <>
                    <div className="blue-overlay"></div>
                    <img src={videoPoster} alt={'Video'} className={'w-100'}/>
                    <div className={'play-btn centered-content text-center cursor-pointer'} onClick={handlePlayClick}>
                        <img className={'w-50 '} src={'/assets/images/icons/play-icon.png'}
                             alt={'Play Button'}/>
                    </div>
                </>
            )
            }
            <iframe
                width={width}
                height={height}
                src={url}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{display: isPlaying ? 'block' : 'none', minHeight: '250px'}}
                className="w-100 h-100"
            ></iframe>
        </div>
    );
};

export default YouTubeEmbed;
