import React, {FC} from "react";

const Termination: FC = () => {
    return (
        <div className={'container'}>
            <div className={'row pt-5 mt-5'}>
                <div className={'col-lg-11 pb-4'}>
                    <h3 className={'f-bold text-blue-color section-title mb-3'}>
                        <span className={'colored-nb me-3'}> 08 </span>
                        TERMINATION</h3>
                    <div className={'f-regular subheading text-blue-color'}>
                        <p className={'mt-4'}><span className={'f-bold'}>8.1 </span>These Terms of Service are effective
                            unless and until terminated by
                            either you or us.
                        </p>
                        <p><span className={'f-bold'}>8.2 </span>In the event that your subscription is cancelled or
                            terminated, you will lose access to the Services at the expiry of your subscription.</p>
                        <p><span className={'f-bold'}>8.3 </span>You may terminate these Terms of Service at any time by
                            cancelling your subscription to the Platform. </p>
                        <p><span className={'f-bold'}>8.4 </span>We may terminate your subscription to Platform’s
                            Services in the event that payment of your Access Fee is delayed.</p>
                        <p><span className={'f-bold'}>8.5 </span>If in our sole judgment you fail, or we suspect that
                            you have failed, to comply with any term or provision of these Terms of Service, we may
                            terminate this agreement at any time without notice and no refund of the Access Fee will be
                            provided.</p>
                        <p><span className={'f-bold'}>8.6 </span>We may also terminate your subscription to the
                            Platform’s Services at any time and for any reason with immediate effect.</p>
                        <p><span className={'f-bold'}>8.7 </span>The obligations and liabilities of the parties incurred
                            before the termination date shall survive the termination of this agreement for all
                            purposes.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Termination;
