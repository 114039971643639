import React, {FC} from "react";
import GetInTouch from "../../components/GetInTouch";
import TopBanner from "../../layout/base/TopBanner";
import Introduction from "./components/Introduction";
import Info from "./components/Info";
import Process from "./components/Process";

const Privacy: FC = () => {
    return (
        <section className={'privacy'}>
            <TopBanner title={'Terms Policy'} description={'Effective date: 09 March 2023'}/>
            <div className={'position-relative'}>
                <div className={'top-right-mask'}>
                    <img src={'/assets/images/mask/mask-top-right-to-bottom.png'} alt={'Mask'}/>
                </div>
                <Introduction/>
                <Info/>
                <Process/>
            </div>
            <GetInTouch/>
        </section>
    );
};

export default Privacy;
