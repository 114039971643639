import {Link} from "react-router-dom";
import React, {FC} from "react";

const Hero: FC = () => {

    return (
        <section className={'top-banner bg-main-gradient z-index-999'}>
            <div className={'container'}>
                <div className={'row justify-content-center align-items-center pt-5 pb-5'}>
                    <div className={'col-lg-6   pt-lg-4 pt-3 '}>
                        <div className={'mt-lg-5 pt-lg-5 mb-lg-5 pb-lg-5 pb-3'}>

                            <h1 className={'section-title text-white f-bold mt-5'}>Let's Partner Up</h1>
                            <p className={'description text-white f-light mt-4'}>
                                Want to launch SBC in your home city? Now is your chance to replicate a proven business
                                networking model and build a community of like-minded individuals.
                            </p>
                            <div>
                                <Link to={''} className={'btn btn-secondary ps-4 ps-5 pe-5'}>Become Our Partner
                                    Today</Link>
                            </div>
                        </div>
                    </div>
                    <div className={'col-lg-6 pt-4'}>
                        <div className={''}>
                            <img src={'/assets/images/partner/partner.png'} alt={'Partner'} className={'w-100'}/>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
};

export default Hero;