import React, {FC} from "react";
import HeroShow from "./components/HeroShow";
import {Link} from "react-router-dom";


const NetworkingWorkshopShow: FC = () => {

    return (
        <>
            <HeroShow/>
            <div className={'position-relative'}>
                <div className={'container pt-5 pb-5'}>
                    <div className={'row'}>
                        <div className={'col-lg-6 col-md-12 z-index-999'}>
                            <div className={'subheading f-regular text-blue-color'}>
                                <p>
                                    If you are doing business in the UAE across borders and wish to network effectively,
                                    or you are a company looking to build and nurture contacts here in the UAE, then
                                    feel free to join us for a super productive hour!</p>
                                <p> Dubai, especially, is a networking city, and it's all about who you know and how
                                    well you know them. So "build the roof before it rains" and start connecting
                                    with those key contacts in advance and hit the ground running.
                                </p>
                                <p> With over 24 years of experience in the UAE, your host Phil Bedford, is widely
                                    connected and happy to help.
                                </p>

                                <p>
                                    During the session you will meet other business owners who are either operating in
                                    the UAE already or looking at moving into this market. You will get the chance to
                                    present yourself and your business to the room as well as collect some useful
                                    resources.
                                </p>
                                <p> Common to the energy in the room is a willingness to help each other.
                                </p>
                                <p> If, for any reason, you can’t make it, please let me know, and we can invite you
                                    along the following week.
                                </p>
                                <p className={'f-semi-bold'}>Notes</p>

                                <ol className={'ps-3'}>
                                    <li className={'mt-2 mb-2'}> Please have your video on unless cultural requirements override this.</li>
                                    <li className={'mt-2 mb-2'}>“Entry Door “will close 5 minutes after we start so please be early. People who arrive once we have stated will regrettably not given entrance.</li>
                                    <li className={'mt-2 mb-2'}> Please stay until the end. If you can’t be with us for the whole session, I would recommend joining us next week.</li>
                                    <li className={'mt-2 mb-2'}>You will have approximately 2.5 minutes to introduce yourself and your business</li>
                                    <li className={'mt-2 mb-2'}>Please be stationary and not driving on our calls</li>
                                </ol>
                            </div>

                            <div className={'mt-5 mb-5 pb-5'}>
                                <div className={'bg-blue '}>
                                    <div className={'p-lg-5 p-3'}>
                                        <h3 className={'section-title f-bold text-white'}>Meet your host</h3>
                                        <div className={'mt-lg-4 mt-5'}>

                                            <div className={'position-relative'}>
                                                <img src={'/assets/images/networking/phil.png'} className={'w-100 z-index-999 position-relative'}
                                                     alt={'Phil Bedford'}/>
                                                <div className={'mask-2 d-lg-block d-none'}>
                                                    <img src={'/assets/images/mask/ellipse-pink-half.png'}
                                                         className={'ellipse'}
                                                         alt={'Mask'}/>
                                                </div>
                                                <div className={'position-absolute z-index-999 logo-phil'}>
                                                    <img src={'/assets/images/networking/rbl.png'} alt={'Logo'} className={''} />
                                                </div>
                                            </div>

                                            <div className={'mt-3'}>
                                                <h3 className={'main-title f-bold text-white'}>Phil Bedford</h3>
                                                <small className={'text-primary-color f-regular subheading'}>The Rebel Networker</small>
                                                <div className={'text-white f-light subheading mt-3'} >
                                                    <p >
                                                        Phil Bedford attributes his success to a commitment to
                                                        continuous education and skill development. As a certified
                                                        Professional Behavioral Analyst and NLP Master Practitioner,
                                                        Phil leverages his expertise to establish instant connections
                                                        and captivate audiences. Through his engaging blog and web TV
                                                        show at <Link className={'text-light-blue'} to={'www.therebelnetworker.com'} target={'_blank'}>www.therebelnetworker.com</Link>, Phil inspires and educates
                                                        individuals worldwide, empowering them to unlock their business
                                                        and personal potential.
                                                    </p>
                                                    <p>In 2015, Phil launched 'The Rebel Networker App,' offering users
                                                       an interactive networking platform. This app not only facilitates
                                                       networking but also enables users to track their activities,
                                                       fostering accountability and providing access to free educational
                                                       resources.
                                                    </p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className={'top-right-mask'}>
                    <img src={'/assets/images/mask/mask-top-right-to-bottom-lg.png'} alt={'Mask'}/>
                </div>
            </div>
        </>

    );
};

export default NetworkingWorkshopShow;
