import React, {FC} from "react";

const Point15: FC = () => {
    return (
        <div className={'container'}>
            <div className={'row '}>
                <div className={'col-lg-11 pb-4'}>
                    <h3 className={'f-bold text-blue-color section-title mb-3'}>
                        <span className={'colored-nb me-3'}> 15 </span>
                        CHANGES TO OUR PRIVACY POLICY</h3>
                    <div className={'f-regular subheading text-blue-color'}>
                        <p className={'mt-4'}>
                            It is our policy to post any changes we make to our privacy policy on this page. If we make
                            material changes to how we treat our users' personal information, we will notify you. The
                            date the privacy policy was last revised is identified at the top of the page. You are
                            responsible for ensuring we have an up-to-date active and deliverable email address for you,
                            and for periodically visiting our Platform and this privacy policy to check for any changes.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Point15;
