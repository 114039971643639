import React, {FC, useState} from "react";
import clsx from "clsx";
import {useFormik} from "formik";
import * as Yup from "yup";
import Select from 'react-select';
import {OptionType} from "../../models/OptionType";


const contactSchema = Yup.object().shape({
    email: Yup.string()
        .email("Wrong email format")
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Email is required"),

    name: Yup.string()
        .required("Name is required"),

    address: Yup.string()
        .required("Address is required"),

    phone_number: Yup.string()
        .required("Phone Number is required"),

    session: Yup.string()
        .required("Tester Session is required"),
});

const GetInTouchForm: FC = () => {
    const [loading, setLoading] = useState(false);

    const options: OptionType[] = [
        {value: '1', label: '1'},
        {value: '2', label: '2'},
        {value: '3', label: '3'}
    ];

    const initialValues = {
        email: "",
        name: "",
        address: "",
        phone_number: "",
        session: ""
    };

    const formik = useFormik({
        initialValues,
        validationSchema: contactSchema,
        onSubmit: async (values, {setSubmitting}) => {

            setSubmitting(true);
            try {
                setLoading(true);

                setLoading(false);

                formik.resetForm();
            } catch (ex) {
                console.error(ex);
            } finally {
                setSubmitting(true);
                setLoading(false);
            }
        },
    });

    return (
        <div className="card card-bordered-blue">
            <div className="p-4">
                <form className="form justify-content-center w-100" onSubmit={formik.handleSubmit} noValidate>
                    <div className="form-group row">
                        <div className="col-lg-6 col-md-12 pe-2 mt-3">
                            <input type="text" {...formik.getFieldProps("name")} className={clsx(
                                "form-control input-main",
                                {"is-invalid": formik.touched.name && formik.errors.name,},
                                {"is-valid": formik.touched.name && !formik.errors.name,}
                            )} placeholder="Full Name"/>
                            {formik.touched.name && formik.errors.name && (
                                <div className="text-message-container-danger">
                                    <span role="alert">{formik.errors.name}</span>
                                </div>
                            )}
                        </div>
                        <div className="col-lg-6 col-md-12 ps-2 mt-3">
                            <input type="email"  {...formik.getFieldProps("email")}
                                   placeholder="Email"
                                   className={clsx("form-control input-main", {"is-invalid": formik.touched.email && formik.errors.email,}, {"is-valid": formik.touched.email && !formik.errors.email,})}/>
                            {formik.touched.email && formik.errors.email && (
                                <div className="text-message-container-danger">
                                    <span role="alert">{formik.errors.email}</span>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-md-12 mt-3">
                            <input type="text" {...formik.getFieldProps("address")} className={clsx(
                                "form-control input-main",
                                {"is-invalid": formik.touched.address && formik.errors.address,},
                                {"is-valid": formik.touched.address && !formik.errors.address,}
                            )} placeholder="Address"/>
                            {formik.touched.address && formik.errors.address && (
                                <div className="text-message-container-danger">
                                    <span role="alert">{formik.errors.address}</span>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-md-12 mt-3">
                            <input type="text" {...formik.getFieldProps("phone_number")} className={clsx(
                                "form-control input-main",
                                {"is-invalid": formik.touched.phone_number && formik.errors.phone_number,},
                                {"is-valid": formik.touched.phone_number && !formik.errors.phone_number,}
                            )} placeholder="Phone Number"/>
                            {formik.touched.phone_number && formik.errors.phone_number && (
                                <div className="text-message-container-danger">
                                    <span role="alert">{formik.errors.phone_number}</span>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="form-group row">
                        <div className="col-md-12 mt-3">
                            <Select
                                className={clsx(
                                    "w-100 main-input",
                                    {"invalid-select": formik.touched.session && formik.errors.session,},
                                    {"valid-select": formik.touched.session && !formik.errors.session,}
                                )}
                                closeMenuOnSelect={false}
                                placeholder="Choose taster session"
                                options={options.map((e: {
                                    label: any;
                                    value: any
                                }) => ({
                                    label: e.label,
                                    value: e.value
                                }))}
                                isSearchable={true}
                                onChange={(newValue: any) =>
                                    formik.setFieldValue('session', newValue.value)
                                }
                            />

                            {formik.touched.session && formik.errors.session && (
                                <div className="text-message-container-danger">
                                    <span role="alert">{formik.errors.session}</span>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="row mt-lg-4 mt-3">
                        <div className="col-lg-5">
                            <button className="btn btn-primary bordered w-100"
                                    disabled={formik.isSubmitting || !formik.isValid} type="submit">
                                {
                                    !loading && (
                                        <span className="indicator-label">
                                            Get in touch
                                        </span>
                                    )
                                }
                                {
                                    loading && (
                                        <span className="indicator-progress"
                                              style={{display: "block"}}><span
                                            className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                    )
                                }
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};
export default GetInTouchForm;