import React, {FC} from "react";
import GetInTouch from "../../components/GetInTouch";
import TopBanner from "../../layout/base/TopBanner";
import MainSelect from "../../components/dropdown/MainSelect";
import CardLayoutFour from "../../components/cards/CardLayoutFour";
import {Link} from "react-router-dom";



const NetworkingWorkshopIndex: FC = () => {

    return (
        <section className={'networking'}>
            <TopBanner title={'Networking & Workshops'} description={'Scroll down, open up your calendar and book your next networking or workshop session!'}/>

            <div className={'position-relative'}>
                <div className={'container pt-5'}>
                    <div className={'row'}>
                        <div className={'col-lg-3 col-md-12 z-index-999'}>
                            <MainSelect placeHolder={'Dates'}/>
                        </div>
                    </div>
                    <div className={'row  mt-5 mb-3'}>
                        <div className={'col-lg-9'} >
                            <hr className={'border-op'} />
                        </div>
                    </div>

                    <div className={'row'}>
                        <div className={'col-md-9 '}>
                            <h3 className={'section-title text-blue-color f-bold'}>March 2024</h3>
                        </div>
                    </div>

                    <div className={'row justify-content-center'}>
                        {[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14].map((value, index) => (
                            <CardLayoutFour bgColor={'bg-network'} colClass={'col-lg-4'} key={index} image={'/assets/images/networking/network.png'} date={'19 March 2024'} title={'SBC International Business Connect (Online)'} time={'1300 - 14:00 UTC+4'} location={'Zoom'} btn={true}/>
                        ))}
                    </div>

                    <div className={'row justify-content-center mb-5'}>
                        <div className={'col-lg-2'}>
                            <Link to={''} className={'btn btn-secondary w-100'}>Load More</Link>
                        </div>
                    </div>
                </div>
                <div className={'top-right-mask'}>
                    <img src={'/assets/images/mask/mask-top-right-to-bottom.png'} alt={'Mask'}/>
                </div>
            </div>
            <GetInTouch/>
        </section>
    );
};

export default NetworkingWorkshopIndex;
