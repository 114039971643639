import React, {FC} from "react";
import Point3 from "./Point3";
import Point4 from "./Point4";
import Point11 from "./Point11";
import Point5 from "./Point5";
import Point6 from "./Point6";
import Point7 from "./Point7";
import Point8 from "./Point8";
import Point9 from "./Point9";
import Point10 from "./Point10";
import Point12 from "./Point12";
import Point13 from "./Point13";
import Point14 from "./Point14";
import Point15 from "./Point15";
import Point16 from "./Point16";

const Process: FC = () => {
    return (
        <div className={'position-relative'}>
            <div className={'container'}>
                <div className={'row pt-5'}>
                    <div className={'col-lg-11'}>
                        <h3 className={'f-bold text-blue-color section-title mb-3'}>
                            <span className={'colored-nb me-3'}> 02 </span>
                            HOW DO WE COLLECT IT</h3>

                        <div className={'f-regular subheading text-blue-color mt-4'}>
                            <p>
                                We collect the information detailed above:</p>
                            <p>
                                <span className={'f-bold'}> (a)</span> Directly from you when you provide it to us
                                e.g., when you register on our Platform or while filling in forms on the Platform; or
                                when you correspond with us, in which case we maintain records and copies of your
                                correspondence (including email addresses) if you contact us;
                            </p>
                            <p>
                                <span className={'f-bold'}>(b)</span> Automatically as you navigate through the
                                Platform including traffic data, location data, and other communication data.
                                Information collected automatically may also include usage details, and details of your
                                visit to the Platform. IP addresses and information are collected through cookies and
                                other tracking technologies.
                            </p>
                            <p><span className={'f-bold'}> (c) </span> Third-party data: We may also collect data
                                from third-party automated sources (via cookies, plugins and other similar technologies)
                            </p>
                            <p><span className={'f-bold'}>(d)</span> Via Google’s Application Programming Interface
                                (API) Services to enable the use of certain Platform features (i.e., use of your Gmail
                                account to calendar events from the Platform’s Coaching feature). The information
                                collected and used with these API services includes personal data such as email
                                addresses, calendar information, and first and last names. Your Google information is
                                used by our system developers to provide or improve user-facing features that are
                                prominent to your user experience.
                            </p>
                            <p><span className={'f-bold'}>(e)</span> When you give us feedback to contact you.
                            </p>

                        </div>
                    </div>
                </div>
            </div>
            <Point3/>
            <Point4/>
            <Point5/>
            <Point6/>
            <Point7/>
            <Point8/>
            <Point9/>
            <Point10/>
            <Point11/>
            <Point12/>
            <Point13/>
            <Point14/>
            <Point15/>
            <Point16/>

        </div>

    );
};

export default Process;
