import React, {FC} from "react";
import AccordionItems from "../../../components/accordion/AccordionItems";


const AccordionQuestions: FC = () => {
    const items = [
        {
            header: 'Q. Who are the other entrepreneurs in the community?',
            body : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do  eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in  culpa qui officia deserunt mollit anim id est laborum.'
        },
        {
            header: 'Q. What’s the eligibility criteria for someone to be accepted into the community?',
            body : 'Ans: SBC community members are concerned about bringing value to the organization as a whole. They are eager to assist when asked and do not hesitate to seek assistance or guidance when necessary.<br /><br/>We\'ll have a call before you sign to see if we\'re a good fit. And if so, I\'ll gladly welcome you aboard.\n'
        },
        {
            header: 'Q. Can I really participate if I’m away from Dubai?',
            body : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do  eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in  culpa qui officia deserunt mollit anim id est laborum.'
        },
        {
            header: 'Q. Can I get a refund or stall my membership if I find that I’m not receiving value?',
            body : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do  eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in  culpa qui officia deserunt mollit anim id est laborum.'
        },
    ]
    return (
        <section className={' bg-secondary-gradient position-relative'}>
            <div className={'container pt-5 pb-5'}>
                <div className={'row justify-content-center align-items-center pt-5 pb-5'}>
                    <div className={'col-lg-9 col-md-12 text-center'}>
                        <h3 className={'section-title f-bold text-white'}>
                            FAQs: YOUR QUESTIONS, ANSWERED
                        </h3>
                    </div>
                </div>

                <div className={'row justify-content-center align-items-center mb-5'}>

                    <div className={'col-md-12'}>
                       <AccordionItems items={items} />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AccordionQuestions;
