import React, {FC} from 'react';
import {Link} from "react-router-dom";

type Props = {
    image: string
    date?: string
    title: string
    time?: string
    location?: string
    btn: boolean
    description?: string
    btnText?: string
    colClass: string
    dateClass?: string
    bgColor?: string
}

const CardLayoutFour: FC<Props> = ({
                                       colClass,
                                       image,
                                       dateClass,
                                       date,
                                       title,
                                       time,
                                       location,
                                       btn,
                                       description,
                                       btnText,
                                       bgColor
                                   }) => {

    return (

        <div className={`${colClass} col-md-12 pt-2 pb-2 z-index-999`}>
            <div
                className={`${bgColor ? bgColor : 'bg-white'} card card-shadow card-custom card-stretch gutter-b ${description && 'border-0'}`}>
                <div className="card-body d-flex flex-column">
                    <div className="flex-grow-1">
                        <div className="card-image-sbc">
                            <img src={image} alt="Card" className="w-100"/>
                        </div>
                        <div className={'p-2'}>
                            {date && <div
                                className={`${dateClass ? dateClass : 'text-primary-color'} f-regular letter-space-2 subheading mt-3`}>{date}</div>}

                            <div className="f-bold block-title text-blue-color mt-3">
                                {title}
                            </div>
                            {time && location &&
                                <div className="f-light text-blue-color mt-4">
                                    <div className="mb-2"><i
                                        className="fa-regular fa-clock text-light-blue me-3"/>{time}</div>
                                    <div className="mb-2"><img src={'/assets/images/icons/location.png'}
                                                               width="12" alt="Icon"
                                                               className="me-3"/>{location}</div>
                                </div>
                            }

                            {description &&
                                <p className="subheading f-regular text-blue-color mt-3"
                                   dangerouslySetInnerHTML={{__html: description}}/>
                            }

                        </div>
                    </div>

                    {btn &&
                        <div className="align-items-start mt-2 mb-2 p-3">
                            <Link to="/networking-and-workshop/show"
                                  className="btn btn-primary bordered ps-5 pe-5">{btnText ? btnText : 'Book Now'}</Link>
                        </div>
                    }

                </div>
            </div>
        </div>
    );
};

export default CardLayoutFour;