import React, {FC} from "react";
import CardLayoutFour from "../../../components/cards/CardLayoutFour";

const Benefits: FC = () => {
    const benefits = [
        {
            title: 'Unlock Support',
            description: 'From setup to scaling, SBC will provide guidance every step of the way. Benefit from our extensive experience, resources, and network to establish and grow your community.',
            imageUrl: '/assets/images/partner/1.png',
        },
        {
            title: 'Business Growth',
            description: 'SBC will help you attract a diverse membership base, and gain access to valuable resources, workshops, and networking events that generate business opportunities.',
            imageUrl: '/assets/images/partner/2.png',
        },
        {
            title: 'Community',
            description: 'Join a network of ambitious leaders and benefit from cross-border networking events that foster international business relationships.',
            imageUrl: '/assets/images/partner/3.png',

        },
    ];

    return (
        <section className={'bg-secondary-blue position-relative pb-5'}>
            <div className={'container'}>
                <div className={'row justify-content-center'}>
                    <div className={'col-md-10 text-center mt-5 mb-5 pt-5'}>
                        <h3 className={'text-white section-title f-bold'}>Partnership Perks</h3>
                        <p className={'main-description text-white f-semi-bold'}>Join us for customized assistance, advice for business growth, and access to a vibrant community.</p>
                    </div>
                </div>
                <div className={'row justify-content-center'}>
                    {benefits.map((benefit:any, index:any) => (
                        <CardLayoutFour  colClass={'col-lg-4'} btn={false} title={benefit.title} description={benefit.description} image={benefit.imageUrl} key={index}/>
                    ))}
                </div>
            </div>

            <div className={'center-left'}>
                <img src={'/assets/images/mask/ellipse-pink.png'} alt={'Mask'}/>
            </div>
        </section>
    );
};

export default Benefits;