import React, {FC} from "react";

const Point4: FC = () => {
    return (
        <div className={'position-relative'}>
            <div className={'container'}>
                <div className={'row pt-5'}>
                    <div className={'col-lg-11 pb-4'}>
                        <h3 className={'f-bold text-blue-color section-title mb-3'}>
                            <span className={'colored-nb me-3'}> 04 </span>
                            HOW WE STORE YOUR PERSONAL DATA</h3>

                        <div className={'f-regular subheading text-blue-color'}>
                            <p className={'mt-4'}><span className={'f-bold'}>4.1 </span> We use Kajabi and Community Box
                                to store your data from the Platform. </p>
                            <p><span className={'f-bold'}>4.2 </span>
                                We implement reasonable organizational level, physical, and technical security
                                measures to help guard your data from any loss, theft, misuse, and unauthorized access,
                                disclosure, alteration, and destruction. However, no physical or electronic security
                                system is impenetrable.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'position-absolute ellipse-right'}>
                <img src={'/assets/images/mask/mask-right.png'} className={''} alt={'Mask'}/>
            </div>

        </div>
    );
};

export default Point4;
