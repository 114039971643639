import React, {FC} from 'react';
import {Link} from "react-router-dom";


const Hero: FC = () => {

    return (
        <section className={'mt-lg-5 mt-3 pt-lg-5 pt-3 mb-lg-5 mv-3 pb-lg-5 pb-3'}>
            <div className={'container'}>
                <div className={'row justify-content-center align-items-center'}>
                    <div className={'col-lg-6'}>
                        <div>
                            <div className={'d-flex'}>
                                <img src={'/assets/images/icons/check-pink-icon.png'}
                                     className={'mt-2 check-icon'} alt={'Icon'}/>
                                <h3 className={'xl-text text-blue-color f-bold ms-lg-4 ms-2'}>Secure clients before
                                    arriving in Dubai</h3>
                            </div>

                            <hr className={'border-light-primary'}/>

                            <div className={'d-flex'}>
                                <img src={'/assets/images/icons/check-pink-icon.png'}
                                     className={'mt-2 check-icon'} alt={'Icon'}/>
                                <h2 className={'xl-text text-blue-color f-bold ms-lg-4 ms-2'}>Expand your network</h2>
                            </div>

                            <hr className={'border-light-primary'}/>

                            <div className={'d-flex'}>
                                <img src={'/assets/images/icons/check-pink-icon.png'}
                                     className={'mt-2 check-icon'} alt={'Icon'}/>
                                <h3 className={'xl-text text-blue-color f-bold ms-lg-4 ms-2'}>Understand business procedures</h3>
                            </div>
                        </div>
                    </div>
                    <div className={'col-lg-6'}>
                        <div className={'card card-bordered-blue'}>
                            <div className={'p-4'}>
                                <div className={'text-center mt-4'}>
                                    <h3 className={'block-title f-regular text-blue-color m-2'}>
                                        SBC is the leading networking platform in Dubai. Connect with potential clients
                                        and accelerate deal closures today, no matter where you are.
                                        {/*<span className={'text-primary-color'}>Dubai's Premier Networking Platform.</span> Engage with Your <span className={'text-primary-color'}> Ideal Prospects</span> and Close Deals Faster - No Matter Where You Are!*/}
                                    </h3>

                                    <div className={'mt-4 mb-4'}>
                                        <Link to={''} className={'btn btn-primary bordered w-75'}>BOOK CALL</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Hero;