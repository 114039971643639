import TopBanner from "../../layout/base/TopBanner";
import React from "react";
import {Link} from "react-router-dom";

type Package = {
    title: string;
    description: string,
    price: string,
    currency: string,
    content: string[],
    link: string,
    is_popular: boolean
}

export const Sponsorship = () => {
    const packages: Package[] = [
        {
            title: "Silver",
            description: "Sponsor one SBC event",
            price: "5,950",
            currency: "aed",
            content: [
                "Logo on table tent during one event",
                "Social media tags",
                "Complimentary event access for one team member per month"
            ],
            link: "#",
            is_popular: false
        },
        {
            title: "Platinum",
            description: "Full Sponsorship",
            price: "29,950",
            currency: "aed",
            content: [
                "Logo on event collaterals",
                "Logo on all SBC table tents",
                "Social media tags",
                "Promotional email to database",
                "Complimentary access to all SBC events",
                "One SBC VIP Access"
            ],
            link: "#",
            is_popular: true
        },
        {
            title: "Gold",
            description: "Sponsor all sessions",
            price: "8,950",
            currency: "aed",
            content: [
                "Logo on all SBC table tents",
                "Social media tags",
                "Complimentary access for one staff member to attend 3 sessions/month"
            ],
            link: "#",
            is_popular: false
        }
    ]

    return (
        <section className="sponsorhsip">
            <TopBanner title="Network, Grow, Succeed" description="Partner with SBC to drive your business growth"/>

            <div className="container">
                <div className="row align-items-center justify-content-center text-center mt-5 mb-md-5 mb-2 pb-5">
                    <div className="col-md-10">
                        <p className="text-blue-color f-light subheading text-center">
                            At Strategic Business Connect (SBC), we offer premier networking opportunities that enhance
                            business relationships. Sponsoring SBC gives your brand prime exposure to a vibrant
                            community with exclusive event access.
                        </p>

                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="packages">
                            {
                                packages.map((item: Package, index: number) => {
                                    return (
                                        <div
                                            className={`package text-left ${item.is_popular ? 'middle bg-primary-color border-primary-color' : 'bg-white border-light-grey'} ${index === 0 ? 'border-right-0' : (index === 2 ? 'border-left-0' : '')}`}
                                            key={`package-${index}`}>

                                            {
                                                item.is_popular &&
                                                <div className="tag text-blue-color text-uppercase">
                                                    Popular
                                                </div>
                                            }

                                            <div
                                                className={`header ${item.is_popular ? 'bg-primary-color' : 'bg-blue'} p-30`}>
                                                <h4 className="text-white text-uppercase f-bold mb-0">{item.title}</h4>
                                                <p className={`${item.is_popular ? 'text-white' : 'text-light-blue'} main-description m-0`}>{item.description}</p>
                                            </div>
                                            <div className={`body ${item.is_popular ? 'pt-0 pb-30 px-30' : 'p-30'}`}>
                                                <div
                                                    className={`price section-title f-bold ${item.is_popular ? 'text-white' : 'text-primary-color'} pb-4`}>{item.price} {item.currency}</div>
                                                <ul className={`subheading f-medium circle-checklist ${item.is_popular ? 'text-white' : 'text-blue-color'}`}>
                                                    {
                                                        item.content.map((text: string, indexContent: number) => {
                                                            return (
                                                                <li className={`subheading ${item.is_popular ? 'text-white' : 'text-blue-color'}`}
                                                                    key={`package-content-text-${indexContent}`}>{text}</li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                                <div className="mt-5">
                                                    <Link to={item.link}
                                                          className={`btn ${item.is_popular ? 'btn-white thick-border' : 'btn-primary'} bordered ps-5 pe-5`}>Get
                                                        Started</Link>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>

                <div className="row pt-5 pb-5">
                    <div className="col-md-12 text-center pt-md-5 pt-0 pb-md-5 pb-0 mb-md-5 mb-0">
                        <p className="text-blue-color f-light subheading text-center">
                            For more information,
                            <br/>
                            contact us at <span className="f-bold">+971 508487188</span> or browse through <span
                            className="f-bold
                            ">our website</span> to learn
                            more about what we do.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}