import React, {FC} from "react";

const Point7: FC = () => {
    return (
        <div className={'bg-blue position-relative'}>
            <div className={'container'}>
                <div className={'row pt-5'}>
                    <div className={'col-lg-11 pt-4 pb-5'}>
                        <h3 className={'f-bold text-white section-title mb-3 me-lg-5'}>
                            <span className={'colored-nb me-3'}> 07 </span>
                            OUR LEGAL BASIS FOR PROCESSING YOUR PERSONAL INFORMATION</h3>

                        <div className={'f-regular subheading text-white mt-5'}>
                            <p>
                                <span className={'f-bold'}>7.1 </span>
                                To the extent required by applicable law, we will collect your personal data with your
                                consent, where required, and in accordance with our legitimate need to provide our
                                Platform.
                            </p>
                            <p><span className={'f-bold'}>7.2 </span>
                                Under European data protection laws, our Platform is required to rely on one or more
                                legal bases to collect, use, share, and otherwise process the information for a.)
                                performing Services under this Privacy Policy and Terms of Use b.) Using your
                                information to maintain your registration c.) for the intended purpose of use of the
                                Platform.
                            </p>
                            <p><span className={'f-bold'}>7.3 </span>
                                Some of the basis for processing your data may include the following:
                            </p>
                            <p><span className={'f-bold'}>a.) </span>
                                Your consent, which is revocable at any time
                            </p>
                            <p><span className={'f-bold'}>b.)</span> Legal Obligation</p>
                            <p><span className={'f-bold'}>c.)</span> Vital Interest</p>
                            <p>
                                <span className={'f-bold'}> 7.3</span> The bases for processing your data may
                                include the following:
                            </p>

                            <p>
                                <span className={'f-bold'}>(a)</span>
                                Cookies and similar technologies, verification of personal data like addresses, and
                                marketing communication
                            </p>
                            <p><span className={'f-bold'}> (b) </span>
                                Any data and personal information requests, required to be processed under the
                                applicable laws.
                            </p>
                            <p><span className={'f-bold'}>(c)</span>
                                Any data required to be preserved and shared to avoid harm to the general public.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className={'position-absolute ellipse-top-right ellipse'}>
                <img src={'/assets/images/mask/ellipse-pink-half.png'} className={''} alt={'Mask'}/>
            </div>
        </div>
    );
};

export default Point7;
