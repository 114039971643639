import React, {FC} from "react";
import CardLayoutFour from "../../../components/cards/CardLayoutFour";
import {Link} from "react-router-dom";

const Blog: FC = () => {
    const blogs = [
        {
            date: "12 July 2024",
            title: "Build your Network Before Your Business",
            imageUrl : "/assets/images/rebel-network/blog-1.png"
        },
        {
            date: "05 July  2024",
            title: "How to Audit Your Marketing Strategy for 2021 and Gain Better Results",
            imageUrl : "/assets/images/rebel-network/blog-2.png"
        },
        {
            date: "29 March 2024",
            title: "How I earned $100,000 in 2 months during a disaster",
            imageUrl : "/assets/images/rebel-network/blog-3.png"
        },
    ]

    return (
        <section className={'bg-primary-color position-relative'}>
            <div className={'container'}>
                <div className={'row justify-content-between align-items-center'}>
                    <div className={'col-md-4 mt-5 mb-5 z-index-999 pt-5'}>
                        <h3 className={'section-title text-white f-bold text-uppercase'}>Read it on our blog</h3>
                    </div>
                    <div className={'col-md-2 mt-5 mb-5 z-index-999 pt-5'}>
                        <Link to={''} className={'btn btn-secondary ps-4 w-100'}>explore more</Link>
                    </div>
                </div>
                <div className={'row pb-5 mb-5 z-index-999'}>
                    {blogs.map((blog: any , index) => (
                        <CardLayoutFour key={index} image={blog.imageUrl} title={blog.title} btn={true} colClass={'col-lg-4  pb-5'} btnText={'Read More'} date={blog.date} dateClass={'text-light-blue'} />

                    ))}
                </div>
            </div>

            <div className={'center-left'}>
                <img src={'/assets/images/mask/ellipse-blue.png'} alt={''}/>
            </div>

        </section>
    );
};

export default Blog;
