import React, {FC} from "react";
import {Link} from "react-router-dom";

const Access: FC = () => {
    return (
        <div className={'bg-blue-c'}>
            <div className={'container'}>
                <div className={'row pt-5 mb-2'}>
                    <div className={'col-lg-11 pt-4 pb-4'}>
                        <h3 className={'f-bold text-blue-color section-title mb-3'}>
                            <span className={'colored-nb me-3'}> 01 </span>
                            ACCESS & USE</h3>

                        <div className={'f-regular subheading text-blue-color mt-3'}>
                            <p>
                                <span className={'f-bold'}>1.1 </span> You are eligible to register on the Platform and
                                benefit from the Services if you are above the legal age for purchasing products in your
                                country of residence.</p>
                            <p>
                                <span className={'f-bold'}>1.2 </span> In order to register on the Platform, you will
                                need to provide us with certain information. Your registration on the Platform may not
                                be accepted if you do not provide us with the required information or if you have not
                                provided valid, true and accurate information. If you represent yourself as the owner of
                                a business, we may request copies of the corporate documents of the business.</p>
                            <p>
                                <span className={'f-bold'}>1.3 </span> You may avail the option of listing your business
                                on the Platform which may be searched for and viewed by other Users and Visitors for the
                                term of your use of the Platform. Should you wish to use this feature, you will be
                                required to submit certain personal data as detailed in our privacy policy.</p>

                            <p>
                                <span className={'f-bold'}>1.4 </span> We reserve the right to decline any
                                registration without further explanation. We reserve the right to undertake such
                                required checks that are necessary to verify your identity.</p>

                            <p>
                                <span className={'f-bold'}>1.5 </span> Once you have successfully completed
                                registration, your registration shall continue for an indefinite period, subject to
                                suspension or termination per these Terms of Service.</p>

                            <p>
                                <span className={'f-bold'}>1.6 </span> If you set up an account on our Platform, you
                                must treat your login details (including password) as confidential. You must not
                                disclose them to any third party. </p>

                            <p>
                                <span className={'f-bold'}>1.7 </span> We have the right to disable any account at any
                                time if in our reasonable opinion you have failed to comply with any of the provisions
                                of these Terms of Service</p>

                            <p>
                                <span className={'f-bold'}>1.8 </span> Your use of the Services and Platform grants
                                us the right to use, reuse and publish your photos and videos in connection with events
                                hosted by the Platform, and in relation to any of the Services of the Platform. You also
                                waive any right to royalties, remuneration or compensation arising in relation to such
                                photos and videos. In the event you do not wish for your photos or videos to be used or
                                published, please let us know in writing.</p>

                            <p>
                                <span className={'f-bold'}>1.9 </span> You will need to provide us with certain personal
                                data for you to obtain Access to the Platform. For information regarding how we process
                                your personal data, please see our Privacy Policy (<Link
                                to={'https://www.strategicbusinessconnect.com/privacy-policy'}
                                className={'link-blue-light'}>https://www.strategicbusinessconnect.com/privacy-policy</Link>).
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Access;
