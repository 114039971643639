import React, {FC} from "react";
import {Link} from "react-router-dom";

const Point12: FC = () => {
    return (
        <div className={'container'}>
            <div className={'row '}>
                <div className={'col-lg-11 pb-4'}>
                    <h3 className={'f-bold text-blue-color section-title mb-3'}>
                        <span className={'colored-nb me-3'}> 12 </span>
                        MARKETING</h3>
                    <div className={'f-regular subheading text-blue-color'}>
                        <p className={'mt-4'}><span className={'f-bold'}>12.1 </span>
                            Our company would like to send you information about our services of ours from time to time,
                            that we think might interest you.
                        </p>
                        <p><span className={'f-bold'}>12.2 </span>
                            If you have agreed to receive marketing, you may always opt out at a later date.
                            However, please note that you may miss out on important updates, sessions, or advisories and
                            may not be able to enjoy the full benefits, functionality, and experience of the Platform,
                            which may impact the value you derive from the Platform for your business.
                        </p>
                        <p><span className={'f-bold'}>12.3</span>
                            We may collect and share some of this information directly with advertising partners,
                            and in some cases through the use of cookies or other similar technologies (which you may
                            consent to, depending on your location).
                        </p>
                        <p><span className={'f-bold'}>12.4 </span>For more information on how targeted advertising
                            works, you can visit the Network Advertising
                            Initiative’s (“NAI”) educational page at <Link
                                className={'link-blue-light'}
                                to={'https://thenai.org/about-online-advertising/faq/?tab=2'}>https://thenai.org/about-online-advertising/faq/?tab=2</Link>
                        </p>
                        <p><span className={'f-bold'}>12.5</span>If you no longer wish to be contacted for marketing
                            purposes write us an email at <Link
                                className={'link-blue-light'}
                                to={'mailto:hello@strategicbusinessconnect.com'}>hello@strategicbusinessconnect.com</Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Point12;
