import React, {FC} from "react";
import {Link} from "react-router-dom";

const Point16: FC = () => {
    return (
        <div className={'container'}>
            <div className={'row mb-5'}>
                <div className={'col-lg-11 pb-4'}>
                    <h3 className={'f-bold text-blue-color section-title mb-3'}>
                        <span className={'colored-nb me-3'}> 16 </span>
                        CONTACT INFORMATION</h3>
                    <div className={'f-regular subheading text-blue-color'}>
                        <p className={'mt-4'}>To ask questions or comment about this privacy policy and our privacy
                            practices, contact us at: <Link
                                className={'link-blue-light'}
                                to={'mailto:hello@strategicbusinessconnect.com'}>hello@strategicbusinessconnect.com</Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Point16;
