import React, {FC, useState} from "react";
import clsx from "clsx";
import {useFormik} from "formik";
import * as Yup from "yup";
import Select from 'react-select';
import {OptionType} from "../../../models/OptionType";
import {Link} from "react-router-dom";

const contactSchema = Yup.object().shape({
    email: Yup.string()
        .email("Wrong email format")
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Email is required"),

    name: Yup.string()
        .required("Name is required"),

    credit_card: Yup.string()
        .required("Credit Card is required"),

    phone_number: Yup.string()
        .required("Phone Number is required"),

    session: Yup.string()
        .required("This field is required"),
});

const SubscriptionForm: FC = () => {
    const [loading, setLoading] = useState(false);
    // const [isActive, setIsActive] = useState(false);

    const options: OptionType[] = [
        {value: '1', label: 'Option 1'},
        {value: '2', label: 'Option 2'},
        {value: '3', label: 'Option 3'}
    ];

    const initialValues = {
        email: "",
        name: "",
        credit_card: "",
        phone_number: "",
        session: ""

    };

    const formik = useFormik({
        initialValues,
        validationSchema: contactSchema,
        onSubmit: async (values, {setSubmitting}) => {

            setSubmitting(true);
            try {
                setLoading(true);
                // const {data} = await bookEvent(event.id , values);

                // if (data.success === 1) {
                setLoading(false);
                // setIsActive(true);

                formik.resetForm();
                // setTimeout(function () {
                //     setIsActive(false);
                //     // resetForm()
                // }, 4500);
                // }
            } catch (ex) {
                console.error(ex);
            } finally {
                setSubmitting(true);
                setLoading(false);
            }
        },
    });
    return (
        <>
            <div className={'card shadow'}>
                <div className={'p-4'}>
                    <div className={'text-center'}>
                        <h4 className={'section-title f-bold text-blue-color   pb-3'}>Yearly subscription of <span
                            className={'text-primary-color'}>USD 682 </span>+ VAT</h4>

                        <Link to={'/login'}
                              className={'text-blue-color f-regular subheading text-decoration-underline text-uppercase'}>Login</Link>
                    </div>
                    <form className="form justify-content-center w-100 pt-3" onSubmit={formik.handleSubmit} noValidate>
                        <div className={'form-group row'}>
                            <div className={'col-lg-12 col-md-12 ps-2 mt-3'}>
                                <input type={'email'}  {...formik.getFieldProps("email")}
                                       placeholder={"Email"}
                                       className={clsx("form-control input-main", {"is-invalid": formik.touched.email && formik.errors.email,}, {"is-valid": formik.touched.email && !formik.errors.email,})}/>
                                {formik.touched.email && formik.errors.email && (
                                    <div className="text-message-container-danger">
                                        <span role="alert">{formik.errors.email}</span>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={'form-group row'}>
                            <div className={'col-lg-12 col-md-12 pe-2 mt-3'}>
                                <input type={'text'} {...formik.getFieldProps("name")} className={clsx(
                                    "form-control input-main",
                                    {"is-invalid": formik.touched.name && formik.errors.name,},
                                    {"is-valid": formik.touched.name && !formik.errors.name,}
                                )} placeholder="Full Name"/>
                                {formik.touched.name && formik.errors.name && (
                                    <div className="text-message-container-danger">
                                        <span role="alert">{formik.errors.name}</span>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className={'form-group row'}>

                            <div className={'col-md-12 mt-3'}>
                                <label className={'text-main-input xs-text f-medium text-uppercase mb-3'}>Phone
                                    Number</label>

                                <input type={'text'} {...formik.getFieldProps("phone_number")} className={clsx(
                                    "form-control input-main",
                                    {"is-invalid": formik.touched.phone_number && formik.errors.phone_number,},
                                    {"is-valid": formik.touched.phone_number && !formik.errors.phone_number,}
                                )} placeholder=""/>
                                {formik.touched.phone_number && formik.errors.phone_number && (
                                    <div className="text-message-container-danger">
                                        <span role="alert">{formik.errors.phone_number}</span>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className={'form-group row'}>
                            <div className={'col-md-12 mt-3'}>
                                <label className={'text-main-input xs-text f-medium text-uppercase mb-3'}>Which SBC
                                    Community are
                                    you from or
                                    interested
                                    in?</label>
                                <Select
                                    className={clsx(
                                        "w-100 main-input",
                                        {"invalid-select": formik.touched.session && formik.errors.session,},
                                        {"valid-select": formik.touched.session && !formik.errors.session,}
                                    )}
                                    closeMenuOnSelect={false}
                                    placeholder={''}
                                    options={options.map((e: {
                                        label: any;
                                        value: any
                                    }) => ({
                                        label: e.label,
                                        value: e.value
                                    }))}
                                    isSearchable={true}
                                    onChange={(newValue: any) =>
                                        formik.setFieldValue('session', newValue.value)
                                    }
                                />

                                {formik.touched.session && formik.errors.session && (
                                    <div className="text-message-container-danger">
                                        <span role="alert">{formik.errors.session}</span>
                                    </div>
                                )}

                            </div>
                        </div>

                        <div className={'form-group row '}>
                            <div className={'col-md-12 mt-3'}>
                                <div className={' position-relative'}>
                                    <input type={'text'} {...formik.getFieldProps("credit_card")} className={clsx(
                                        "form-control input-main text-indent-50",
                                        {"is-invalid": formik.touched.credit_card && formik.errors.credit_card,},
                                        {"is-valid": formik.touched.credit_card && !formik.errors.credit_card,}
                                    )} placeholder="card number"/>
                                    <div className={'card-image'}>
                                        <img src={'/assets/images/icons/atm.png'} alt={'Card'}/>
                                    </div>
                                </div>
                                {formik.touched.credit_card && formik.errors.credit_card && (
                                    <div className="text-message-container-danger">
                                        <span role="alert">{formik.errors.credit_card}</span>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={'form-group row'}>
                            <div className={'col-md-12 mt-3'}>
                                <label className={'text-main-input xs-text f-regular mb-3'}><span className={'me-2'}><i
                                    className="fa-solid fa-lock"></i></span> Your payment information will be stored on
                                    a secure server for future
                                    purchases</label>
                            </div>
                        </div>

                        <div className={'form-group row'}>
                            <div className={'col-md-12 mt-2'}>
                                <div className={''}>
                                    <input
                                        type="checkbox"
                                        className="custom-checkbox"

                                    />
                                    <span className={`text-main-input xs-text f-regular mb-3 ms-2`}>Subscribe to receive notifications via email for purchase requests, connections, and opportunities for business growth.</span>
                                </div>
                            </div>
                        </div>
                        <div className={'form-group row'}>
                            <div className={'col-md-12 mt-2'}>
                                <div className={''}>
                                    <input
                                        type="checkbox"
                                        className="custom-checkbox"

                                    />
                                    <span className={`text-main-input xs-text f-regular mb-3 ms-2`}>I have read and agree to the terms and conditions outlined on this page.</span>
                                </div>
                            </div>
                        </div>
                        <div className={'form-group row'}>
                            <div className={'col-md-12 mt-4'}>
                                <div className={'bg-light-secondary bdr-10 text-main-input xs-text f-regular mb-3 p-4'}>
                                    <p>
                                        Jumpstart your VIP access and supercharge your networking with the Referral
                                        Accelerator. This 2-hour LIVE workshop unveils the science of referral
                                        marketing, empowering you to achieve quicker results with SBC. Only 195 AED +
                                        VAT.
                                    </p>
                                    <div className={''}>
                                        <input
                                            type="checkbox"
                                            className="custom-checkbox"

                                        />
                                        <span className={` ms-2`}> Yes, I'd like to add the workshop!</span>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className={'row mt-lg-4 justify-content-center mt-3'}>
                            <div className={'col-lg-7'}>
                                <button className="btn btn-primary bordered w-100 "
                                        disabled={formik.isSubmitting || !formik.isValid} type="submit">
                                    {!loading && (
                                        <span className="indicator-label">
                                 complete my purchase
                                </span>
                                    )}
                                    {loading && (
                                        <span className="indicator-progress"
                                              style={{display: "block"}}><span
                                            className="spinner-border spinner-border-sm align-middle ms-2"></span></span>)}
                                </button>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </>
    );
};
export default SubscriptionForm;