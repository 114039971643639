import React, {Suspense, useEffect, useState} from 'react';
import {Outlet, useLocation} from "react-router-dom";
import PreviewPanel from "./components/PreviewPanel";
import {MasterLayoutContext} from "./layout/MasterLayoutContext";
import {OptionType} from "./models/OptionType";
import {City, Country} from 'country-state-city';
import LoadingIndicator from "./components/LoadingIndicator";
import Header from "./layout/base/Header";
import Footer from "./layout/base/Footer";

function App() {
    const location = useLocation();
    const [countries, setCountries] = useState<OptionType[]>([]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    useEffect(() => {
        const iCountries = Country.getAllCountries();
        const allCountries: OptionType[] = [];

        iCountries.forEach((country) => {
            if (country.name.toLowerCase() !== 'israel') {
                const cities = City.getCitiesOfCountry(country.isoCode);
                if (cities && cities?.length > 0) {
                    const countryOptionType: OptionType = {value: country.isoCode, label: country.name};
                    allCountries.push(countryOptionType)
                }
            }
        });

        setCountries(allCountries)
    }, []);

    const getCitiesOfCountry = (countryCode: string) => {
        const citiesOfCountry = City.getCitiesOfCountry(countryCode)
        const cities: OptionType[] = [];

        citiesOfCountry?.forEach((city) => {
            cities.push({value: city.name.toLowerCase(), label: city.name})
        })

        return cities;
    }

    return (
        <MasterLayoutContext.Provider value={{countries, setCountries, getCitiesOfCountry}}>
            <Suspense fallback={<LoadingIndicator/>}>
                <Header/>
                <Outlet/>
                {
                    (!process.env.REACT_APP_ENV ||
                        process.env.REACT_APP_ENV === 'local' ||
                        process.env.REACT_APP_ENV === 'staging'
                    ) &&
                    <PreviewPanel/>
                }
                <Footer/>
            </Suspense>
        </MasterLayoutContext.Provider>
    );
}

export default App;
