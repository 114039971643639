import React, {FC} from 'react';


const Video: FC = () => {

    return (
        <div className={'container'}>
            <div className={'row  pb-5'}>
                <div className={'col-md-12'}>
                    <div className={'position-relative'}>
                        <div className="blue-overlay"></div>
                        <img src={'/assets/images/home/video-poster-2.jpg'} className={'w-100'}
                             alt={'Video Poster'}/>
                        <div className={'play-btn centered-content'}>
                            <img className={''} src={'/assets/images/icons/play-icon.png'} alt={'Play Button'}/>
                        </div>
                    </div>
                </div>
                <div className={'col-lg-4 col-md-12 pe-lg-0'}>
                    <div className={'text-center text-white  bg-blue'}>
                        <div className={'pt-lg-5 pb-lg-5 pt-3 pb-3 ps-3 pe-3'}>
                            <img src={'/assets/images/icons/x-icon.png'} width={'30'} alt={'Icon'}/>
                            <h5 className={'block-title f-bold mt-3'}>No more worrying</h5>
                            <p className={'subheading f-semi-bold'}>about uncovering the secrets of doing business in Dubai</p>
                        </div>
                    </div>
                </div>

                <div className={'col-lg-4 col-md-12 p-lg-0'}>
                    <div className={'text-center text-white  bg-blue-light'}>
                        <div className={'pt-lg-5 pb-lg-5 pt-3 pb-3 ps-3 pe-3'}>
                            <img src={'/assets/images/icons/x-icon.png'} width={'30'} alt={'Icon'}/>
                            <h5 className={'block-title f-bold mt-3'}>No more stressing</h5>
                            <p className={'subheading f-semi-bold'}>about gaining an edge over established competitors</p>
                        </div>
                    </div>
                </div>

                <div className={'col-lg-4 col-md-12 ps-lg-0'}>
                    <div className={'text-center text-blue  bg-yellow'}>
                        <div className={'pt-lg-5 pb-lg-5 pt-3 pb-3 ps-3 pe-3'}>
                            <img src={'/assets/images/icons/x-icon-dark.png'} width={'30'} alt={'Icon'}/>
                            <h5 className={'block-title f-bold mt-3'}>No more panicking</h5>
                            <p className={'subheading f-semi-bold'}>about potentially wasting your investment and closing within a year</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Video;