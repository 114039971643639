import React, {FC} from "react";
import Hero from "./components/Hero";
import WhyToBecomePartner from "./components/WhyToBecomePartner";
import Benefits from "./components/Benefits";
import BecomePartner from "./components/BecomePartner";
import AccordionBecomeAPartner from "./components/AccordionBecomeAPartner";
import Book from "./components/Book";


const Partner: FC = () => {
    return (
        <section className={'partner'}>
            <Hero/>
            <WhyToBecomePartner/>
            <Benefits/>
            <BecomePartner/>
            <AccordionBecomeAPartner/>
            <Book/>
        </section>
    );
};

export default Partner;
