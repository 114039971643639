import React, {FC} from "react";
import {Accordion} from "react-bootstrap";

type Props = {
    items: any
}
const AccordionItems: FC<Props> = ({items}) => {
    return (
        <Accordion>
            {items.map((item: any, index: number) => (
                <Accordion.Item eventKey={index.toString()} key={index}>
                    <Accordion.Header>{item.header}</Accordion.Header>
                    <Accordion.Body className={'pb-4'}>
                        {item.body}
                    </Accordion.Body>
                </Accordion.Item>
            ))}
        </Accordion>
    );
};

export default AccordionItems;