import React, {FC} from "react";
import CarouselReviews from "../../../components/carousel/CarouselReviews";
import {Link} from "react-router-dom";


const Reviews: FC = () => {
    const reviews = [
        {
            name: 'Sam Drake',
            position: 'Founder of Qwerty Digital',
            description: 'The SBC has been an invaluable resource for me. I cannot overstate the valuable connections I\'ve made through the platform by being a part of the community and networking with like-minded individuals. I\'ve met numerous clients and referral partners who have significantly contributed to my business growth over the past few months. I wholeheartedly recommend the SBC platform to anyone looking to build their network with valuable connections.',
            imageUrl: '/assets/images/home/review-1.webp',
        },
        {
            name: 'Justin Clapp',
            position: 'Reinvention Coach',
            description: 'I wanted to drop you a note to express how much I enjoyed this morning’s event! I was genuinely impressed. It was hands down the best networking event I\'ve ever attended! I adore the environment you\'ve cultivated, the structure, and the thought-provoking questions you posed. The caliber of people present was outstanding, and the collective energy was palpable! I truly feel like I\'ve found the community I\'ve been searching for. I just signed up for SBC through the link provided, as I can\'t envision missing out on future events.',
            imageUrl: '/assets/images/home/review-2.webp',
        },
        {
            name: 'Mahmoud Tatari',
            position: 'Business Growth Coach',
            description: 'I traveled to Dubai with hopes of expanding my business, but I found myself without a network, opportunities, or any clue where to begin. That is, until I was introduced to Phil Bedford and the Strategic Business Connect community, dedicated to networking, referral opportunities, and partnership-building. Being a part of SBC has enabled me to meet new people, broaden my network, and establish valuable referral partnerships. If you\'re seeking to expand your network in Dubai while residing abroad, I highly recommend reaching out to SBC.',
            imageUrl: '/assets/images/home/review-3.webp',

        },

    ];
    return (
        <section className={'bg-secondary-blue position-relative mt-5 pb-5'}>
            <div className={'container'}>
                <div className={'row justify-content-center'}>
                    <div className={'col-md-10 text-center mt-5 mb-5'}>
                        <h3 className={'text-white section-title f-bold'}>Fast-track your business network because nobody has 20 years to build it. Get plugged into Dubai immediately.</h3>
                    </div>
                </div>
                <div className={'row justify-content-center'}>
                    <CarouselReviews colClass={'col-lg-10'} containerClass={'container'} items={reviews} itemsToShow={1} centerMode={false} theme={'reviews-dark'}/>
                </div>
                <div className={'row justify-content-center'}>
                    <div className={'col-lg-3 col-md-12'}>
                        <Link to={''} className={'btn btn-primary bordered w-100'}>Sign Up Now</Link>
                    </div>
                </div>
            </div>
            <div className={'center-left'}>
                <img src={'/assets/images/mask/ellipse-pink.png'} alt={'Mask'}/>
            </div>
        </section>
    );
};

export default Reviews;
