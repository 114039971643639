import React, {FC} from "react";

const Billing: FC = () => {
    return (
        <div className={'container'}>
            <div className={'row pt-5'}>
                <div className={'col-lg-11 pb-4'}>
                    <h3 className={'f-bold text-blue-color section-title mb-3'}>
                        <span className={'colored-nb me-3'}> 05 </span>
                        BILLING</h3>

                    <div className={'f-regular subheading text-blue-color'}>
                        <p className={'mt-4'}><span className={'f-bold'}>5.1 </span> We will automatically charge the
                            Access Fee to the credit card you provide while placing your order or through any other
                            means.</p>
                        <p><span className={'f-bold'}>5.2 </span> All amounts due for access must be paid in full in
                            advance.</p>
                        <p><span className={'f-bold'}>5.3 </span> You agree to provide current, complete, and
                            accurate purchase and account information for all purchases made on our website. You agree
                            to promptly update your account and other information, like your email address and credit
                            card details so that we can complete your transactions and contact you as needed.</p>
                        <p><span className={'f-bold'}>5.4 </span> All payments must be made using the payment methods
                            made available on the Platform. Our Platform cannot directly interfere with the payment
                            gateway mechanism. We have no access to the information that you may enter for making the
                            payment through the payment gateway. We do not endorse the working of the payment gateway,
                            nor are we liable for any failure of services offered by such payment gateway. Such payment
                            gateway may have a privacy policy and terms of service different from those of ours. All
                            failures, errors, and omissions of the payment gateway shall be solely on the payment
                            gateway.
                        </p>
                        <p><span className={'f-bold'}>5.5 </span> We reserve the right at any time to modify or
                            discontinue, temporarily or permanently, any payment method without notice to the buyer or
                            giving any reason.</p>
                        <p><span className={'f-bold'}>5.6 </span> All payments by you, under the Terms shall be
                            inclusive of any Value Added Tax (VAT) chargeable in respect of the supply of Services. </p>
                        <p><span className={'f-bold'}>5.7 </span> You agree to be solely responsible and liable for
                            the proper administration, imposition, collection, reporting, and remitting of all
                            applicable taxes. We emphasise that this information is not intended and should not be used
                            as legal advice. If you are unsure as to your tax responsibilities, then you should seek
                            advice from experts on this subject.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Billing;
