import React, {FC, useRef, useState} from "react";
import clsx from "clsx";
import {useFormik} from "formik";
import * as Yup from "yup";
import Select from 'react-select';
import {OptionType} from "../../models/OptionType";
import {useMasterLayout} from "../../layout/MasterLayoutContext.loader.ts";

const contactSchema = Yup.object().shape({
    email: Yup.string()
        .email("Please enter a valid email")
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Email is required"),
    name: Yup.string()
        .required("Name is required"),
    address: Yup.string()
        .required("Address is required"),
    phone_number: Yup.string()
        .required("Phone number is required"),
    description: Yup.string()
        .required("Description is required"),
    country: Yup.string()
        .required("Country where to bring SBC is required"),
    city: Yup.string()
        .required("City where to bring SBC is required"),
});

const PartnerForm: FC = () => {
    const {countries, getCitiesOfCountry} = useMasterLayout();

    const [cities, setCities] = useState<OptionType[]>([]);
    const [loading, setLoading] = useState(false);
    const countriesSelectRef = useRef<any>(null)
    const citiesSelectRef = useRef<any>(null)

    const initialValues = {
        email: "",
        name: "",
        address: "",
        phone_number: "",
        country: "",
        city: "",
        description: ""
    };

    const formik = useFormik({
        initialValues,
        validationSchema: contactSchema,
        onSubmit: async (values, {setSubmitting}) => {

            setSubmitting(true);
            try {
                // setLoading(true);

                formik.resetForm();
                countriesSelectRef.current?.clearValue();
                citiesSelectRef.current?.clearValue();
            } catch (ex) {
                console.error(ex);
            } finally {
                setSubmitting(true);
                setLoading(false);
            }
        },
    });

    return (
        <>
            <div className={'card card-bordered-blue'}>
                <div className={'p-4'}>
                    <h3 className={'f-bold text-blue-color text-center section-title'}>Contact Form</h3>
                    <form className="form justify-content-center w-100" onSubmit={formik.handleSubmit} noValidate>
                        <div className={'form-group row'}>
                            <div className={'col-lg-6 col-md-12 pe-2 mt-3'}>
                                <input type={'text'} {...formik.getFieldProps("name")} className={clsx(
                                    "form-control input-main",
                                    {"is-invalid": formik.touched.name && formik.errors.name,},
                                    {"is-valid": formik.touched.name && !formik.errors.name,}
                                )} placeholder='Full Name'/>
                                {formik.touched.name && formik.errors.name && (
                                    <div className="text-message-container-danger">
                                        <span role="alert">{formik.errors.name}</span>
                                    </div>
                                )}
                            </div>
                            <div className={'col-lg-6 col-md-12 ps-2 mt-3'}>
                                <input type={'email'}  {...formik.getFieldProps("email")}
                                       placeholder={"Email"}
                                       className={clsx("form-control input-main", {"is-invalid": formik.touched.email && formik.errors.email,}, {"is-valid": formik.touched.email && !formik.errors.email,})}/>
                                {formik.touched.email && formik.errors.email && (
                                    <div className="text-message-container-danger">
                                        <span role="alert">{formik.errors.email}</span>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={'form-group row'}>
                            <div className={'col-md-12 mt-3'}>
                                <input type={'text'} {...formik.getFieldProps("address")} className={clsx(
                                    "form-control input-main",
                                    {"is-invalid": formik.touched.address && formik.errors.address,},
                                    {"is-valid": formik.touched.address && !formik.errors.address,}
                                )} placeholder='Address'/>
                                {formik.touched.address && formik.errors.address && (
                                    <div className="text-message-container-danger">
                                        <span role="alert">{formik.errors.address}</span>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={'form-group row'}>
                            <div className={'col-md-12 mt-3'}>
                                <input type={'text'} {...formik.getFieldProps("phone_number")} className={clsx(
                                    "form-control input-main",
                                    {"is-invalid": formik.touched.phone_number && formik.errors.phone_number,},
                                    {"is-valid": formik.touched.phone_number && !formik.errors.phone_number,}
                                )} placeholder='Phone Number'/>
                                {formik.touched.phone_number && formik.errors.phone_number && (
                                    <div className="text-message-container-danger">
                                        <span role="alert">{formik.errors.phone_number}</span>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={'form-group row'}>
                            <div className={'col-md-12 mt-3'}>
                                <Select
                                    className={clsx(
                                        "w-100 main-input",
                                        {"invalid-select": formik.touched.country && formik.errors.country,},
                                        {"valid-select": formik.touched.country && !formik.errors.country,}
                                    )}
                                    isLoading={loading}
                                    closeMenuOnSelect={false}
                                    placeholder={'Prospective country of interest'}
                                    options={countries.map((e: {
                                        label: any;
                                        value: any
                                    }) => ({
                                        label: e.label,
                                        value: e.value
                                    }))}
                                    isSearchable={true}
                                    onChange={(newValue: any) => {
                                        if (newValue) {
                                            formik.setFieldValue('country', newValue.value)

                                            setCities(getCitiesOfCountry(newValue.value))
                                        }
                                    }
                                    }
                                    ref={countriesSelectRef}
                                />

                                {formik.touched.country && formik.errors.country && (
                                    <div className="text-message-container-danger">
                                        <span role="alert">{formik.errors.country}</span>
                                    </div>
                                )}

                            </div>
                        </div>
                        <div className={'form-group row'}>
                            <div className={'col-md-12 mt-3'}>
                                <Select
                                    className={clsx(
                                        "w-100 main-input",
                                        {"invalid-select": formik.touched.city && formik.errors.city,},
                                        {"valid-select": formik.touched.city && !formik.errors.city,}
                                    )}
                                    isLoading={loading}
                                    closeMenuOnSelect={false}
                                    placeholder={'Prospective city of interest'}
                                    options={cities.map((e: {
                                        label: any;
                                        value: any
                                    }) => ({
                                        label: e.label,
                                        value: e.value
                                    }))}
                                    isSearchable={true}
                                    onChange={(newValue: any) =>
                                        formik.setFieldValue('city', newValue.value)
                                    }
                                    ref={citiesSelectRef}
                                />

                                {formik.touched.city && formik.errors.city && (
                                    <div className="text-message-container-danger">
                                        <span role="alert">{formik.errors.city}</span>
                                    </div>
                                )}

                            </div>
                        </div>
                        <div className={'form-group row'}>
                            <div className={'col-md-12 mt-3'}>
                               <textarea  {...formik.getFieldProps("description")} className={clsx(
                                   "form-control input-main",
                                   {"is-invalid": formik.touched.description && formik.errors.description,},
                                   {"is-valid": formik.touched.description && !formik.errors.description,}
                               )} placeholder='Why are you interested in becoming a partner?'/>
                                {formik.touched.description && formik.errors.description && (
                                    <div className="text-message-container-danger">
                                        <span role="alert">{formik.errors.description}</span>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={'row mt-lg-4 mt-3'}>
                            <div className={'col-lg-5'}>
                                <button className="btn btn-primary bordered w-100 "
                                        disabled={formik.isSubmitting || !formik.isValid} type="submit">
                                    {!loading && (
                                        <span className="indicator-label">
                                 GET IN TOUCH
                                </span>
                                    )}
                                    {loading && (
                                        <span className="indicator-progress"
                                              style={{display: "block"}}><span
                                            className="spinner-border spinner-border-sm align-middle ms-2"></span></span>)}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};
export default PartnerForm;