import React, {FC} from "react";

const Point11: FC = () => {
    return (
        <div className={'position-relative'}>
            <div className={'container'}>
                <div className={'row '}>
                    <div className={'col-lg-11 pb-4'}>
                        <h3 className={'f-bold text-blue-color section-title mb-3'}>
                            <span className={'colored-nb me-3'}> 11 </span>
                            AUTOMATIC DECISION-MAKING</h3>
                        <div className={'f-regular subheading text-blue-color'}>
                            <p className={'mt-4'}><span className={'f-bold'}>11.1 </span>
                                If you are a resident of the EEA, you have the right to object to processing based
                                solely on automated decision-making (which includes profiling), when that
                                decision-making has a legal effect on you or otherwise significantly affects you.
                            </p>
                            <p className={'mt-4'}><span className={'f-bold'}>11.2 </span>
                                We do not engage in fully automated decision-making that has a legal or otherwise
                                significant effect using customer data.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'position-absolute ellipse-right-bottom ellipse'}>
                <img src={'/assets/images/mask/mask-right-b.png'} className={''} alt={'Mask'}/>
            </div>
        </div>
    );
};

export default Point11;
