import React, {FC} from "react";

const UserAccessPeriod: FC = () => {
    return (
        <div className={'position-relative'}>
            <div className={'container'}>
                <div className={'row pt-5'}>
                    <div className={'col-lg-11 pb-4'}>
                        <h3 className={'f-bold text-blue-color section-title mb-3'}>
                            <span className={'colored-nb me-3'}> 04 </span>
                            USER ACCESS PERIOD</h3>

                        <div className={'f-regular subheading text-blue-color'}>
                            <p className={'mt-4'}><span className={'f-bold'}>4.1 </span> If you avail our annual
                                access with one-time fee, your access period is for an initial term of 12 months and
                                will automatically renew every 12 months, provided the relevant Access Fee is paid.
                            </p>
                            <p><span className={'f-bold'}>4.2 </span> In an event you choose the monthly access
                                option, you access period is for a period of 1 (one) month, and will automatically renew
                                each month, provided the relevant monthly Access Fee is paid. You need to ensure that
                                you activate monthly payment mandates on your card.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'position-absolute ellipse-right'}>
                <img src={'/assets/images/mask/mask-right.png'} className={''} alt={'Mask'}/>
            </div>
        </div>
    );
};

export default UserAccessPeriod;
