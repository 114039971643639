import React, {FC} from "react";
import {Link} from "react-router-dom";

const Introduction: FC = () => {
    return (
        <div className={'container'}>
            <div className={'row pt-5 mb-5'}>
                <div className={'col-lg-11 pt-4'}>
                    <h3 className={'f-bold text-blue-color section-title mb-3'}>Strategic Business Connect (SBC)
                        Platform <br/>Terms of Service</h3>

                    <div className={'f-regular subheading text-blue-color'}>
                        <p className={'f-bold'}>Effective date: March 09, 2023</p>
                        <p>
                            Welcome to the Strategic Business Connect (SBC) platform, accessible from <Link
                            to={'www.strategicbusinessconnect.com'} target={'_blank'}
                            className={'link-blue-light'}>www.strategicbusinessconnect.com </Link>(the “Platform”).</p>
                        <p>This Platform is operated by Asentiv Marketing and Management Consultancy LLC (“Company”) and
                            its affiliates, subsidiaries, and related entities. Throughout the Platform, the terms “we”,
                            “us” and “our” refer to the Company, and “you”, “your” “user”, “client” refers to you, the
                            user of the Platform.</p>
                        <p>By visiting, accessing, and making purchases on our Platform, you engage in our services
                            (“Services”) and agree to be bound by the following terms and conditions (“Terms of Service”
                            or “Terms”), and all other policies and notices stated here, including the Platform’s
                            privacy policy. These Terms of Service apply to all users of the Platform, including without
                            limitation users who are browsers, clients and everyone that accesses the Platform.</p>
                        <p>Please read these Terms of Service carefully before accessing or using our Platform. If you
                            do not agree to all the Terms, then you may not access the Platform or use any Services.</p>
                        <p>Any new features that are added to the Platform shall also be subject to the Terms of
                            Service. You can review the most current version of the Terms of Service at any time on this
                            page. We reserve the right to update, change or replace any part of these Terms of Service
                            by posting updates and/or changes to our website. It is your responsibility to check this
                            page periodically for changes. Your continued use of or access to the Platform following the
                            posting of any changes constitutes acceptance of those changes.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Introduction;
