import React from 'react';

const LoadingIndicator = () => {
    return (
        <div className="progress-container">
            {/*<div className="progress-bar"/>*/}
            <div className="progress-content">
                <div className="logo">
                    <img src="/assets/images/logos/logo-4.webp" alt="Logo"/>
                </div>
                <div className="fa fa-spinner spinner-grow"></div>
            </div>
        </div>
    );
};

export default LoadingIndicator;
