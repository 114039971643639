import React, {FC} from "react";
import Slider from "react-slick";

type Props = {
    items: any
    itemsToShow: number
    containerClass: string
    centerMode: boolean
    theme: string
    colClass : string

}
const CarouselReviews: FC<Props> = ({items, itemsToShow, containerClass, centerMode, theme, colClass}) => {
    const settings = {
        className: 'center',
        centerMode: centerMode,
        centerPadding: '20%',
        dots: false,
        infinite: true,
        speed: 500,
        arrows: true,
        slidesToShow: itemsToShow,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    centerMode: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    center: true

                },
            },
            {
                breakpoint: 768,
                settings: {
                    centerMode: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],


    };


    return (
        <div className={containerClass}>
            <div className="row justify-content-center">
                <div className={`${colClass} ${theme} col-10`}>
                    <div className="review-slider pb-5">
                        <Slider {...settings} className="dark-carousel arrows-bottom-center text-center">
                            {items.map((item: any, index: number) => {
                                    return (
                                        <div className="p-lg-2 p-2" key={index}>
                                            <div className="border-grey mt-100px position-relative">
                                                <div className="review-wrapper-image">
                                                    <img src={item.imageUrl} alt={item.name} className="review-image"/>
                                                </div>
                                                <div className="p-lg-5 p-3">
                                                    <div className="row mt-lg-5 mt-lg-2 mt-4 pt-4">
                                                        <div className="col-md-2 col-2">
                                                            <img src="/assets/images/icons/quote.png" alt="Review"
                                                                 className="w-75"/>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-3">
                                                        <div className="col-md-12 text-start">
                                                            <p className="f-light subheading description text-start">{item.description}</p>
                                                            <p className="name f-bold main-description mb-0">{item.name}</p>
                                                            <p className="sm-text position">{item.position}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            )}
                        </Slider>
                    </div>
                </div>
            </div>

        </div>

    )
}
export default CarouselReviews;