import React, {FC} from 'react';

type Props = {
    title: string
    description?: string
    image: string
    borderClass: string
    subtitle: string
}

const CardLayoutSix: FC<Props> = ({title, description, image, borderClass, subtitle}) => {
    return (
        <div className="card card-custom card-stretch gutter-b border-0">
            <div className="card-body d-flex flex-column ">
                <div className="text-center">
                    <div className="mt-1">
                        <img src={image} alt={title} className="step-image"/>
                    </div>
                    <div className="mt-5 title-container d-block">
                        <div className="f-semi-bold md-text text-blue-color">
                            {subtitle}
                        </div>
                        <div className={`f-bold lg-text text-blue-color title ${borderClass}`}>
                            {title}
                        </div>
                    </div>
                </div>
                <div className="align-items-center text-center mt-5">
                    {description &&
                        <p className="sm-text f-regular text-blue-color"
                           dangerouslySetInnerHTML={{__html: description}}/>
                    }
                </div>
            </div>
        </div>
    );
};

export default CardLayoutSix;