import React, {FC} from "react";

const Point6: FC = () => {
    return (
        <div className={'container'}>
            <div className={'row pt-5'}>
                <div className={'col-lg-11 pb-4'}>
                    <h3 className={'f-bold text-blue-color section-title mb-3'}>
                        <span className={'colored-nb me-3'}> 06 </span>
                        INTERNATIONAL DATA TRANSFERS</h3>

                    <div className={'f-regular subheading text-blue-color'}>
                        <p className={'mt-4'}><span className={'f-bold'}>6.1 </span>
                            6.1 We are located and operate in the United Arab Emirates. However, our servers and service
                            providers may be located in the United States, United Kingdom and/ or Europe. By submitting
                            personal information to us, you understand that we may transfer your personal information to
                            a country foreign to you. The laws that apply to the use and protection of personal
                            information in other countries or jurisdictions in which we transfer or process personal
                            information, may be different from the laws and protections in your country. These transfers
                            may include transfers to our service providers located in foreign jurisdictions. Whenever we
                            engage a service provider, we require that its privacy and security standards adhere to our
                            requirements and applicable privacy laws.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Point6;
