import React, {FC} from 'react';
import {Link} from "react-router-dom";

type Props = {
    image: string
    date?: string
    title: string
    time?: string
    location?: string
    btn: boolean
    description?: string
    btnText?: string
    colClass: string
}

const CardBook: FC<Props> = ({colClass, image, date, title, time, location, btn, description, btnText}) => {
    return (
        <div className={`${colClass} col-md-12 z-index-999`}>
            <div className="card card-custom card-stretch gutter-b">
                <div className="card-body d-flex flex-column">
                    <div className="flex-grow-1">
                        <div className="card-image-sbc">
                            <img src={image} alt="Card" className="w-100"/>
                        </div>
                        <div className="text-center">
                            {title &&
                                <p className="lg-text f-bold text-blue-color mt-3"
                                   dangerouslySetInnerHTML={{__html: title}}/>
                            }
                        </div>
                    </div>
                    {btn &&
                        <div className="align-items-start text-center mt-2 mb-2 p-3">
                            <Link to={''}
                                  className="btn btn-primary bordered ps-5 pe-5">{btnText ? btnText : 'Book Now'}</Link>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default CardBook;