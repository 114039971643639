import React, {FC} from "react";
import TopBanner from "../../layout/base/TopBanner";
import ContactForm from "./components/ContactForm";


const Contact: FC = () => {
    return (
        <section className={'contact'}>
            <TopBanner title={'Contact Us'}/>
            <div className={'position-relative bg-white'}>
                <div className={'container'}>
                    <div className={'row pt-5'}>
                        <div className={'col-md-12 pt-5 z-index-999'}>
                            <div className={'text-blue-color '}>
                                <h3 className={'f-bold section-title'}>What's on your mind?</h3>
                                <p className={'f-regular subheading'}>Please fill out the form below or get in touch via
                                                                      email or phone.</p>
                            </div>
                        </div>
                    </div>
                    <div className={'row pb-lg-5'}>
                        <div className={'col-md-12 pb-lg-5 pb-5 flex-wrap'}>
                            <ContactForm/>
                        </div>
                    </div>
                    <div className={'row justify-content-between pb-5'}>
                        <div className={'col-lg-4 col-md-12 pb-5'}>
                            <div className={'d-flex align-items-center mb-lg-5 '}>
                                <div>
                                    <img src={'/assets/images/icons/email-icon.png'} className={'w-50px'}
                                         alt={'Email'}/>
                                </div>
                                <div className={'text-blue-color ms-lg-3 ms-1'}>
                                    <div className={'f-bold subheading'}>
                                        Email
                                    </div>
                                    <div className={'f-regular sm-text '}>
                                        hello@strategicbusinessconnect.com
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'col-lg-3 col-md-12 pb-5'}>
                            <div className={'d-flex'}>
                                <div>
                                    <img src={'/assets/images/icons/phone-icon.png'} className={'w-50px'}
                                         alt={'Phone Number'}/>
                                </div>
                                <div className={'text-blue-color ms-lg-3 ms-1 '}>
                                    <div className={'f-bold subheading'}>
                                        Michelle
                                    </div>
                                    <div className={'f-regular sm-text'}>
                                        +(971) 50 565 6310
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'col-lg-3 col-md-12 pb-5'}>
                            <div className={'d-flex'}>
                                <div>
                                    <img src={'/assets/images/icons/phone-icon.png'} className={'w-50px'}
                                         alt={'Phone Number'}/>
                                </div>
                                <div className={'text-blue-color ms-lg-3 ms-1'}>
                                    <div className={'f-bold subheading'}>
                                        Phil
                                    </div>
                                    <div className={'f-regular sm-text'}>
                                        +(971) 50 848 7188
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'top-right-mask'}>
                    <img src={'/assets/images/mask/mask-top-right-to-bottom.png'} alt={'Mask'}/>
                </div>
            </div>
        </section>
    );
};

export default Contact;
