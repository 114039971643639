import {createContext, Dispatch, SetStateAction} from "react";
import {OptionType} from "../models/OptionType";

interface MasterLayoutContextProps {
    countries: OptionType[];
    setCountries: Dispatch<SetStateAction<OptionType[]>>;
    getCitiesOfCountry: (countryCode: string) => OptionType[]
}

const defaultMasterLayoutContextValue: MasterLayoutContextProps = {
    countries: [],
    setCountries: () => {
    },
    getCitiesOfCountry(countryCode): OptionType[] {
        return [];
    }
}

export const MasterLayoutContext = createContext<MasterLayoutContextProps>(defaultMasterLayoutContextValue)