import React, {FC} from "react";
import AccordionItems from "../../../components/accordion/AccordionItems";


const AccordionBecomeAPartner: FC = () => {

    const items = [
        {
            header: 'Q. What Are the Investment Requirements?',
            body : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do  eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in  culpa qui officia deserunt mollit anim id est laborum.'
        },
        {
            header: 'Q. How Do I Get Started?',
            body : 'Ans: Getting started is simple. Fill out our partnership inquiry form, and we\'ll schedule a discovery call. This call is an opportunity for us to learn more about each other and assess how we can collaborate effectively.'
        },
        {
            header: 'Q. How Is the Revenue Shared?',
            body : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do  eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in  culpa qui officia deserunt mollit anim id est laborum.'
        },
        {
            header: 'Q. What Are the Expected Outcomes of the Partnership?',
            body : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do  eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in  culpa qui officia deserunt mollit anim id est laborum.'
        },
    ]

    return (
        <section className={' bg-secondary-gradient position-relative'}>
            <div className={'container pt-5 pb-5'}>
                <div className={'row justify-content-center align-items-center pt-5 pb-5'}>
                    <div className={'col-lg-9 col-md-12 text-center'}>
                        <h3 className={'section-title f-bold text-white'}>
                            Partnership FAQs: <br/>Your Questions, Answered.
                        </h3>
                    </div>
                </div>

                <div className={'row justify-content-center align-items-center mb-5'}>

                    <div className={'col-md-12'}>
                        <AccordionItems items={items} />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AccordionBecomeAPartner;
