import React, {FC} from 'react';
import MainButton from "../../../components/buttons/MainButton";


const JoinNow: FC = () => {

    return (
        <section className={'position-relative'}>
            <div className={'contact overlay-bg'}>
                <div className={'container pt-lg-5 pb-lg-5'}>
                    <div className={'row justify-content-center  pt-5 pb-5'}>
                        <div className={'col-lg-9 col-md-12 text-center z-index-999 mt-lg-5 mb-lg-5'}>
                            <div className={'mt-lg-5 mb-lg-5'}>
                                <h3 className={'section-title f-bold text-white'}>A LIFETIME OF VALUE, IN MINUTES</h3>
                                <p className={'text-white f-regular subheading ms-lg-3 me-lg-3'}>
                                    This is your final opportunity to join a community of like-minded professionals, equipping you for success and enabling your business to thrive tenfold in Dubai.
                                </p>
                                <MainButton text={'join now'} className={'btn-primary bordered ps-5 pe-5 mt-2'} link={''}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={''}>
                <div className={'top-left-mask white d-lg-block d-none'}>
                    <img src={'/assets/images/mask/ellipse-white-cropped.png'} className={''} alt={'Mask'}/>
                </div>
                <div className={'center-left to-bottom'}>
                    <img src={'/assets/images/mask/mask-top-left-to-bottom.png'} className={''} alt={'Mask'}/>
                </div>
            </div>
        </section>
    );
};

export default JoinNow;