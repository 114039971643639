import React, {FC} from "react";

const Info: FC = () => {
    return (
        <div className={'bg-blue-c'}>
            <div className={'container'}>
                <div className={'row pt-5 mb-2'}>
                    <div className={'col-lg-11 pt-4 pb-4'}>
                        <h3 className={'f-bold text-blue-color section-title mb-3'}>
                            <span className={'colored-nb me-3'}> 01 </span>
                            INFORMATION WE COLLECT ABOUT YOU</h3>

                        <div className={'f-regular subheading text-blue-color mt-3'}>
                            <p>
                                Personal data means any information that does (or could be used to) identify a living
                                person. In particular, the Platform may collect the following categories of
                                information:</p>
                            <p>
                                <span className={'f-bold'}>Personal Data</span> <br/>
                                A name, country code, telephone or mobile number, email address, date of birth, and
                                demographic information.
                                <br/>
                                The Platform will also collect information relating to the business owned by you
                                including business name, ownership, and logo, if you choose to be listed in the client
                                directory.
                            </p>
                            <p>
                                <span className={'f-bold'}>Financial Data</span> <br/>
                                We may collect financial data like bank account and payment card details. You will be
                                required to submit your credit card number and CVV to process payments through our
                                payment gateway.
                            </p>
                            <p><span className={'f-bold'}>Internet or Similar Network Activity</span> <br/>
                                Browsing history, search history, information on your interaction with the platform or
                                advertisement, address, your login data, browser type, and version, time zone setting
                                and location, browser plug-in types and versions, operating system and platform, and
                                other technology on the devices you use to access the Platform.
                            </p>
                            <p><span className={'f-bold'}>Marketing and communications data</span> <br/>
                                Preferences in receiving marketing from us and our third parties and your communication
                                preferences.
                            </p>
                            <p><span className={'f-bold'}>Other Information</span> <br/>
                                General, aggregated, demographic, and non-personal information to improve our Platform
                                <br/>
                                Any other information we consider necessary to enhance your experience on the Platform.
                                <br/>
                                The information you provide when you enter a promotion via our Platform or our social
                                media channels or the comments or feedback you provide on the Platform.
                                <br/>
                                Information you provide us, or that we may collect from you when you report a problem
                                with the Platform.
                                <br/>
                                A record of correspondence in an event that you contact us.
                                <br/>
                                General, aggregated, demographic, and non-personal information to improve the Platform.
                                <br/>
                                Any other information we consider necessary to enhance your experience on the Platform
                            </p>
                            <p><span className={'f-bold'}>Technical Data</span> <br/>
                                IP address, browser type and version, time zone setting and generic location, browser
                                plug-in types and versions, operating system and platform, devices you use to access our
                                Platform.
                            </p>
                            <p><span className={'f-bold'}>Usage Data</span> <br/>
                                Information about how a user interacts with the Platform. For example, the number of
                                times they visit a page or sections they tap on.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Info;
