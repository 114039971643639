import React, {FC} from "react";

const General: FC = () => {
    return (
        <div className={'container'}>
            <div className={'row '}>
                <div className={'col-lg-11 pb-4'}>
                    <h3 className={'f-bold text-blue-color section-title mb-3'}>
                        <span className={'colored-nb me-3'}> 15 </span>
                        GENERAL</h3>
                    <div className={'f-regular subheading text-blue-color'}>
                        <p className={'mt-4'}><span className={'f-bold'}>15.1 </span>If any provision of these Terms of
                            Service is determined to be unlawful, void, or unenforceable, such provision shall
                            nonetheless be enforceable to the fullest extent permitted by applicable law, and the
                            unenforceable portion shall be deemed to be severed from these Terms of Service. Such
                            determination shall not affect the validity and enforceability of any other remaining
                            provisions.</p>
                        <p className={''}><span className={'f-bold'}>15.2 </span>The failure of us to exercise or
                            enforce any right or provision of these Terms of Service shall not constitute a waiver of
                            such right or provision.</p>
                        <p className={''}><span className={'f-bold'}>15.3 </span>These Terms of Service and any policies
                            or operating rules posted by us on this Platform or in respect to the Service constitute the
                            entire agreement and understanding between you and us and govern your use of the Service,
                            superseding any prior or contemporaneous agreements, communications, and proposals, whether
                            oral or written, between you and us (including, but not limited to, any prior versions of
                            the Terms of Service).</p>
                        <p className={''}><span className={'f-bold'}>15.4 </span>You are not allowed to assign,
                            subcontract, or transfer your right or obligation of your access to anyone without prior
                            consent. We may transfer our rights and obligations under these to another business without
                            your consent, but we will notify you of the transfer and ensure that your rights are not
                            adversely affected as a result.</p>
                        <p className={''}><span className={'f-bold'}>15.5 </span> If you breach these terms and we take
                            no action, we will still be entitled to use our rights and remedies in any other situation
                            where you breach the terms.</p>
                        <p className={''}><span className={'f-bold'}>15.6 </span>Any ambiguities in the interpretation
                            of these Terms of Service shall not be construed against the drafting party.</p>
                        <p className={''}><span className={'f-bold'}>15.7 </span> All provisions that either expressly
                            or by their nature survive will survive suspension or termination of your membership of the
                            Platform.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default General;
