import React, {FC} from 'react';
import {Link} from "react-router-dom";

type Props = {
    title: string
    description: string[]
    btnText: string
    preSubheading: string
    postSubheading: string
}

const CardLayoutThree: FC<Props> = ({title, description, btnText, preSubheading, postSubheading}) => {
    return (
        <div className="col-lg-6 col-md-12 z-index-999 mb-5">
            <div className="bg-pricing card card-custom card-stretch gutter-b border-0">
                <div className="card-body d-flex flex-column p-0 border-0">
                    <div className="flex-grow-1">
                        <div className="bg-blue text-center pt-3 pb-3">
                            <small
                                className="md-text text-white letter-space-2 text-uppercase">{preSubheading}</small>
                            <h4 className="main-title f-bold text-white pb-0 mb-0">
                                {title}
                            </h4>
                            <small className="main-description sm-text text-light-blue">{postSubheading}</small>
                        </div>
                        <ul className="subheading f-medium text-blue-color circle-checklist p-lg-5 p-3">
                            {description.map((item: string, index: number) => (
                                <li key={index}>{item}</li>
                            ))}
                        </ul>

                    </div>
                    <div className="align-items-center text-center mt-5 pb-5">
                        <Link to="/" className="btn btn-primary bordered ps-5 pe-5">{btnText}</Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CardLayoutThree;