import React, {FC} from "react";

const Point5: FC = () => {
    return (
        <div className={'container'}>
            <div className={'row pt-5'}>
                <div className={'col-lg-11 pb-4'}>
                    <h3 className={'f-bold text-blue-color section-title mb-3'}>
                        <span className={'colored-nb me-3'}> 05 </span>
                        RETENTION OF PERSONAL DATA</h3>

                    <div className={'f-regular subheading text-blue-color'}>
                        <p className={'mt-4'}><span className={'f-bold'}>5.1 </span>
                            We retain your personal information only for as long as necessary to fulfill the
                            purposes for which we collected it, including for the purposes of satisfying any legal,
                            accounting, or reporting requirements. To determine the appropriate retention period for
                            personal information, we consider the amount, nature, and sensitivity of the personal
                            information; the potential risk of harm from unauthorized use or disclosure of your personal
                            information; the purposes for which we process your personal information and whether we can
                            achieve those purposes through other means; and the applicable legal requirements. This
                            period of retention of data will be anywhere between 6 months and upwards depending on the
                            applicable law and the nature of the data and the factors listed above. In some
                            circumstances, you may ask us to delete your information.
                        </p>
                        <p><span className={'f-bold'}>5.2 </span>
                            Keep in mind that, while our systems are designed to carry out our deletion practices
                            upon deletion of accounts, we cannot promise that deletion will occur within a specific
                            timeframe. There may be legal requirements to store your data and we may need to suspend
                            those deletion practices if we receive a valid legal process asking us to preserve content
                            or if we receive reports of abuse or other Terms of Service violations. Finally, we may also
                            retain certain information in backup for a limited period of time or as required by law.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Point5;
