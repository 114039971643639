import React, {FC} from "react";
import {Link} from "react-router-dom";


const Call: FC = () => {

    return (
        <section className={'resources bg-secondary-gradient position-relative'}>
            <div className={'container pt-5 pb-5'}>
                <div className={'row justify-content-center align-items-center pt-5 pb-5'}>
                    <div className={'col-lg-4 col-md-12 me-lg-5'}>
                        <div className={'border-primary-lighten'}>
                            <img src={'/assets/images/home/phil.png'} className={'w-100'} alt={'Phil'}/>
                        </div>
                    </div>
                    <div className={'col-lg-4 col-md-12 mt-lg-0 mt-4'}>
                        <div>
                            <h3 className={'f-bold section-title text-white'}>HOP ON A CALL WITH PHIL ONCE A MONTH, FOR FREE</h3>
                            <p className={'f-light subheading text-white me-lg-4'}>Have questions about business, suppliers, client outreach, how schools work and more?</p>

                            <div className={'mt-4'}>
                                <Link to={''} className={'btn btn-secondary w-75'}>BOOK CALL</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Call;
