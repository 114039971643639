import React, {FC} from "react";


const BusinessResources: FC = () => {

    return (
        <section className={'bg-primary-color position-relative'}>
            <div className={'container'}>
                <div className={'row align-items-center justify-content-between pt-5 pb-5'}>
                    <div className={'col-lg-6 col-md-12 z-index-999  mt-5 mb-5'}>
                        <div>
                            <img src={'/assets/images/networking/resources.png'} alt={''} className={'w-100'} />
                        </div>
                    </div>
                    <div className={'col-lg-5 col-md-12  z-index-999  mt-5 mb-5'}>
                        <div className={''}>
                            <h3 className={'section-title f-bold text-white'}>PREMIUM BUSINESS RESOURCES</h3>
                            <ol className={'ordered-list f-medium subheading mt-3 d-inline-block text-white me-lg-3'}>
                                <li className={''}>Monthly FREE video calls with our Dubai Team</li>
                                <li className={''}>Exclusive WhatsApp group with overseas clients</li>
                                <li className={''}>Access to Dubai business experts to fast-track your success</li>
                                <li className={''}>Complimentary and discounted resources on business procedures and processes in Dubai</li>
                                <li className={''}>Multiple free opportunities each month to network in Dubai through Zoom from your home country. Build your business, find clients, suppliers, and collaborators & more</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div className={'center-left d-lg-block d-none'}>
                <img src={'/assets/images/mask/ellipse-white.png'} className={'mask-op'} alt={'Mask'}/>
            </div>

        </section>
    );
};

export default BusinessResources;
