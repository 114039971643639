import React, {FC} from "react";

const GoverningLaw: FC = () => {
    return (
        <div className={'container'}>
            <div className={'row '}>
                <div className={'col-lg-11 pb-4'}>
                    <h3 className={'f-bold text-blue-color section-title mb-3'}>
                        <span className={'colored-nb me-3'}> 14 </span>
                        GOVERNING LAW & DISPUTE RESOLUTION</h3>
                    <div className={'f-regular subheading text-blue-color'}>
                        <p className={'mt-4'}><span className={'f-bold'}>14.1 </span>These Terms of Service shall be
                            governed by and construed in accordance with the laws of the Dubai International Financial
                            Centre (DIFC).</p>
                        <p className={''}><span className={'f-bold'}>14.2</span> Any dispute, difference, controversy,
                            or claim arising out of or in connection with these Terms of Service, including (but not
                            limited to) any question regarding its existence, validity, interpretation, performance,
                            discharge, and applicable remedies, shall be subject to the exclusive jurisdiction of the
                            DIFC Courts.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GoverningLaw;
