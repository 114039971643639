import React, {FC} from "react";

const ThirdPartyLinks: FC = () => {
    return (
        <div className={'position-relative'}>
            <div className={'container'}>
                <div className={'row '}>
                    <div className={'col-lg-11 pb-4'}>
                        <h3 className={'f-bold text-blue-color section-title mb-3'}>
                            <span className={'colored-nb me-3'}> 11 </span>
                            THIRD-PARTY LINKS</h3>
                        <div className={'f-regular subheading text-blue-color'}>
                            <p className={'mt-4'}><span className={'f-bold'}>11.1 </span> Third-party links on this
                                Platform if any may direct you to third-party websites that are not affiliated with us.
                                We are not responsible for examining or evaluating the content or accuracy and we do not
                                warrant and will not have any liability or responsibility for any third-party materials
                                or websites, or any other materials, products, or services of third parties.
                            </p>
                            <p className={'mt-4'}><span className={'f-bold'}>11.2 </span> We are not liable for any
                                harm or damages related to the purchase or use of goods, services, resources, content,
                                or any other transactions made in connection with any third-party websites. Please
                                review carefully the third-party's policies and practices and make sure you understand
                                them before you engage in any transaction. Complaints, claims, concerns, or questions
                                regarding third-party products should be directed to the third-party.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'position-absolute ellipse-right-bottom ellipse'}>
                <img src={'/assets/images/mask/mask-right-b.png'} className={''} alt={'Mask'}/>
            </div>
        </div>
    );
};

export default ThirdPartyLinks;
