import React, {FC} from "react";
import CarouselReviews from "../../../components/carousel/CarouselReviews";



const Entrepreneurs: FC = () => {

    const entrepreneurs = [

        {
            name: 'Ebenezer David',
            position: '',
            description:'Over the past year, I\'ve been working on relocating my business from India to the UAE. Utilizing the SBC platform has been instrumental in forging connections within the business community, addressing challenges proactively, and accessing a plethora of resources ranging from business solutions to healthcare and accommodation options, as well as schooling opportunities.',
            imageUrl: '/assets/images/networking/rbenezer-david.png',
        },
        {
            name: 'Sam Drake',
            position: 'Founder of Qwerty Digital',
            description: 'The SBC has been an invaluable resource for me. I cannot overstate the valuable connections I\'ve made through the platform by being a part of the community and networking with like-minded individuals. I\'ve met numerous clients and referral partners who have significantly contributed to my business growth over the past few months. I wholeheartedly recommend the SBC platform to anyone looking to build their network with valuable connections.',
            imageUrl: '/assets/images/networking/rbenezer-david.png',

        },
        {
            name: 'Justin Clapp',
            position: 'Reinvention Coach',
            description: 'I wanted to drop you a note to express how much I enjoyed this morning’s event! I was genuinely impressed. It was hands down the best networking event I\'ve ever attended! I adore the environment you\'ve cultivated, the structure, and the thought-provoking questions you posed. The caliber of people present was outstanding, and the collective energy was palpable! I truly feel like I\'ve found the community I\'ve been searching for. I just signed up for SBC through the link provided, as I can\'t envision missing out on future events.',
            imageUrl: '/assets/images/networking/rbenezer-david.png'
        },
        {
            name: 'Mahmoud Tatari',
            position: 'Business Growth Coach',
            description: 'I traveled to Dubai with hopes of expanding my business, but I found myself without a network, opportunities, or any clue where to begin. That is, until I was introduced to Phil Bedford and the Strategic Business Connect community, dedicated to networking, referral opportunities, and partnership-building. Being a part of SBC has enabled me to meet new people, broaden my network, and establish valuable referral partnerships. If you\'re seeking to expand your network in Dubai while residing abroad, I highly recommend reaching out to SBC.',
            imageUrl: '/assets/images/networking/mahmoud-tatari.png'
        }
    ];
    return (
        <section className={'resources  position-relative'}>
            <div className={'container'}>
                <div className={'row justify-content-center pt-5'}>
                    <div className={'col-md-7 pt-3 pb-2 z-index-999 text-center'}>
                        <h3 className={'section-title text-blue-color f-bold'}>HEAR IT FROM THEM</h3>
                    </div>
                </div>

            </div>

            <div className={'container-fluid'}>
                <div className={'row'}>
                    <div className={'col-md-12 z-index-999'}>
                        <CarouselReviews colClass={'col-lg-12'} containerClass={'container-fluid pb-5'} items={entrepreneurs} itemsToShow={1}  centerMode={true} theme={'reviews-light'}/>

                    </div>
                </div>
            </div>

        </section>
);
};

export default Entrepreneurs;
