import React, {FC} from "react";

const Limitation: FC = () => {
    return (
        <div className={'container'}>
            <div className={'row '}>
                <div className={'col-lg-11 pb-4'}>
                    <h3 className={'f-bold text-blue-color section-title mb-3'}>
                        <span className={'colored-nb me-3'}> 12 </span>
                        LIMITATION OF LIABILITY</h3>
                    <div className={'f-regular subheading text-blue-color'}>
                        <p className={'mt-4'}><span className={'f-bold'}>12.1 </span> Our Platform and its access
                            is only intended to provide you with professional networking and business development
                            information, and we will not be liable for any losses you incur by placing reliance on the
                            advice provided.</p>
                        <p><span className={'f-bold'}>12.2 </span> We are not liable to you for any losses you incur if
                            the Platform services are delayed and cannot be
                            performed because you fail to provide us with the
                            relevant information necessary to allow us to perform
                            the Services. </p>
                        <p><span className={'f-bold'}>12.3</span>We will not be liable for any losses you suffer or
                            incur due to delay or failure to provide any features
                            of the platform caused by events outside our control.
                        </p>
                        <p><span className={'f-bold'}>12.4 </span> In the event of being affected by an unforeseeable
                            event, we will promptly notify you via email if we
                            are unable to fulfill a particular Service.
                        </p>
                        <p><span className={'f-bold'}>12.5</span>We provide the Platform, the content, and the Services
                            on an "as-is" basis and make no representations or
                            warranties of any kind, express or implied, with
                            respect to the operation of the Platform or the
                            information, content, or materials on the Platform. We
                            do not guarantee, represent, or warrant that your use
                            of our Service will be uninterrupted, timely, secure,
                            or error-free.
                        </p>
                        <p><span className={'f-bold'}>12.6</span> In no case shall the Company, directors, officers,
                            employees, affiliates, agents, contractors, interns, suppliers, service providers, or
                            licensors be liable for any injury, loss, claim, or any direct, indirect, incidental,
                            punitive, special, or consequential damages of any kind, including without limitation loss
                            of profits, lost revenue, loss of savings, loss of data, loss or corruption of software or
                            systems, loss or damage to equipment, loss of use, loss of opportunity, loss of savings
                            whether anticipated or actual replacement costs, harm to reputation, or loss of goodwill, or
                            any similar damages, whether based in contract, tort (including negligence), strict
                            liability, or otherwise, arising from your use of any of the Services on the Platform.</p>
                        <p><span className={'f-bold'}>12.7</span> Our sole and entire maximum liability, in connection
                            with or arising out of the Services (regardless of whether such liability arises in tort,
                            contract, or in any other way and whether or not caused by negligence or misrepresentation),
                            and your sole and exclusive remedy for any cause whatsoever, shall be limited to the actual
                            amount paid by you for accessing our Platform in the 12-month period preceding the action
                            giving rise to a claim against us.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Limitation;
