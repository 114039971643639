import React, {FC} from "react";
import {Link} from "react-router-dom";
import CarouselLogos from "../../components/carousel/CarouselLogos";
import {NavDropdown} from "react-bootstrap";


const Footer: FC = () => {

    const images = [
        '/assets/images/logos/logo-1.webp',
        '/assets/images/logos/logo-2.webp',
        '/assets/images/logos/logo-3.webp',
        '/assets/images/logos/logo-4.webp'
    ];
    return (
        <footer className={''}>
            <div className={''}>
                <div className={'container logos-top'}>
                    <div className={'row justify-content-center'}>
                        <div className={'col-md-10 col-10'}>
                            <div className={'bg-white'}>
                                <CarouselLogos images={images}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'bg-blue position-relative'}>
                <div className={'container pt-lg-3 pt-3 pb-5'}>
                    <div className={'row justify-content-center mt-lg-5 pt-lg-5 mt-4 pt-4'}>
                        <div className={'col-lg-3 col-8 text-lg-start text-center  mt-3'}>
                            <div>
                                <Link className={''} to="/"> <img src={'/assets/images/logos/logo.png'} className={'w-50'} alt={'SBC Logo'}/> </Link>
                            </div>
                        </div>
                        <div className={'col-lg-2 col-md-6 col-6 mt-3'}>
                            <div>
                                <ul className={'main-links'}>
                                    <li><Link to={'/'}>Home</Link></li>
                                    <li><Link to={'/about'}>About Us</Link></li>
                                    <li><Link to="https://therebelnetworker.com/books/" target={'_blank'}>Books</Link></li>
                                    <li><Link to={'networking-and-workshops'}>Networking & Workshops</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className={'col-lg-2 col-md-6 col-6  mt-3'}>
                            <div>
                                <ul className={'main-links'}>
                                    <li><Link to={'/contact'}>Contact</Link></li>
                                    <li>
                                        <NavDropdown title={<>Work with Phil <i className="fa-solid fa-chevron-down xs-text" /></>} id="basic-nav-dropdown" className={`nav-dropdown`}>
                                            <NavDropdown.Item className={'text-capitalize f-regular text-blue-color xs-text'} href={"https://therebelnetworker.com/speaking/"} target={'_blank'}>Speaking</NavDropdown.Item>
                                            <NavDropdown.Item className={'text-capitalize f-regular text-blue-color xs-text'} href={"https://therebelnetworker.com/business-coaching/"} target={'_blank'}>Coaching</NavDropdown.Item>
                                        </NavDropdown>
                                    </li>
                                    <li><NavDropdown  title={<>Resources <i className="fa-solid fa-chevron-down xs-text" /></>} id="basic-nav-dropdown"  className={'nav-dropdown'}>
                                        <NavDropdown.Item className={'text-capitalize f-regular text-blue-color xs-text'} href={"https://therebelnetworker.com/blog/"} target={'_blank'}>Blog</NavDropdown.Item>
                                        <NavDropdown.Item className={'text-capitalize f-regular text-blue-color xs-text'} href={"https://therebelnetworker.com/episodes/"} target={'_blank'}>TV Features</NavDropdown.Item>
                                        <NavDropdown.Item className={'text-capitalize f-regular text-blue-color xs-text'} href={"https://therebelnetworker.com/grow-and-engage/"} target={'_blank'}>Extras</NavDropdown.Item>
                                    </NavDropdown></li>
                                </ul>
                            </div>
                        </div>
                        <div className={'col-lg-3 col-md-6 col-6  mt-3'}>
                            <div>
                                <ul className={'main-links'}>
                                    <li><Link to={'/terms-of-service'}>Terms of service</Link></li>
                                    <li><Link to={'/privacy-policy'}>Privacy Policy</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className={'col-lg-2 col-md-6 col-6 text-lg-end  mt-3 z-index-999'}>
                            <div>
                                <ul className={'social-links'}>
                                    <li><Link to="https://www.facebook.com/groups/strategicbusinessconnect" target={'_blank'} aria-label="Facebook Group: Strategic Business Connect"><i className="fa-brands fa-facebook-f"/></Link>
                                    </li>
                                    <li><Link to="https://www.instagram.com/strategicbusinessconnect/" target={'_blank'} aria-label="Instagram Account: Strategic Business Connect"><i className="fa-brands fa-instagram"/></Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'mask-image-footer'}>
                    <img src={'/assets/images/mask/footer-mask.png'} alt={'Mask Footer'}/>
                </div>
            </div>
            <div className={'copy-right bg-white'}>
                <div className={'container'}>
                    <div className={'row'}>
                        <div className={'col-12'}>
                            <div className={'copy-right-text text-center pt-3 pb-3'}>
                                © 2024 Strategic Business Connect. All Rights reserved. Part of Asentiv Marketing and
                                Management Consultancy LLC.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
