/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, {FC} from 'react'
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import App from "../App";
import About from "../pages/about/about";
import Contact from "../pages/contact/contact";
import NetworkingWorkshopIndex from "../pages/networking/NetworkingWorkshopIndex";
import NetworkingWorkshopShow from "../pages/networking/NetworkingWorkshopShow";
import PlugInToDubai from "../pages/plug-into-dubai/PlugInToDubai";
import Privacy from "../pages/privacy/Privacy";
import Terms from "../pages/terms/Terms";
import Login from "../pages/login/Login";
import Partner from "../pages/partner/Partner";
import RebelNetwork from "../pages/rebel-network/RebelNetwork";
import Offer from "../pages/offer/Offer";
import {Sponsorship} from "../pages/sponsorship/Sponsorship";


/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */


const AppRoutes: FC = () => {
    const Home = React.lazy(() => import('../pages/home/Home'));

    return (
        <BrowserRouter>
            <Routes>
                <Route element={<App/>}>

                    <Route path='/' element={<Home/>}/>
                    <Route path='/login' element={<Login/>}/>
                    <Route path='/about' element={<About/>}/>
                    <Route path='/contact' element={<Contact/>}/>
                    <Route path='/networking-and-workshops' element={<NetworkingWorkshopIndex/>}/>
                    <Route path='/networking-and-workshop/show' element={<NetworkingWorkshopShow/>}/>
                    <Route path='/plug-into-dubai' element={<PlugInToDubai/>}/>
                    <Route path='/privacy-policy' element={<Privacy/>}/>
                    <Route path='/terms-of-service' element={<Terms/>}/>
                    <Route path='/partner' element={<Partner/>}/>
                    <Route path='/rebel-network' element={<RebelNetwork/>}/>
                    <Route path='/offer' element={<Offer/>}/>
                    <Route path='/sponsorship' element={<Sponsorship/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export {AppRoutes}
