import React, {FC} from "react";
import {Link} from "react-router-dom";

const Episodes: FC = () => {
    const videos = [
        {
            title: 'It\'s only a minute but',
            imageUrl: '/assets/images/rebel-network/episode-1.png',
            description: 'Runtime · Views · March 30, 2019'
        },
        {
            title : 'Who is your Lighthouse?',
           imageUrl: '/assets/images/rebel-network/episode-2.png',
            description: 'Runtime · Views · January 16, 2019'
        },
        {
            title: 'Tips from the slopes',
           imageUrl: '/assets/images/rebel-network/episode-3.png',
            description: 'Runtime · Views · April 9, 2017'

        },
    ];

    return (
        <section className={' position-relative pb-5'}>
            <div className={'container'}>
                <div className={'row justify-content-center'}>
                    <div className={'col-md-12 text-center mb-5 pt-5 z-index-999'}>
                        <h3 className={'text-blue-color section-title f-bold'}>IT'S TIME TO STIR YOUR CURIOSITY</h3>
                        <p className={'subheading text-blue-color f-regular mt-3'}>Indulge in hours of captivating video content. Let your curiosity soar.</p>
                    </div>
                </div>
                <div className={'row justify-content-center'}>
                    {videos.map((video:any, index:any) => (
                        <div className={'col-lg-4 col-md-12 z-index-999'} key={index}>
                            <div className={'bg-episode'}>
                                <div className={'position-relative'}>
                                    <div className="blue-overlay"></div>
                                    <img src={video.imageUrl} alt={'Video'} className={'w-100'}/>
                                    <div className={'play-btn centered-content text-center'}>
                                        <img className={'w-50 '} src={'/assets/images/icons/play-icon.png'}
                                             alt={'Play Button'}/>
                                    </div>
                                </div>
                                <div className={'p-3'}>
                                    <h4 className={'lg-text text-blue-color f-semi-bold'}>{video.title}</h4>
                                    <p className={'sm-text text-blue-color f-regular'}>{video.description}</p>
                                </div>
                            </div>

                        </div>
                    ))}
                </div>
                <div className={'row justify-content-center mt-5'}>
                    <div className={'col-lg-2 col-md-12 text-center'}>
                        <Link to={''} className={'btn btn-primary bordered w-100'}>view more</Link>
                    </div>
                </div>
            </div>


        </section>
    );
};

export default Episodes;