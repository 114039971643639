import React, {FC} from "react";
import GetInTouch from "../../components/GetInTouch";
import TopBanner from "../../layout/base/TopBanner";
import Introduction from "./components/Introduction";
import Access from "./components/Access";
import Features from "./components/Features";
import UserAccessPeriod from "./components/UserAccessPeriod";
import Billing from "./components/Billing";
import Rights from "./components/Rights";
import Guide from "./components/Guide";
import Termination from "./components/Termination";
import Errors from "./components/Errors";
import OptionalTools from "./components/OptionalTools";
import ThirdPartyLinks from "./components/ThirdPartyLinks";
import Limitation from "./components/Limitation";
import Notices from "./components/Notices";
import GoverningLaw from "./components/GoverningLaw";
import General from "./components/General";
import Contact from "./components/Contact";
import TermsPrice from "./components/TermsPrice";

const Terms: FC = () => {
    return (
        <section className={'terms'}>
            <TopBanner title={'Terms of Service'}/>
            <div className={'position-relative'}>
                <div className={'top-right-mask'}>
                    <img src={'/assets/images/mask/mask-top-right-to-bottom.png'} alt={'Mask'}/>
                </div>
                <Introduction/>
                <Access/>
                <Features/>
                <TermsPrice/>
                <UserAccessPeriod/>
                <Billing/>
                <Rights/>
                <Guide/>
                <Termination/>
                <Errors/>
                <OptionalTools/>
                <ThirdPartyLinks/>
                <Limitation/>
                <Notices/>
                <GoverningLaw/>
                <General/>
                <Contact/>
            </div>
            <GetInTouch/>
        </section>
    );
};

export default Terms;
