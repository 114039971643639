import React, {FC} from "react";
import {Link} from "react-router-dom";

const Point13: FC = () => {
    return (
        <div className={'container'}>
            <div className={'row '}>
                <div className={'col-lg-11  pb-4'}>
                    <h3 className={'f-bold text-blue-color section-title mb-3'}>
                        <span className={'colored-nb me-3'}> 13 </span>
                        COOKIES & SIMILAR TECHNOLOGIES</h3>
                    <div className={'f-regular subheading text-blue-color'}>
                        <p className={'mt-4'}><span className={'f-bold'}>13.1 </span>
                            We use two broad categories of cookies: (1) first-party cookies, served directly by us to
                            your computer or mobile device, which we use to recognize your device when it revisits our
                            Platform; and (2) third-party cookies, which are served by service providers or business
                            partners on our client services, and can be used by such service providers or business
                            partners to recognize your device when it visits other websites.
                            Some technologies, plug-ins, and specific cookies are also used to collect the automatic
                            data mentioned under this privacy policy and it may include:
                        </p>
                        <p><span className={'f-bold'}>(a) Cookies (or browser cookies). </span>
                            A cookie is a small file placed on the hard drive of your computer. You may refuse to accept
                            browser cookies by activating the appropriate setting on your browser. However, if you
                            select this setting, you may be unable to access certain parts of the Platform. Unless you
                            have adjusted your browser setting so that it will refuse cookies, our system will issue
                            cookies when you direct your browser to the Platform. These cookies include but are not
                            limited to:
                        </p>
                        <p><span className={'f-bold'}>i. Essential Cookies:</span>
                            These cookies are essential to provide you with services available through our Platform and
                            to enable you to use some of its features. Without these cookies, the services that you have
                            asked for cannot be provided, and we only use these cookies to provide you with those
                            services.
                        </p>
                        <p><span className={'f-bold'}>ii. Functionality Cookies: </span>
                            These cookies allow our Sites to remember the choices you make when you use our Sites. The
                            purpose of these cookies is to provide you with a more personal experience and to avoid you
                            having to re-select your preferences every time you visit our Platform.
                        </p>
                        <p><span className={'f-bold'}>iii. Analytics and Performance Cookies:</span>
                            These cookies collect information about traffic to our Sites and about how individuals use
                            our Sites.
                        </p>
                        <p><span className={'f-bold'}>(b) Flash Cookies. </span>
                            Certain features of the Platform may use locally stored objects (or Flash cookies) to
                            collect and store information about your preferences and navigation to, from, and on Flash
                            cookies are not managed by the same browser settings as are used for browser cookies.
                        </p>
                        <p><span className={'f-bold'}>(c) Web Beacons</span>
                            Pages of our Platform and our e-mails may contain small electronic files known as web
                            beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the
                            Company, for example, to count users who have visited those pages or opened an email and for
                            other related website statistics (for example, recording the popularity of certain website
                            content and verifying system and server integrity).
                        </p>
                        <p><span className={'f-bold'}>13.2 How to manage cookies: </span>
                            You can set your browser not to accept cookies, and the above website tells you how to
                            remove cookies from your browser. However, in a few cases, some of our website features may
                            not function as a result.
                        </p>
                        <p><span className={'f-bold'}>13.3 Do not track: </span>
                            Your browser settings may allow you to automatically transmit a "Do Not Track" signal to
                            websites and online services you visit; however unless mandated by law and where it is
                            required by us to do so as per the law, the website shall not alter its current practices
                            when it receives a "Do Not Track" signal from a visitor's browser.
                        </p>
                        <p>Most browsers automatically accept cookies, but you can choose whether or not to accept
                            cookies through your browser controls, often found in your browser’s “Tools” or
                            “Preferences” menu. More information on how to modify your browser settings or how to block,
                            manage or filter cookies can be found in your browser’s help file or through such sites
                            as: <Link className={'link-blue-light'} target={'_blank'}
                                      to={'https://www.aboutcookies.org/'}>https://www.aboutcookies.org/</Link></p>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Point13;
