import React, {FC} from "react";

const Point8: FC = () => {
    return (
        <div className={'container'}>
            <div className={'row pt-5 mt-5'}>
                <div className={'col-lg-11 pb-4'}>
                    <h3 className={'f-bold text-blue-color section-title mb-3'}>
                        <span className={'colored-nb me-3'}> 08 </span>
                        HOW WE PROTECT YOUR PERSONAL INFORMATION</h3>
                    <div className={'f-regular subheading text-blue-color'}>
                        <p className={'mt-4'}><span className={'f-bold'}>8.1 </span>
                            Personal information is maintained on our servers or those of our service providers and
                            is accessible by authorized employees, representatives, and agents as necessary for the
                            purposes described in this privacy policy.
                        </p>
                        <p><span className={'f-bold'}>8.2 </span>
                            We realize that individuals trust us to protect their personal information. We maintain
                            reasonable and appropriate physical, electronic, and procedural safeguards designed to help
                            protect your personal information. While we attempt to protect your personal information in
                            our possession, no method of transmission over the internet, or security system is perfect,
                            and we cannot promise that information about you will remain secure in all circumstances.
                        </p>
                        <p><span className={'f-bold'}>8.3</span>
                            For your convenience, the Platform includes functionality that allows you to remain
                            logged in so that you do not have to re-enter your password each time you want to access
                            your account. If you choose to remain logged in, you should be aware that anyone with access
                            to your device will be able to access and make changes to your account and may be able to
                            make purchases through your account. For that reason, if you choose to remain logged in, we
                            strongly recommend that you take appropriate steps (such as enabling the “Passcode Lock”
                            security feature on your mobile device) to protect against unauthorized access to and use of
                            your account. Please also notify us as soon as possible if you suspect any unauthorized use
                            of your account or password.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Point8;
