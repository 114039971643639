import React, {FC} from "react";
import {Link} from "react-router-dom";

const Notices: FC = () => {
    return (
        <div className={'container'}>
            <div className={'row '}>
                <div className={'col-lg-11  pb-4'}>
                    <h3 className={'f-bold text-blue-color section-title mb-3'}>
                        <span className={'colored-nb me-3'}> 13 </span>
                        NOTICES</h3>
                    <div className={'f-regular subheading text-blue-color'}>
                        <p className={'mt-4'}>All notices sent by you to us must be sent by email to <Link
                            className={'link-blue-light'} target={'_blank'}
                            to={'mailto:hello@strategicbusinessconnect.com'}>hello@strategicbusinessconnect.com</Link>.
                            We may give notice to you at either the email or postal address you provide to us in the
                            Order. Notice will be deemed received and properly served 24 hours after an email is sent or
                            three days after the date of posting of any letter. To prove the service of any notice, it
                            will be sufficient to prove, in the case of a letter, that the letter was properly
                            addressed, stamped, and placed in the post, and in the case of an email, that the email was
                            sent to the specified email address of the addressee.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Notices;
