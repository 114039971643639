import React, {FC} from 'react';
import CardLayoutThree from "./cards/CardLayoutThree";

type Props = {
    title: string;
    description?: string;
    isUsd?: boolean;
}

const PricingSection: FC<Props> = ({title, description, isUsd}) => {
    const packages = [
        {
            price: isUsd ? 'USD 134' : 'AED 490',
            preSubheading: 'Monthly Payments',
            postSubheading: isUsd ? '+VAT / Month' : '+VAT / Month',
            description: [
                'Access the SBC Community Business Directory',
                'Discover business opportunities and connect using advanced search features',
                'Participate in member-only business and social networking sessions',
                'Enjoy complimentary access to online SBC networking events',
                'Benefit from discounted rates for live SBC networking sessions',
                'Engage in networking opportunities tailored for females and mixed groups',
                'Join Super Connect and Free-Flow networking formats',
                'Get complimentary enrollment in the e-course \'Se7en Deadly Start-Up Sins\'',
                'Access top-notch business resources and workshops',
                'Attend Business Networking Masterclasses',
                'Connect with local business leaders, authors, and experts',
                'Receive a 25% discount on \'The Millionaire Networker\' e-course',
                'Enjoy a 5% discount on Entrepreneurial Programs from ASENTIV'
            ],
            btnText: 'SIGN UP'
        },
        {
            price: isUsd ? 'USD 680' : 'AED 4,900',
            preSubheading: 'ONE-TIME PAYMENT',
            postSubheading: '+VAT / Year (Best Value)',
            description: [
                'Access the SBC Community Business Directory',
                'Discover business opportunities and connect using advanced search features',
                'Participate in member-only business and social networking sessions',
                'Enjoy complimentary access to online SBC networking events',
                'Benefit from discounted rates for live SBC networking sessions',
                'Engage in networking opportunities tailored for females and mixed groups',
                'Join Super Connect and Free-Flow networking formats',
                'Get complimentary enrollment in the e-course \'Se7en Deadly Start-Up Sins\'',
                'Access top-notch business resources and workshops',
                'Attend Business Networking Masterclasses',
                'Connect with local business leaders, authors, and experts',
                'Receive a 25% discount on \'The Millionaire Networker\' e-course',
                'Enjoy a 1000aed discount on ASENTIV PRO'

            ],
            btnText: 'SIGN UP & SAVE 17%'
        }
    ];

    return (
        <section className="pricing-table">
            <div className="container">
                <div className="row justify-content-center  mt-5 pt-2">
                    <div className="col-md-10">
                        <div className="text-blue-color text-center">
                            <h3 className="section-title f-bold">{title}</h3>
                            <p className="subheading f-regular">{description}</p>
                        </div>
                    </div>
                </div>

                <div className="row justify-content-center mt-5 pt-2">
                    {packages.map((pricing: any, index: number) => (
                        <CardLayoutThree key={index} title={pricing.price} description={pricing.description}
                                         btnText={pricing.btnText} preSubheading={pricing.preSubheading}
                                         postSubheading={pricing.postSubheading}/>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default PricingSection;