import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAnglesLeft, faAnglesRight} from "@fortawesome/free-solid-svg-icons";
import {Button, Col, Container, Row} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";


const PreviewPanel: React.FC = () => {
    const [isExpanded, setIsExpanded] = useState<Boolean>(false);
    const navigate = useNavigate();

    const pages = [
        {thumbnail: '/assets/images/page-screenshots/home.png', title: 'Home', link: '/'},
        {thumbnail: '/assets/images/page-screenshots/about.png', title: 'About Us', link: '/about'},
        {
            thumbnail: '/assets/images/page-screenshots/networking.png',
            title: 'Networking & Workshops',
            link: '/networking-and-workshops'
        },
        {
            thumbnail: '/assets/images/page-screenshots/networking-show.png',
            title: 'Show | Networking & Workshops',
            link: '/networking-and-workshop/show'
        },
        {thumbnail: '/assets/images/page-screenshots/contact.png', title: 'Contact', link: '/contact'},
        {thumbnail: '/assets/images/page-screenshots/login.png', title: 'Login', link: '/login'},
        {
            thumbnail: '/assets/images/page-screenshots/terms-of-service.png',
            title: 'Terms of Service',
            link: '/terms-of-service'
        },
        {thumbnail: '/assets/images/page-screenshots/policy.png', title: 'Privacy Policy', link: '/privacy-policy'},
        {
            thumbnail: '/assets/images/page-screenshots/plug-into-dubai.png',
            title: 'Plug Into Dubai',
            link: '/plug-into-dubai'
        },
        {
            thumbnail: '/assets/images/page-screenshots/rebel-network.png',
            title: 'Rebel Network',
            link: '/rebel-network'
        },
        {thumbnail: '/assets/images/page-screenshots/partner.png', title: 'Partner', link: '/partner'},
        {thumbnail: '/assets/images/page-screenshots/offer.png', title: 'Offer', link: '/offer'},
        {thumbnail: '/assets/images/page-screenshots/sponsorship.png', title: 'Sponsorship', link: '/sponsorship'},
    ];

    const handleToggle = () => setIsExpanded(!isExpanded);

    const handleView = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, link: string) => {
        e.preventDefault();
        navigate(link);
        setIsExpanded(false);
    }

    return (
        <div className={`preview-panel ${isExpanded ? 'expanded' : ''}`}>
            <Button className={'preview-panel__toggle'} onClick={handleToggle} aria-label="View Preview Panel">
                {
                    isExpanded ? <FontAwesomeIcon icon={faAnglesLeft}/> : <FontAwesomeIcon icon={faAnglesRight}/>
                }
            </Button>

            <div className={`preview-panel-content`}>
                <div className={'wrapper'}>
                    <Container>
                        <Row>
                            {
                                pages.map((page, index) => (
                                    <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={4} className={'my-2'}
                                         key={`page-${index}`}>
                                        <div className={'block'}>
                                            <div className={'thumbnail'}>
                                                <img src={page.thumbnail} alt={`Thumbnail ${index}`}/>
                                            </div>

                                            <h2 className={'f-bold'}>{page.title}</h2>

                                            <Link to={''}
                                               className={'btn btn-primary bordered w-50'}
                                               onClick={(e) => handleView(e, page.link)}
                                            >View Page</Link>
                                        </div>
                                    </Col>
                                ))
                            }
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    );
};

export default PreviewPanel;

