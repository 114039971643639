import React, {FC} from 'react';
import SignUpForm from "./SignUpForm";

const HeroShow: FC = () => {

    return (
        <section className={'top-banner bg-main-gradient z-index-999'}>
            <div className={'container'}>
                <div className={'row justify-content-center align-items-center  pt-4 pb-5'}>
                    <div className={'col-lg-6   pt-4 '}>
                        <div className={'mt-5 pt-5 mb-lg-5 pb-lg-5 pb-3'}>
                            <div>
                                <img src={'/assets/images/networking/network.png'} className={'w-100 border-blue-light'} alt={'Network'}/>
                            </div>
                            <h1 className={'section-title text-white f-bold mt-5'}>SBC International Business Connect (Online)</h1>
                            <p className={'subheading text-white f-light mt-4'}>
                                <span className={'f-semi-bold'}> TIME:</span> 1:00PM - 2:00PM <br/>  <span className={'f-semi-bold'}>VENUE:</span>Web conferencing details provided upon confirmation.
                            </p>
                        </div>
                    </div>
                    <div className={'col-lg-5 '}>
                        <SignUpForm />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HeroShow;