import React, {FC} from "react";
import Hero from "./components/Hero";
import Video from "./components/Video";
import Reviews from "./components/Reviews";
import Access from "./components/Access";
import BusinessResources from "./components/BusinessResources";
import DigitalPlatform from "./components/DigitalPlatform";
import PeopleTalks from "./components/PeopleTalks";
import Call from "./components/Call";
import Entrepreneurs from "./components/Entrepreneurs";
import MainButton from "../../components/buttons/MainButton";
import PricingSection from "../../components/PricingSection";
import AccordionQuestions from "./components/AccordionQuestions";
import Options from "./components/Options";
import JoinNow from "./components/JoinNow";
import TopBanner from "../../layout/base/TopBanner";


const PlugIntoDubai: FC = () => {

    return (
        <>
            <TopBanner title={'Plug Into Dubai, From Anywhere'}
                       description={'Want to enter the Dubai market while abroad? SBC is the answer.'}/>

            <section>
                <Hero/>
                <Video/>
                <Reviews/>
                <Access/>
                <BusinessResources/>
                <DigitalPlatform/>
                <PeopleTalks/>
                <Call/>
                <Entrepreneurs/>

                <div className={'bg-primary-color'}>
                    <div className={'container pt-5 pb-5'}>
                        <div className={'row justify-content-center pt-3 pb-3'}>
                            <div className={'col-md-9 text-center '}>
                                <h3 className={'section-title f-bold text-white'}>Ready to join a wide network of
                                    entrepreneurs in Dubai?</h3>
                            </div>
                            <div className={'col-lg-5 text-center mt-2 '}>
                                <div className={'d-lg-flex d-block justify-content-center'}>
                                    <div>
                                        <MainButton text={'Join Now'}
                                                    className={'btn btn-transparent thick-border ps-4 pe-4 me-lg-4 mt-2'}
                                                    link={''}/>
                                    </div>
                                    <div>
                                        <MainButton text={'book discovery call'}
                                                    className={'btn btn-secondary ps-5 pe-5 mt-2'} link={''}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <PricingSection title={'PRICING PLANS'} isUsd={true}/>

                {/*<BookNow />*/}
                <AccordionQuestions/>

                <Options/>
                <JoinNow/>
            </section>
        </>

    );
};

export default PlugIntoDubai;
