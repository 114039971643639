import React, {FC} from "react";
import CarouselReviews from "../../../components/carousel/CarouselReviews";

const Reviews: FC = () => {
    const reviews = [
        {
            name: 'Sam Drake',
            position: 'Founder of Qwerty Digital',
            description: 'The SBC has been an invaluable resource for me. I cannot overstate the valuable connections I\'ve made through the platform by being a part of the community and networking with like-minded individuals. I\'ve met numerous clients and referral partners who have significantly contributed to my business growth over the past few months. I wholeheartedly recommend the SBC platform to anyone looking to build their network with valuable connections.',
            imageUrl: '/assets/images/home/review-1.webp',
        },
        {
            name: 'Justin Clapp',
            position: 'Reinvention Coach',
            description: 'I wanted to drop you a note to express how much I enjoyed this morning’s event! I was genuinely impressed. It was hands down the best networking event I\'ve ever attended! I adore the environment you\'ve cultivated, the structure, and the thought-provoking questions you posed. The caliber of people present was outstanding, and the collective energy was palpable! I truly feel like I\'ve found the community I\'ve been searching for. I just signed up for SBC through the link provided, as I can\'t envision missing out on future events.',
            imageUrl: '/assets/images/home/review-2.webp',
        },
        {
            name: 'Mahmoud Tatari',
            position: 'Business Growth Coach',
            description: 'I traveled to Dubai with hopes of expanding my business, but I found myself without a network, opportunities, or any clue where to begin. That is, until I was introduced to Phil Bedford and the Strategic Business Connect community, dedicated to networking, referral opportunities, and partnership-building. Being a part of SBC has enabled me to meet new people, broaden my network, and establish valuable referral partnerships. If you\'re seeking to expand your network in Dubai while residing abroad, I highly recommend reaching out to SBC.',
            imageUrl: '/assets/images/home/review-3.webp',

        },
    ];

    return (
        <section className="reviews">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-center mt-5 pt-2 mb-lg-0 mb-3">
                        <h3 className="section-title text-blue-color f-bold">Hear it from the community</h3>
                    </div>
                </div>
            </div>
            <CarouselReviews colClass="col-lg-12" containerClass="container-fluid pb-5" items={reviews}
                             itemsToShow={1} centerMode={true} theme="reviews-light"/>
        </section>
    );
};

export default Reviews;
