import React, {FC} from 'react';
import Select from "react-select";
import clsx from "clsx";
import {OptionType} from "../../models/OptionType";

type Props = {
    placeHolder : string
}
const MainSelect: FC<Props> = ({placeHolder}) => {
    const options: OptionType[] = [
        { value: '1', label: 'Option 1' },
        { value: '2', label: 'Option 2' },
        { value: '3', label: 'Option 3' }
    ];
    return (
        <>

            <Select
                className={clsx(
                    "w-100 main-input",
                )}
                closeMenuOnSelect={false}
                placeholder={placeHolder}
                options={options.map((e: {
                    label: any;
                    value: any
                }) => ({
                    label: e.label,
                    value: e.value
                }))}
                isSearchable={true}

            />

        </>);
};

export default MainSelect;